<template>
  <section class="section">
    <b-modal
      v-model="isUserModalActive"
      aria-modal
      aria-role="dialog"
      has-modal-card
    >
      <user-modal
        :new-user="newUser"
        :user-data="userData"
        v-on:close="isUserModalActive = false"
        v-on:fetch="handleSubmit"
      />
    </b-modal>
    <div class="container">
      <h1 class="title">{{ $tf("userList.title|Felhasználók") }}</h1>
      <h2 class="subtitle">
        {{
          $tf("userList.subtitle|A rendszer felhasználóinak listája, kezelése")
        }}
      </h2>
      <div class="is-pulled-top-right is-inline-flex">
        <b-button
          class="mt-3 mb-5 mr-2"
          icon-left="upload"
          type="is-info"
          @click="importUsers"
          >{{ $tf("userList.import|Importálás") }}
        </b-button>
        <b-button
          :loading="isExportInProgress"
          class="mt-3 mb-5 mr-2"
          icon-left="download"
          type="is-info"
          @click="exportUsers"
          >{{ $tf("userList.export|Exportálás") }}
        </b-button>
        <b-button
          class="mt-3 mb-5"
          icon-left="user-plus"
          type="is-info"
          @click="addUser()"
          >{{ $tf("userList.create|Új felhasználó létrehozása") }}
        </b-button>
      </div>
      <b-table
        ref="table"
        :data="filterByExEmployees"
        :loading="loading"
        :row-class="employeeClass"
        :show-detail-icon="true"
        :aria-current-label="$tf('aria.current.label|Aktuális lap')"
        :aria-next-label="$tf('aria.next.label|Következő lap')"
        :aria-page-label="$tf('aria.page.label|Lap')"
        :aria-previous-label="$tf('aria.previous.label|Előző lap')"
        class="mb-6"
        hoverable
        paginated
        per-page="30"
        striped
      >
        <b-table-column
          v-slot="props"
          :custom-sort="
            (a, b, isAsc) =>
              isAsc
                ? Intl.Collator('hu').compare(a.name, b.name)
                : Intl.Collator('hu').compare(b.name, a.name)
          "
          :label="$tf('userList.table.name|Név')"
          field="name"
          searchable
          sortable
        >
          <div
            class="usernameField is-flex is-align-items-center is-justify-content-start"
          >
            <f-avatar :data="props.row" size="32x32" />
            <div class="is-flex-1">
              <p>{{ props.row.name }}</p>
            </div>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.email|E-mail cím')"
          field="email"
          searchable
          sortable
        >
          {{ props.row.email }}
        </b-table-column>

        <b-table-column v-slot="props" field="" label=" ">
          <b-tag v-if="props.row.external" type="is-danger"
            >{{ $tf("userList.table.external|Külsős") }}
          </b-tag>
          <b-tag v-else type="is-success"
            >{{ $tf("userList.table.internal|Belsős") }}
          </b-tag>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.title|Titulus')"
          field="title"
          searchable
          sortable
        >
          {{ props.row.title }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.role|Szerepkör')"
          field="role"
          searchable
          sortable
        >
          {{ props.row.role }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.seniority|Szenioritás')"
          field="seniority"
          searchable
          sortable
        >
          {{ props.row.seniority }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.department|Terület')"
          field="department"
          searchable
          sortable
        >
          {{ props.row.department }}
        </b-table-column>

        <b-table-column
          :custom-search="onIconFilter"
          :custom-sort="sortIcons"
          :label="$tf('userList.table.misc|Egyéb')"
          centered
          field="icons"
          searchable
          sortable
        >
          <template #searchable="props">
            <div class="is-flex mb-1" style="gap: 24px">
              <div>
                <b-tooltip
                  :label="
                    $tf(
                      'userList.table.misc.loggingRequired|Timesheetelésben részt vesz'
                    )
                  "
                >
                  <b-checkbox-button
                    v-model="iconFilter"
                    class="p-0"
                    native-value="loggingRequired"
                    style="width: 24px"
                    @update:modelValue="
                      (value) =>
                        iconFilterChange(value, 'loggingRequired', props)
                    "
                  >
                    <b-icon class="m-0" icon="calendar-day" />
                  </b-checkbox-button>
                </b-tooltip>
              </div>
              <div>
                <b-tooltip
                  :label="$tf('userList.table.misc.hasJiraId|Van Jira ID-ja')"
                >
                  <b-checkbox-button
                    v-model="iconFilter"
                    class="p-0"
                    native-value="jiraID"
                    style="width: 24px"
                    @update:modelValue="
                      (value) => iconFilterChange(value, 'jiraID', props)
                    "
                  >
                    <b-icon class="m-0" icon="jira" pack="fab" />
                  </b-checkbox-button>
                </b-tooltip>
              </div>
            </div>
            <div class="is-flex" style="gap: 24px">
              <div>
                <b-tooltip
                  :label="
                    $tf(
                      'userList.table.misc.hasAssignedWorkSchedule|Van hozzárendelt munkarendje'
                    )
                  "
                >
                  <b-checkbox-button
                    v-model="iconFilter"
                    class="p-0"
                    native-value="workSchedule_ASSIGNED"
                    style="width: 24px"
                    @update:modelValue="
                      (value) =>
                        iconFilterChange(value, 'workSchedule_ASSIGNED', props)
                    "
                  >
                    <b-icon class="m-0" icon="assigned" size="is-extra-big" />
                  </b-checkbox-button>
                </b-tooltip>
              </div>
              <div>
                <b-tooltip
                  :label="
                    $tf(
                      'userList.table.misc.hasPersonalWorkSchedule|Van egyedi munkarendje'
                    )
                  "
                >
                  <b-checkbox-button
                    v-model="iconFilter"
                    class="p-0"
                    native-value="workSchedule_PERSONAL"
                    style="width: 24px"
                    @update:modelValue="
                      (value) =>
                        iconFilterChange(value, 'workSchedule_PERSONAL', props)
                    "
                  >
                    <b-icon class="m-0" icon="personal" size="is-extra-big" />
                  </b-checkbox-button>
                </b-tooltip>
              </div>
              <div>
                <b-tooltip
                  :label="
                    $tf(
                      'userList.table.misc.hasAggregatedWorkSchedule|Van aggregált munkarendje'
                    )
                  "
                >
                  <b-checkbox-button
                    v-model="iconFilter"
                    class="p-0"
                    native-value="workSchedule_AGGREGATE"
                    style="width: 24px"
                    @update:modelValue="
                      (value) =>
                        iconFilterChange(value, 'workSchedule_AGGREGATE', props)
                    "
                  >
                    <b-icon class="m-0" icon="aggregate" size="is-extra-big" />
                  </b-checkbox-button>
                </b-tooltip>
              </div>
            </div>
          </template>

          <template v-slot="props">
            <div
              class="is-flex is-justify-content-center is-align-items-center"
              style="gap: 6px"
            >
              <b-tooltip
                v-if="shouldHaveIcon(props.row, 'loggingRequired')"
                :label="
                  $tf(
                    'userList.table.misc.loggingRequired|Timesheetelésben részt vesz'
                  )
                "
              >
                <b-icon class="is-blue p-0 m-0" icon="calendar-day" />
              </b-tooltip>
              <b-tooltip
                v-if="shouldHaveIcon(props.row, 'jiraID')"
                :label="$tf('userList.table.misc.hasJiraId|Van Jira ID-ja')"
              >
                <b-icon class="is-blue p-0 m-0" icon="jira" pack="fab" />
              </b-tooltip>
              <b-tooltip
                v-if="shouldHaveIcon(props.row, 'workSchedule', 'ASSIGNED')"
                :label="
                  $tf(
                    'userList.table.misc.hasAssignedWorkSchedule|Van hozzárendelt munkarendje'
                  )
                "
              >
                <b-icon
                  class="is-blue p-0 m-0"
                  icon="assigned"
                  size="is-extra-big"
                />
              </b-tooltip>
              <b-tooltip
                v-if="shouldHaveIcon(props.row, 'workSchedule', 'PERSONAL')"
                :label="
                  $tf(
                    'userList.table.misc.hasPersonalWorkSchedule|Van egyedi munkarendje'
                  )
                "
              >
                <b-icon
                  class="is-blue p-0 m-0"
                  icon="personal"
                  size="is-extra-big"
                />
              </b-tooltip>
              <b-tooltip
                v-if="shouldHaveIcon(props.row, 'workSchedule', 'AGGREGATE')"
                :label="
                  $tf(
                    'userList.table.misc.hasAggregatedWorkSchedule|Van aggregált munkarendje'
                  )
                "
              >
                <b-icon
                  class="is-blue p-0 m-0"
                  icon="aggregate"
                  size="is-extra-big"
                />
              </b-tooltip>
            </div>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('userList.table.actions|Műveletek')"
          centered
          field="actions"
        >
          <b-tooltip
            :label="$tf('userList.table.actions.edit|Szerkesztés')"
            type="is-light"
          >
            <clickable-icon
              icon="edit"
              type="is-info"
              @click="editUser(props.row.id)"
            />
          </b-tooltip>
        </b-table-column>
      </b-table>
      <b-switch v-model="isShowingExEmployees"
        >{{ $tf("userList.showExEmployees|Ex-kollégák megjelenítése") }}
      </b-switch>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import FilteredExEmployeesMixin from "@/mixins/FilteredExEmployeesMixin";
import UserModal from "@/components/census/user/UserModal.vue";
import FileSaver from "file-saver";
import UserImportModal from "@/components/census/user/UserImportModal.vue";
import AvatarPlaceholder from "../../../components/module/icon/AvatarPlaceholder.vue";
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  name: "UserList",
  components: { FAvatar, UserModal, ClickableIcon },
  async mounted() {
    await this.fetchUsers();
    await this.$store.dispatch("user_permission/fetch");
  },
  data() {
    return {
      isExportInProgress: false,
      isUserModalActive: false,
      selectedUserId: null,
      isShowingExEmployees: false,
      loading: false,
      iconFilter: [],
      newUser: true,
      userData: null,
    };
  },
  mixins: [FilteredExEmployeesMixin],
  computed: {
    ...mapGetters({
      employees: "employee/employees",
    }),
  },
  methods: {
    async fetchUsers(force) {
      this.loading = true;
      await this.$store.dispatch("employee/fetchAdmin", force);
      this.loading = false;
    },
    getUser(userId) {
      let employee = this.employees.filter((emp) => {
        return emp.id === userId;
      });
      return employee[0];
    },
    editUser(userId) {
      this.newUser = false;
      this.userData = this.getUser(userId);
      this.isUserModalActive = true;
    },
    addUser() {
      this.newUser = true;
      this.userData = null;
      this.isUserModalActive = true;
    },
    async handleSubmit() {
      this.isUserModalActive = false;
      await this.fetchUsers(true);
    },
    iconsLength(employee) {
      let count = 0;
      if (!!employee.loggingRequired) ++count;
      if (!!employee.externalId) ++count;

      if (employee.workSchedule === "ASSIGNED") ++count;
      if (employee.workSchedule === "PERSONAL") ++count;
      if (employee.workSchedule === "AGGREGATE") ++count;
      return count;
    },
    shouldHaveIcon(employee, icon, type) {
      switch (icon) {
        case "loggingRequired": {
          return !!employee.loggingRequired;
        }
        case "jiraID": {
          return !!employee.externalId;
        }
        case "workSchedule": {
          return employee.workSchedule === type;
        }
        default:
          return false;
      }
    },
    iconFilterChange(value, field, props) {
      if (!props.filters[props.column.field])
        props.filters[props.column.field] = [];
      if (!value) {
        props.filters[props.column.field] = props.filters[
          props.column.field
        ].filter((filter) => filter !== field);
      } else {
        const copy = props.filters[props.column.field];
        copy.push(field);
        props.filters[props.column.field] = copy;
      }
    },
    onIconFilter(props, _input) {
      if (
        this.iconFilter.includes("loggingRequired") &&
        !this.shouldHaveIcon(props, "loggingRequired")
      )
        return false;
      if (
        this.iconFilter.includes("jiraID") &&
        !this.shouldHaveIcon(props, "jiraID")
      )
        return false;
      if (
        this.iconFilter.includes("workSchedule_ASSIGNED") &&
        !this.shouldHaveIcon(props, "workSchedule", "ASSIGNED")
      )
        return false;
      if (
        this.iconFilter.includes("workSchedule_PERSONAL") &&
        !this.shouldHaveIcon(props, "workSchedule", "PERSONAL")
      )
        return false;
      if (
        this.iconFilter.includes("workSchedule_AGGREGATE") &&
        !this.shouldHaveIcon(props, "workSchedule", "AGGREGATE")
      )
        return false;
      return true;
    },
    sortIcons(a, b, isAsc) {
      let comp = this.iconsLength(a) > this.iconsLength(b) ? 1 : -1;
      if (!isAsc) comp = -comp;
      return comp;
    },
    async exportUsers() {
      this.isExportInProgress = true;
      try {
        let data = await this.$store.dispatch("census_user/exportUsers");
        FileSaver.saveAs(data, "users.xlsx");
      } finally {
        this.isExportInProgress = false;
      }
    },
    async importUsers() {
      this.$buefy.modal.open({
        parent: this,
        component: UserImportModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: async () => await this.fetchUsers(true),
        },
      });
    },
  },
};
</script>
<style scoped></style>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.usernameField .field.is-grouped .field:not(:last-child) {
  margin-bottom: 0.75rem;
}

.employee-ex {
  td {
    font-style: italic !important;
    color: $grey-lighter;
  }
}

.is-blue {
  color: $blue;
}
</style>
