<template>
  <div class="container">
    <h3 class="heading">
      <loading-component>
        {{ $tf("execDashboard.overspend.title|Túlköltés várható") }}
      </loading-component>
    </h3>
    <loading-component>
      <template #loader><div></div></template>
      <div v-if="this.overspend.projects.length < 1">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="smile" size="is-large"></b-icon></p>
            <p>
              {{
                $tf(
                  "execDashboard.overspend.empty|Nincs túlköltés a szűrési feltételeknek megfelelő projekteken"
                )
              }}
            </p>
          </div>
        </section>
      </div>
    </loading-component>
    <div class="columns mb-3">
      <div class="column is-one-third is-flex is-justify-content-center">
        <loading-component
          type="circle"
          loading-width="250px"
          loading-height="250px"
          :hide-component="this.overspend.projects.length < 1"
        >
          <apex-chart
            type="donut"
            :options="getOverspentRadialChartOptions"
            :series="[
              this.overspend.number['LOSS']
                ? this.overspend.number['LOSS'].value
                : 0,
              this.overspend.number['PROFIT']
                ? this.overspend.number['PROFIT'].value
                : 0,
              this.overspend.number['IN_BUDGET']
                ? this.overspend.number['IN_BUDGET'].value
                : 0,
            ]"
            height="250px"
          />
        </loading-component>
      </div>
      <div class="column is-one-third">
        <loading-component :hide-component="this.overspend.projects.length < 1">
          <apex-chart
            type="bar"
            :options="getOverspentBudgetBarChartOptions"
            :series="[
              {
                data: [
                  moneyify(
                    this.overspend.overspend['IN_BUDGET']
                      ? this.overspend.overspend['IN_BUDGET'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                  moneyify(
                    this.overspend.overspend['PROFIT']
                      ? this.overspend.overspend['PROFIT'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                  moneyify(
                    this.overspend.overspend['LOSS']
                      ? this.overspend.overspend['LOSS'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                ],
                name: $tf(
                  'execDashboard.overspend.overspendChart.title|Túlköltés'
                ),
              },
            ]"
            height="200px"
          />
        </loading-component>
      </div>

      <div class="column is-one-third">
        <loading-component :hide-component="this.overspend.projects.length < 1">
          <apex-chart
            type="bar"
            :options="getOverspentProfitBarChartOptions"
            :series="[
              {
                data: [
                  moneyify(
                    this.overspend.profit['IN_BUDGET']
                      ? this.overspend.profit['IN_BUDGET'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                  moneyify(
                    this.overspend.profit['PROFIT']
                      ? this.overspend.profit['PROFIT'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                  moneyify(
                    this.overspend.profit['LOSS']
                      ? this.overspend.profit['LOSS'].value
                      : 0,
                    CURRENCY_TIERS.LEVEL_TWO,
                    false,
                    false
                  ),
                ],
                name: $tf('execDashboard.overspend.profitChart.title|Profit'),
              },
            ]"
            height="200px"
          />
        </loading-component>
      </div>
    </div>
    <loading-component :hide-component="this.overspend.projects.length < 1">
      <template #loader><loading-table></loading-table></template>
      <b-table
        v-if="this.overspend.projects.length > 0"
        :data="overspend.projects"
        hoverable
        default-sort="riskKPI"
        default-sort-direction="desc"
        scrollable
      >
        <b-table-column
          field="name"
          :label="$tf('execDashboard.overspend.table.projectName|Projekt név')"
          v-slot="props"
        >
          <router-link :to="`/projects/project/${props.row.identifier}`">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          field="income"
          :label="$tf('execDashboard.overspend.table.income|Bevétel')"
          sortable
          v-slot="props"
          >{{ moneyify(props.row.income) }}</b-table-column
        >

        <b-table-column
          field="budget"
          :label="$tf('execDashboard.overspend.table.budget|Büdzsé')"
          sortable
          v-slot="props"
          >{{ moneyify(props.row.budget) }}</b-table-column
        >

        <b-table-column
          field="spent"
          :label="$tf('execDashboard.overspend.table.spent|Költés')"
          sortable
          v-slot="props"
          >{{ moneyify(props.row.spent) }}</b-table-column
        >

        <b-table-column
          field="spentPercentage"
          :label="$tf('execDashboard.overspend.table.spentPercentage|Költés%')"
          sortable
          v-slot="props"
          >{{ percentify(props.row.spentPercentage) }}</b-table-column
        >

        <b-table-column
          field="readiness"
          :label="$tf('execDashboard.overspend.table.readiness|Készültség')"
          sortable
          v-slot="props"
          >{{ percentify(props.row.readiness) }}</b-table-column
        >

        <b-table-column
          field="riskKPI"
          :label="$tf('execDashboard.overspend.table.budgetKPI|Büdzsé KPI')"
          numeric
          sortable
          v-slot="props"
          cell-class="do-not-wrap"
        >
          <RiskKPITag :value="props.row.riskKPI" />
        </b-table-column>

        <b-table-column
          field="forecastedOverspending"
          :label="
            $tf(
              'execDashboard.overspend.table.forecastedOverspending|FC túlköltés'
            )
          "
          sortable
          v-slot="props"
          >{{ moneyify(props.row.forecastedOverspending) }}</b-table-column
        >
        <b-table-column
          field="forecastedProfit"
          :label="
            $tf('execDashboard.overspend.table.forecastedProfit|FC profit')
          "
          sortable
          v-slot="props"
          >{{ moneyify(props.row.forecastedProfit) }}</b-table-column
        >
      </b-table>
    </loading-component>
  </div>
</template>

<script>
import { getBudgetRiskValue, moneyify, percentify } from "@/utils/util";
import { CURRENCY_TIERS } from "@/utils/const";
import LoadingTable from "@/components/loading/LoadingTable.vue";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKPITag.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardOverspend",
  components: {
    RiskKPITag: RiskKpiTag,
    LoadingTable,
    LoadingComponent: defineAsyncComponent(() =>
      import("@/components/loading/LoadingComponent.vue")
    ),
  },
  methods: { getBudgetRiskValue, percentify, moneyify },
  props: {
    overspend: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getOverspentRadialChartOptions() {
      return {
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: [
          this.overspend.number["LOSS"]?.text ?? "",
          this.overspend.number["PROFIT"]?.text ?? "",
          this.overspend.number["IN_BUDGET"]?.text ?? "",
        ],
        title: {
          text: this.$tf(
            "execDashboard.overspend.overspentRadialChart.title|Projektek száma"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--red)", "var(--yellow)", "var(--green)"],
        plotOptions: {
          pie: {
            donut: {
              size: "40%",
            },
          },
        },
      };
    },
    getOverspentBudgetBarChartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        labels: [
          this.overspend.overspend["IN_BUDGET"]?.text ?? "",
          this.overspend.overspend["PROFIT"]?.text ?? "",
          this.overspend.overspend["LOSS"]?.text ?? "",
        ],
        title: {
          // FIXME: get correct currency and prefixum
          text: this.$tf(
            "execDashboard.overspend.overspentBudgetChart.title|Büdzsé túlköltés - m Ft"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--green)", "var(--yellow)", "var(--red)"],
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
    getOverspentProfitBarChartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        labels: [
          this.overspend.profit["IN_BUDGET"]?.text ?? "",
          this.overspend.profit["PROFIT"]?.text ?? "",
          this.overspend.profit["LOSS"]?.text ?? "",
        ],
        title: {
          // FIXME: get correct currency and prefixum
          text: this.$tf(
            "execDashboard.overspend.overspentProfitChat.title|Profit - m Ft"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--green)", "var(--yellow)", "var(--red)"],
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
  },

  // TO BE REMOVED
  data() {
    return {
      LoadingTable, // DON'T REMOVE
      CURRENCY_TIERS,
      data: {
        overspentProjectCount: [5, 3, 11],
        overspentBudget: [-3, 8, 2],
        profit: [40, 20, -10],
      },
    };
  },
};
</script>

<style scoped></style>
