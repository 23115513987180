import VueI18n from "vue-i18n";
// import network from "@/utils/network";

import { localFetch, localStore } from "@/utils/util";
import axios from "axios";
import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";
import { DEBUG_LANGUAGE } from "@/utils/const";

const messages = {};
const i18n = createI18n({
  locale: "hu",
  fallbackLocale: "en",
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages,
  allowComposition: true,
});

const loadedLanguages = []; // our default language that is preloaded

function setI18nLanguage(lang) {
  localStore("stored_locale", lang);
  i18n.locale = lang;
  i18n.global.locale = lang;
  return lang;
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return setI18nLanguage(lang);
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang);
  }
  // If the language hasn't been loaded yet
  let messages = {};
  try {
    messages = await axios.get(`/api/tokens/public/${lang}`);

    i18n.global.setLocaleMessage(lang, messages?.data || []);
    loadedLanguages.push(lang);
  } catch (ex) {
    console.log("Failed fetching language tokens");
  }

  return setI18nLanguage(lang);
}

/**
 * @param {string} key - The translation key.
 * @param {Record<string, any>} [params] - Optional parameters for the translation.
 * @returns {string} - The translated string.
 */
export function $tf(key, params) {
  if (!key) return "%__%404";
  let fallback = key; // default fallback
  // Split the key and the fallback value if '|' is used
  if (key.includes("|")) {
    [key, fallback] = key.split("|");
    key = key.trim();
    fallback = fallback.trim();
  }
  const translation = i18n.global.t(key, params);
  // If the translation is the same as the key, it means the translation doesn't exist
  // Skip if selected language is the debug key
  if (localFetch("stored_locale") === DEBUG_LANGUAGE) {
    return key;
  }
  // TODO: For testing purposes we return the key for missing translations
  return translation;
}

export function $tcf(key, params) {
  if (!key) return "_%404%_";
  let fallback = key; // default fallback
  // Split the key and the fallback value if '|' is used
  if (key.includes("|")) {
    [key, fallback] = key.split("|");
    key = key.trim();
    fallback = fallback.trim();
  }
  const translation = this.$tc(key, params);
  // If the translation is the same as the key, it means the translation doesn't exist
  // Skip if selected language is the debug key
  if (localFetch("stored_locale") === DEBUG_LANGUAGE) {
    return key;
  }
  // TODO: For testing purposes we return the key for missing translations
  return translation;
}

export default i18n;
