import { mapGetters } from "vuex";
import { isNullOrUndefined, roundToTwoDecimals } from "@/utils/util";

export default {
  computed: {
    ...mapGetters({
      riskKPILimits: "uiConfigStore/getRiskKPILimits",
    }),
  },
  methods: {
    // These expect a number as input, from 0.0 to infinity (capped at 10)
    displayKpi(kpi) {
      if (isNullOrUndefined(kpi)) return "?";
      const val = roundToTwoDecimals(kpi);
      return val > 10 ? "10+" : val;
    },
    getRiskColor(kpi) {
      let val = parseFloat(kpi);
      if (val > 10) return "is-fatal";
      if (val > roundToTwoDecimals(1 + this.riskKPILimits[1]))
        return "is-danger";
      if (val > roundToTwoDecimals(1 - this.riskKPILimits[0]))
        return "is-warning";
      return "is-success";
    },
    getRiskIcon(kpi) {
      let val = parseFloat(kpi);
      if (val > 10) return "tombstone";
      if (val > roundToTwoDecimals(1 + this.riskKPILimits[1]))
        return "siren-on";
      if (val > roundToTwoDecimals(1 - this.riskKPILimits[0]))
        return "triangle-exclamation";
      return "thumbs-up";
    },
    getForecastIcon(kpi) {
      if (kpi > 10) return "snowflakes";
      if (kpi > roundToTwoDecimals(1 + this.riskKPILimits[1]))
        return "cloud-showers";
      if (kpi > roundToTwoDecimals(1 - this.riskKPILimits[0])) return "clouds";
      return "sun-cloud";
    },
  },
};
