<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{
          $tf("quotaEditor.title|Kvóta megadása {year} évre", {
            year: selectedYear,
          })
        }}
      </h1>
      <hr />
      <form @submit.prevent="" v-if="quotaRequests && employees && remainings">
        <b-table
          :data="filteredEmployees ? filteredEmployees : []"
          ref="table"
          striped
          hoverable
          mobile-cards
          default-sort-direction="asc"
        >
          <b-table-column
            field="name"
            :label="$tf('quotaEditor.table.name|Név')"
            width="160"
            sortable
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="year"
            :label="$tf('quotaEditor.previousYear|Előző év')"
            width="160"
            sortable
            v-slot="props"
          >
            <span class="quota">
              {{ getQuota(props.row.id) }} /
              {{ getReservedPlusTaken(props.row.id) }}
            </span>
            <span class="quotaPlus" v-if="getRemaining(props.row.id) > 0">
              ( +{{ getRemaining(props.row.id) }} )
            </span>
            <span class="quotaMinus" v-if="getRemaining(props.row.id) < 0">
              ( {{ getRemaining(props.row.id) }} )
            </span>
          </b-table-column>

          <b-table-column
            field="actYear"
            :label="selectedYear"
            width="30"
            sortable
            v-slot="props"
          >
            <div
              class="columns is-centered is-vcentered"
              v-if="quotaRequests[props.row.id]"
            >
              <div class="column">
                <vuelidated-input
                  v-model="quotaRequests[props.row.id].quota"
                  :placeholder="$tf('quotaEditor.table.quota|Kvóta')"
                  :ref-value="`quotaRequests[${props.row.id}].quota`"
                  :validation-rule="v$.quotaRequests[props.row.id].quota"
                  @update:modelValue="
                    quotaRequests[props.row.id].actionSuccess = null
                  "
                  :disabled="quotaRequests[props.row.id].actionSuccess"
                />
              </div>
              <div class="column">
                <transition name="fade">
                  <div>
                    <span
                      v-if="quotaRequests[props.row.id].actionSuccess === true"
                    >
                      <b-icon
                        icon="check"
                        size="is-medium"
                        type="is-success"
                      ></b-icon>
                    </span>
                    <span
                      v-if="quotaRequests[props.row.id].actionSuccess === false"
                    >
                      <b-icon
                        icon="times"
                        size="is-medium"
                        type="is-danger"
                      ></b-icon>
                    </span>
                  </div>
                </transition>
              </div>
            </div>
          </b-table-column>

          <b-table-column
            field="actions"
            :label="$tf('quotaEditor.actions|Műveletek')"
            centered
            width="80"
            v-slot="props"
          >
            <b-tooltip
              :label="$tf('quotaEditor.actions.save|Mentés')"
              type="is-light"
              v-if="quotaRequests[props.row.id]"
            >
              <clickable-icon
                type="is-info"
                size="is-medium"
                @click="saveQuota(props.row.id)"
                icon="save"
                :disabled="quotaRequests[props.row.id].actionSuccess"
              />
            </b-tooltip>
          </b-table-column>
        </b-table>
      </form>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "QuotaForm",
  components: { ClickableIcon, VuelidatedInput },
  setup: () => useCustomVuelidate(),

  async mounted() {
    let selectedYear = this.$route.params.year;
    if (selectedYear) this.selectedYear = selectedYear;
    // await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("quotas/getRemainings", {
      year: selectedYear - 1,
    });
    await this.createQuotaRequestArray();
    await this.$store.dispatch("quotas/getActYearQuotas", selectedYear);
    await this.setExistQuotas();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      selectedYear: null,
      buttonLoading: false,
      quotaRequests: {},
    };
  },

  validations() {
    const quotaValidations = {};
    for (const key in this.quotaRequests) {
      quotaValidations[key] = {
        quota: { numeric },
      };
    }
    return {
      quotaRequests: quotaValidations,
    };
  },

  computed: {
    filteredEmployees: function () {
      return this.employees
        .filter((user) => !user.external && !user.endDate)
        .sort();
    },
    requestItem: function () {
      return function (id) {
        return this.quotaRequests[id];
      };
    },
    ...mapGetters({
      employees: "employee/employees",
      remainings: "quotas/remainings",
      actYearQuotas: "quotas/actYearQuotas",
    }),
  },
  methods: {
    saveQuota(user) {
      if (this.quotaRequests[user].id) {
        this.updateQuota(user);
      } else {
        this.createQuota(user);
      }
    },
    updateQuota(user) {
      let quotaRequest = this.quotaRequests[user];
      this.$store.dispatch("quotas/updateQuota", quotaRequest).then(
        () => {
          this.quotaRequests[user].actionSuccess = true;
        },
        () => {
          this.quotaRequests[user].actionSuccess = false;
        }
      );
    },
    createQuota(user) {
      let quotaRequest = this.quotaRequests[user];
      this.$store.dispatch("quotas/createQuota", quotaRequest).then(
        () => {
          this.quotaRequests[user].actionSuccess = true;
        },
        () => {
          this.quotaRequests[user].actionSuccess = false;
        }
      );
    },
    setExistQuotas() {
      for (let emp of this.filteredEmployees) {
        for (let quota of this.actYearQuotas) {
          if (emp.id === quota.userId) {
            this.quotaRequests[emp.id].quota = quota.quota;
            this.quotaRequests[emp.id].id = quota.id;
          }
        }
      }
    },
    createQuotaRequestArray() {
      for (let emp of this.filteredEmployees) {
        let actRequest = {
          user: emp.id,
          year: this.selectedYear,
          quota: null,
          id: null,
          actionSuccess: null,
        };

        this.quotaRequests[emp.id] = actRequest;
      }
    },
    getQuota(user) {
      let remaining = this.remainings.filter((rem) => {
        return rem.user === user;
      });
      return remaining[0] ? remaining[0].quota : "-";
    },
    getRemaining(user) {
      let remaining = this.remainings.filter((rem) => {
        return rem.user === user;
      });
      return remaining[0] != null ? remaining[0].remaining : null;
    },
    getReservedPlusTaken(user) {
      let remaining = this.remainings.filter((rem) => {
        return rem.user === user;
      });
      return remaining[0] ? remaining[0].reserved + remaining[0].taken : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.quotaMinus {
  color: $red;
}
.quotaPlus {
  color: $green;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
