<template>
  <div class="modal-card" style="width: auto">
    <div class="container">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">
          {{
            $tf(
              "overtimePoliciesForm.newTitle|Új túlóra szabályzat létrehozása"
            )
          }}
        </h1>
        <h1 class="title" v-else>
          {{
            $tf(
              "overtimePoliciesForm.editTitle|{name} túlóra szabályzat módosítása",
              { name: name }
            )
          }}
        </h1>

        <form @submit.prevent="submit" class="mt-5">
          <b-field class="mb-1">
            <vuelidated-input
              v-model="editData.name"
              :placeholder="$tf('overtimePoliciesForm.name.placeholder|Név')"
              :label="$tf('overtimePoliciesForm.name|Név')"
              :validation-rule="v$.editData.name"
              label-position="on-border"
            />
          </b-field>

          <b-field class="mb-1 mt-3">
            <vuelidated-form :validations="v$.editData.multiplier">
              <template v-slot="{ errors, invalid }">
                <b-field
                  :expanded="true"
                  :type="{ 'is-danger': invalid }"
                  :message="v$.editData.multiplier.$error ? errors[0] : null"
                  label-position="on-border"
                  class="custom-label"
                >
                  <template #label
                    >{{ $tf("overtimePoliciesForm.multiplier|Szorzó") }}
                    <span class="has-text-danger">*</span></template
                  >
                  <b-numberinput
                    controls-alignment="right"
                    :placeholder="
                      $tf('overtimePoliciesForm.multiplier.placeholder|Szorzó')
                    "
                    step="0.5"
                    v-model="editData.multiplier"
                    @blur="v$.editData.multiplier.$touch()"
                  ></b-numberinput>
                </b-field>
              </template>
            </vuelidated-form>
          </b-field>

          <b-field class="mb-1">
            <b-field :label="$tf('overtimePoliciesForm.active|Aktív')">
              <b-switch v-model="editData.active">
                {{
                  editData.active
                    ? $tf("overtimePoliciesForm.active.yes|Igen")
                    : $tf("overtimePoliciesForm.active.no|Nem")
                }}
              </b-switch>
            </b-field>
          </b-field>

          <b-field class="is-pulled-right mt-5">
            <b-button @click="close">
              {{ $tf("overtimePoliciesForm.cancel|Mégse") }}
            </b-button>
            <b-button native-type="submit" type="is-info" class="ml-2">
              {{ $tf("overtimePoliciesForm.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ProjectCategoryForm",
  components: { VuelidatedInput, VuelidatedForm },
  setup: () => {
    const instance = getCurrentInstance();
    const {v$, touch$} = useCustomVuelidate()
    return {
      v$, touch$, instance
    };
  },
  props: {
    overtimePolicy: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      editData: {
        multiplier: 1.5,
        active: true,
      },
      isCreate: true,
      projects: [],
    };
  },
  mounted() {
    if (this.overtimePolicy) {
      this.isCreate = false;
      this.name = this.overtimePolicy.name;
      this.editData = JSON.parse(JSON.stringify(this.overtimePolicy));
    } else {
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      multiplier: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("overtime_policies/fetchOvertimePolicies", true);
    },
    submit() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isCreate) {
        this.$store
          .dispatch("overtime_policies/create", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("overtime_policies/update", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>

<style lang="scss">
.custom-label {
  .label {
    margin-left: 0 !important;
  }
}
</style>
