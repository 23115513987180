export default {
  computed: {
    activeTab: {
      get() {
        const tabs = this.getTabConfig();
        const hash = this.$router.currentRoute.value.hash;

        for (const [key, value] of Object.entries(tabs.HASH)) {
          if (hash === value) {
            return tabs[key];
          }
        }

        return tabs.DEFAULT;
      },
      set(val) {
        const tabs = this.getTabConfig();

        for (const [key, value] of Object.entries(tabs)) {
          if (val === value) {
            this.$router.push({
              hash: tabs.HASH[key],
            });
            return;
          }
        }

        // Fallback to DEFAULT if no match
        this.$router.push({
          hash: tabs.HASH.DEFAULT,
        });
      },
    },
  },
  methods: {
    /**
     * Define a method to provide the tab configuration.
     * Override this method in the component using the mixin.
     */
    getTabConfig() {
      throw new Error("You must override getTabConfig() in your component.");
    },
  },
};
