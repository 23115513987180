<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else class="project-form">
      <div class="is-flex mb-3">
        <div>
          <h3 class="title">
            {{ $tf("projectForm.namesAndIds|Nevek és azonosítók") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.namesAndIds.tooltip|Az azonosítót kézzel szükséges kitölteni!"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
        </div>
      </div>
      <div>
        <div class="columns mb-5">
          <div class="column">
            <vuelidated-input
              v-model="projectEdit.identifier"
              :expanded="true"
              :label="$tf('projectForm.identifier|Azonosító')"
              :placeholder="$tf('projectForm.identifier.placeholder|Azonosító')"
              :validation-rule="v$.projectEdit.identifier"
              label-position="on-border"
            />
          </div>
          <div class="column">
            <vuelidated-input
              v-model="projectEdit.name"
              :expanded="true"
              :label="$tf('projectForm.name|Név')"
              :placeholder="$tf('projectForm.name.placeholder|Név')"
              :validation-rule="v$.projectEdit.name"
              label-position="on-border"
            />
          </div>
          <div v-if="!isSelfTracking" class="column">
            <vuelidated-input
              v-model="projectEdit.prefix"
              :expanded="true"
              :label="$tf('projectForm.prefix|Prefix')"
              :placeholder="$tf('projectForm.prefix.placeholder|Prefix')"
              :validation-rule="v$.projectEdit.prefix"
              label-position="on-border"
            />
          </div>
        </div>
        <div
          class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
        >
          <div class="is-half-width">
            <b-field
              :label="$tf('projectForm.manager|Felelős')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="managerName"
                :data="filteredLeads"
                :placeholder="
                  $tf('projectForm.manager.placeholder|pl. Elektrom Ágnes')
                "
                class="has-blue-icon"
                field="name"
                icon="search"
                rounded
                @select="(option) => (projectEdit.lead = option.id)"
              >
                <template v-slot:empty
                  >{{ $tf("projectForm.manager.empty|Nincs találat") }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="is-half-width">
            <b-field
              :label="$tf('projectForm.client|Ügyfél')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="clientName"
                :data="filteredClients"
                :placeholder="
                  $tf('projectForm.client.placeholder|pl. Alpha Soft Kft.')
                "
                class="has-blue-icon"
                field="name"
                icon="search"
                rounded
                @select="(option) => (projectEdit.client = option.id)"
              >
                <template v-slot:empty
                  >{{ $tf("projectForm.client.empty|Nincs találat") }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>
        <div class="is-full-width mb-4">
          <b-field grouped label-position="on-border">
            <div class="projet-type-label pr-2 pd-2">
              {{ $tf("projectForm.type|Projekt típusa") }}
              <span class="has-text-danger">*</span>
            </div>
            <b-field>
              <b-radio-button
                v-model="projectEdit.type"
                native-value="BASIC"
                type="is-primary"
              >
                <b-icon icon="long-arrow-alt-right"></b-icon>
                <span>{{ $tf("projectForm.type.basic|Hagyományos") }}</span>
              </b-radio-button>
              <b-radio-button
                v-model="projectEdit.type"
                native-value="SUPPORT"
                type="is-primary"
              >
                <b-icon icon="headset"></b-icon>
                <span>{{ $tf("projectForm.type.support|Folyamatos") }}</span>
              </b-radio-button>
              <b-radio-button
                v-model="projectEdit.type"
                native-value="OUTSOURCE"
                type="is-primary"
              >
                <b-icon icon="handshake"></b-icon>
                <span>{{ $tf("projectForm.type.outsource|Outsource") }}</span>
              </b-radio-button>
            </b-field>
            <b-switch
              v-model="projectEdit.internal"
              :disabled="projectEdit.type === 'OUTSOURCE'"
            >
              {{ $tf("projectForm.internal|Belső projekt") }}
            </b-switch>
          </b-field>
        </div>
      </div>
    </div>
    <div v-if="projectEdit.type === 'BASIC'">
      <div class="columns">
        <div class="column">
          <div class="is-flex is-align-items-center">
            <money-input
              v-model="projectEdit.budget"
              :label="$tf('projectForm.budget|Büdzsé')"
              class="mb-2 ml-0 pr-0"
              custom-class="is-full-width"
              label-position="on-border"
              required
            />
          </div>

          <p v-if="!projectEdit.internal" class="money-subtext">
            {{ $tf("projectForm.calculatedProfit|Kalkulált profit:") }}
            <span>{{ projectEdit.expectedIncome - projectEdit.budget }}</span>
          </p>
        </div>
        <div class="column">
          <div class="is-flex is-align-items-center">
            <money-input
              v-model="projectEdit.expectedIncome"
              :disabled="projectEdit.internal"
              :label="$tf('projectForm.expectedIncome|Várható Bevétel:')"
              :required="!projectEdit.internal"
              class="mb-2 ml-0 pr-0"
              custom-class="is-full-width"
              label-position="on-border"
            />
          </div>
          <p v-if="!projectEdit.internal" class="money-subtext">
            {{ $tf("projectForm.calculatedProfitRate|Kalkulált profitráta:") }}
            <span>{{
              percentify(
                (projectEdit.expectedIncome - projectEdit.budget) /
                  projectEdit.expectedIncome
              )
            }}</span>
          </p>
        </div>
        <div class="column">
          <money-input
            v-model="projectEdit.commitment"
            :label="$tf('projectForm.commitment|Vállalás (előzetes becslés)')"
            class="mb-2"
            day
            expanded
            label-position="on-border"
            required
          />
        </div>
      </div>
    </div>
    <div>
      <div class="my-5">
        <h3 class="title mb-5">
          {{ $tf("projectForm.dates.title|Időpontok") }}
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            position="is-right"
            type="is-info"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectForm.dates.subtitle|Fontos időpontok a projekt folyamatában"
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </div>
      <template v-if="datesAreSet">
        <div class="is-flex is-flex-direction-column">
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
          >
            <div class="is-half-width">
              <div class="is-flex is-align-items-center">
                <b-field
                  :label="$tf('projectForm.dates.startDate|Kezdés dátuma')"
                  class="datepicker-field has-blue-icon"
                  expanded
                  label-position="on-border"
                  style="width: 100%"
                >
                  <f-datepicker
                    v-model="projectEdit.startDate"
                    :placeholder="
                      $tf(
                        'projectForm.dates.startDate.placeholder|Kezdés dátuma'
                      )
                    "
                    class="has-blue-icon"
                    expanded
                    icon="calendar-day"
                  />
                </b-field>
              </div>
            </div>
            <div class="is-half-width">
              <div class="is-flex is-align-items-center">
                <b-tooltip
                  v-if="isDateChanged()"
                  :label="
                    $tf(
                      'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                    )
                  "
                  multilined
                  size="is-small"
                  type="is-warning"
                >
                  <b-icon
                    icon="triangle-exclamation"
                    type="is-warning"
                  ></b-icon>
                </b-tooltip>
                <b-field
                  v-if="!isSelfTracking"
                  :label="$tf('projectForm.dates.dueDate|Határidő')"
                  class="datepicker-field has-blue-icon"
                  expanded
                  label-position="on-border"
                  style="width: 100%"
                >
                  <f-datepicker
                    v-model="projectEdit.dueDate"
                    :placeholder="
                      $tf('projectForm.dates.dueDate.placeholder|Határidő')
                    "
                    class="has-blue-icon"
                    expanded
                    icon="calendar-day"
                  >
                  </f-datepicker>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div>
      <div class="my-5">
        <div class="mb-5">
          <h3 class="title">
            {{ $tf("projectForm.dimensions.title|Dimenziók") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.dimensions.subtitle|Projekt dimenzió beállítások"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
        </div>
        <div
          class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-4"
        >
          <div class="is-half-width">
            <dimension-input
              v-for="(dimension, index) of dimensions"
              :key="index"
              v-model="selectedDimensionValues[dimension.dimensionName]"
              :dimension="dimension"
            />
          </div>
        </div>
        <div
          class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
        >
          <div class="is-half-width">
            <b-field
              expanded
              :label="$tf('projectForm.color.label|Színek')"
              label-position="on-border"
            >
              <b-select
                v-model="projectEdit.color"
                class="has-blue-icon"
                icon="palette"
                :placeholder="$tf('projectForm.color.placeholder|Szín')"
              >
                <option
                  v-for="color in projectColors"
                  :key="color.id"
                  :value="color.id"
                >
                  {{ color.colorName }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <ProjectCollapse
        id="meta-data"
        :header="$tf('projectForm.misc.title|Egyéb kiegészítő adatok')"
      >
        <div class="content mt-5">
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
          >
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.managementType|Szervezés típusa')"
                expanded
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="managementTypeInput"
                  :data="managementTypes"
                  :open-on-focus="true"
                  :placeholder="
                    $tf(
                      'projectForm.misc.managementType.placeholder|Szervezés típusa'
                    )
                  "
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="cogs"
                  @select="
                    (option) => (projectEdit.managementType = option?.id)
                  "
                >
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.pricingModel|Árazás')"
                expanded
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="pricingModelInput"
                  :data="pricingModels"
                  :open-on-focus="true"
                  :placeholder="
                    $tf('projectForm.misc.pricingModel.placeholder|Árazás')
                  "
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="cogs"
                  @select="(option) => (projectEdit.pricingModel = option?.id)"
                >
                </b-autocomplete>
              </b-field>
            </div>
          </div>
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
          >
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.technologies|Technológiák')"
                expanded
                label-position="on-border"
              >
                <multiselect-dropdown
                  v-model="projectEdit.technologies"
                  :items="technologies"
                  append-to-body
                  identifier-field="id"
                  name-field="name"
                  expanded
                  searchable
                  clearable
                  scrollable
                  max-height="300"
                  icon="circle-xmark"
                >
                  <template #empty>{{
                    $tf("projectForm.misc.technologies.empty|Nincs találat")
                  }}</template>
                </multiselect-dropdown>
              </b-field>
            </div>
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.laborTypes|Technológiák')"
                expanded
                label-position="on-border"
              >
                <multiselect-dropdown
                  v-model="projectEdit.laborTypes"
                  :items="laborTypes"
                  append-to-body
                  identifier-field="id"
                  name-field="name"
                  expanded
                  searchable
                  clearable
                  scrollable
                  max-height="300"
                  icon="circle-xmark"
                >
                  <template #empty>{{
                    $tf("projectForm.misc.laborTypes.empty|Nincs találat")
                  }}</template>
                </multiselect-dropdown>
              </b-field>
            </div>
          </div>

          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
          >
            <div class="is-flex-1">
              <b-field
                :label="
                  $tf('projectForm.misc.service_type|Szolgáltatás típusa')
                "
                expanded
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="serviceTypeInput"
                  :data="serviceTypes"
                  :open-on-focus="true"
                  :placeholder="
                    $tf(
                      'projectForm.misc.service_type.placeholder|Szolgáltatás típusa'
                    )
                  "
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="cogs"
                  @select="(option) => (projectEdit.serviceType = option?.id)"
                >
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.income_type|Árbevétel típusa')"
                expanded
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="incomeTypeInput"
                  :data="incomeTypes"
                  :open-on-focus="true"
                  :placeholder="
                    $tf(
                      'projectForm.misc.income_type.placeholder|Árbevétel típusa'
                    )
                  "
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="money-bill-wave"
                  @select="(option) => (projectEdit.incomeType = option?.id)"
                >
                </b-autocomplete>
              </b-field>
            </div>
            <div class="is-flex-1">
              <b-field
                :label="$tf('projectForm.misc.sales_type|Sales típusa')"
                expanded
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="salesTypeInput"
                  :data="salesTypes"
                  :open-on-focus="true"
                  :placeholder="
                    $tf('projectForm.misc.sales_type.placeholder|Sales típusa')
                  "
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="chart-line"
                  @select="(option) => (projectEdit.salesType = option?.id)"
                >
                </b-autocomplete>
              </b-field>
            </div>
          </div>
          <b-field
            :label="$tf('projectForm.misc.summary|Összefoglaló, leírás')"
            expanded
            label-position="on-border"
          >
            <b-input v-model="projectEdit.summary" type="textarea"></b-input>
          </b-field>
          <b-field
            :label="
              $tf(
                'projectForm.misc.supportPotential|CR, support és upsell lehetőségek'
              )
            "
            expanded
            label-position="on-border"
          >
            <b-input
              v-model="projectEdit.supportPotential"
              type="textarea"
            ></b-input>
          </b-field>
          <h3 class="title">
            {{ $tf("projectForm.attachments.title|Csatolmányok") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.attachments.subtitle|Szerződések, specifikációk, egyéb dokumentumok."
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
          <h2 class="subtitle">
            {{
              $tf(
                "projectForm.attachments.subtitle|Szerződések, specifikációk, becslések, egyéb projekthez kapcsolódó dokumentumok feltöltése."
              )
            }}
          </h2>
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
          >
            <MultifileUpload v-model="uploadedFiles" />
          </div>
          <b-table v-if="projectData.documents" :data="projectData.documents">
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.attachments.table.file|Fájl')"
              field="displayName"
            >
              {{ props.row.displayName }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.attachments.table.uploader|Feltöltő')"
              field="createdBy"
            >
              {{ employees.find((e) => e.id === props.row.createdBy).name }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="
                $tf(
                  'projectForm.attachments.table.uploadedAt|Feltöltés időpontja'
                )
              "
              field="created"
            >
              {{ formatDate(props.row.created) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.attachments.table.download|Letöltés')"
              cell-class="has-text-centered"
              width="40"
            >
              <a @click="downloadFile(props.row.id, props.row.displayName)">
                <b-icon icon="download"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.attachments.table.delete|Törlés')"
              cell-class="has-text-centered"
              width="40"
            >
              <a @click="deleteFile(props.row.id)">
                <b-icon icon="trash" type="is-danger"></b-icon>
              </a>
            </b-table-column>
          </b-table>
        </div>
        <template #trigger="props">
          <div class="has-text-centered">
            <a class="link is-info">
              {{
                !props.open
                  ? $tf("projectForm.attachments.metaData|Metaadatok")
                  : $tf("projectForm.attachments.close|Bezárás")
              }}
              <br />
              <b-icon :icon="!props.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
          </div>
        </template>
      </ProjectCollapse>
    </div>
    <div v-if="projectEdit.type === 'OUTSOURCE'"></div>
    <div v-if="projectEdit.type === 'SUPPORT'"></div>
    <div class="has-text-centered mt-5 mb-4 is-flex">
      <b-field class="ml-auto">
        <b-button class="is-info" @click="saveProject()">
          {{ $tf("projectForm.createProject|Létrehozás") }}
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import {
  deepCopy,
  formatDate,
  isNullOrUndefined,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput";
import MultifileUpload from "@/components/module/input/MultifileUpload";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton";
import ProjectCollapse from "../module/ProjectCollapse";
import {
  ISSUE_TRACKING_SYSTEM,
  PROJECT_BASE_DATA_TYPE,
  PROJECT_TABS,
} from "@/utils/const";
import DimensionInput from "@/components/module/input/DimensionInput.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";

export default {
  name: "ProjectForm",
  components: {
    MultiselectDropdown,
    FDatepicker,
    DimensionInput,
    LoadingSkeleton,
    MultifileUpload,
    VuelidatedInput,
    ProjectCollapse,
    MoneyInput,
  },
  setup: () => useCustomVuelidate(),
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: false,
    },
  },
  validations: {
    projectEdit: {
      identifier: {
        required,
      },
      name: {
        required,
      },
      prefix: {
        required,
      },
    },
  },
  data() {
    return {
      selectedDimensionValues: {},
      moneyify,
      numberify,
      percentify,
      workdayify,
      formatDate,
      datesAreSet: false,
      managerName: "",
      clientName: "",
      colorName: "",
      projectKey: "",
      importProject: false,
      syncKeys: false,
      syncKeyLoading: false,
      downloadKeyLoading: false,
      dimensions: [],
      filteredColors: [],
      serviceTypeInput: undefined,
      incomeTypeInput: undefined,
      salesTypeInput: undefined,
      managementTypeInput: undefined,
      pricingModelInput: undefined,
      projectEdit: {
        internal: false,
        expectedIncome: 0,
        budget: 0,
        type: "BASIC",
        serviceType: undefined,
        incomeType: undefined,
        salesType: undefined,
        managementType: undefined,
        technologies: undefined,
        pricingModel: undefined,
        laborTypes: undefined,
      },
      uploadedFiles: [],
      loading: false,
      currentTab: 0,
    };
  },
  watch: {
    "projectEdit.type": function handler(val) {
      if (val === "OUTSOURCE") {
        this.projectEdit.internal = false;
      }
    },
  },
  computed: {
    isSelfTracking() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    filteredLeads() {
      let leads = this.leads || [];
      return leads.filter((element) => {
        if (!this.managerName || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    filteredClients() {
      let clients = this.clients || [];
      return clients.filter((element) => {
        if (!this.clientName || !element.identifier) return false;
        return (
          element.identifier
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    managementTypes() {
      return (
        this.projectBaseDataGetter(
          PROJECT_BASE_DATA_TYPE.ENUM.MANAGEMENT_TYPE
        ) || []
      );
    },
    pricingModels() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.PRICING_MODEL) ||
        []
      );
    },
    technologies() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.TECHNOLOGIES) ||
        []
      );
    },
    laborTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.LABOR_TYPES) ||
        []
      );
    },
    serviceTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.SERVICE_TYPE) ||
        []
      );
    },
    incomeTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.INCOME_TYPE) ||
        []
      );
    },
    salesTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.SALES_TYPE) || []
      );
    },
    ...mapGetters({
      clients: "enterprise_clients/clients",
      employees: "employee/loggingEmployees",
      leads: "employee/currentEmployees",
      projectKeys: "enterprise_projects/projectIdentifiers",
      projectColors: "project_dimensions/getProjectColors",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      projectBaseDataGetter: "project_base_data/getByType",
    }),
  },
  methods: {
    async preloadData() {
      const promises = [
        this.$store.dispatch("enterprise_clients/fetchClients"),
        this.$store.dispatch("project_dimensions/fetchProjectColorsCompact"),
        // this.$store.dispatch("employee/fetchLeads"),
        this.$store.dispatch(
          "project_dimensions/fetchActiveProjectDimensionsWithValues"
        ),
        this.$store.dispatch("project_base_data/fetchAll"),
      ];

      await Promise.all(promises);

      this.projectEdit.startDate = new Date();
      this.projectEdit.dueDate = new Date();
      this.datesAreSet = true;
      this.clientName = this.projectEdit.clientName;
      this.managerName = this.projectEdit.leadName;
      this.filterColors("");

      this.projectKey = {
        key: this.projectEdit.key,
        used: true,
        $isDisabled: true,
      };

      this.dimensions =
        this.$store.getters[
          "project_dimensions/getProjectDimensionsWithValues"
        ];

      if (this.projectEdit.dimensionValues) {
        this.projectEdit.dimensionValues.forEach((dimension) => {
          this.selectedDimensionValues[dimension.dimensionName] =
            dimension.values;
        });
      }
    },
    async saveProject() {
      if (this.isSelfTracking) {
        this.projectEdit.prefix = this.projectEdit.identifier;
      }
      if (
        !this.projectEdit.identifier ||
        !this.projectEdit.name ||
        !this.projectEdit.prefix
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "projectForm.error.emptyIdentifiers|Azonosító, prefix és név kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      if (
        !(
          this.projectEdit.lead &&
          this.filteredLeads
            .map((emp) => emp.id)
            .includes(this.projectEdit.lead)
        )
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "projectForm.error.emptyManager|Felelős megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      if (
        !(
          this.projectEdit.client &&
          this.filteredClients
            .map((cli) => cli.id)
            .includes(this.projectEdit.client)
        )
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "projectForm.error.emptyClient|Ügyfél megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      if (!this.projectEdit.type) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "projectForm.error.emptyData|Típus megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      this.touch$();
      if (!this.v$.$invalid) {
        await this.actuallySaveProject();
      }
    },
    getColorId(projectRequest) {
      if (projectRequest.colors && projectRequest.colors.length > 0) {
        const colorInHex = projectRequest.colors[0];
        const colorId = this.projectColors.find(
          (color) => color["colorInHex"] === colorInHex
        ).id;
        delete projectRequest.colors;
        return colorId;
      }
      return null;
    },
    async actuallySaveProject(comment) {
      let projectRequest = deepCopy(this.projectEdit);

      projectRequest.dueDate = formatDate(projectRequest.dueDate);
      projectRequest.startDate = formatDate(projectRequest.startDate);
      projectRequest.starred = false;
      projectRequest.prefix = projectRequest.prefix.toUpperCase();
      // projectRequest.color = this.getColorId(projectRequest);

      projectRequest.dimensionValues = Object.values(
        this.selectedDimensionValues
      )
        .filter(Array.isArray) // Filter only properties that are arrays
        .flatMap((dimensionValues) =>
          dimensionValues.map((dimValue) => dimValue.id)
        );

      delete projectRequest.calculatedProfit;
      delete projectRequest.calculatedProfitRate;
      delete projectRequest.spent;
      delete projectRequest.expenseTotal;
      delete projectRequest.income;

      if (projectRequest.internal) {
        delete projectRequest.expectedIncome;
      }

      if (this.projectEdit.type === "OUTSOURCE") {
        projectRequest.internal = false;
      }

      if (comment) {
        projectRequest.commentSummary = comment.summary;
        projectRequest.commentDescription = comment.description;
      }

      await this.$store.dispatch("enterprise_projects/createProject", {
        projectData: projectRequest,
      });
      await this.$store.dispatch("enterprise_core/clearCache");
      await this.$store.dispatch("outsource_projects/clearCache");
      await this.$store.dispatch("support_projects/clearCache");
      await this.$router.push({
        name: "ControllingProjects",
        hash: PROJECT_TABS.HASH[projectRequest.type],
      });
    },
    filterColors(text) {
      this.filteredColors = this.projectColors
        .filter((option) => {
          return (
            option.colorInHex
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        })
        .map((option) => option.colorInHex);
    },
    async selectProject(selectedOption) {
      this.loading = true;
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: selectedOption.identifier,
        force: true,
      });
      this.projectEdit = this.$store.getters["enterprise_projects/projectData"](
        selectedOption.identifier
      );
      this.projectEdit.identifier = selectedOption.identifier;
      if (this.projectEdit.startDate) {
        this.projectEdit.startDate = new Date(this.projectEdit.startDate);
      } else this.projectEdit.startDate = new Date();
      if (this.projectEdit.endDate) {
        this.projectEdit.endDate = new Date(this.projectEdit.endDate);
      } else this.projectEdit.endDate = new Date();
      if (this.projectEdit.dueDate) {
        this.projectEdit.dueDate = new Date(this.projectEdit.dueDate);
      } else this.projectEdit.dueDate = new Date();
      this.clientName = this.projectEdit.clientName;
      this.managerName = this.projectEdit.leadName;
      this.loading = false;
    },
    toDate(date) {
      const numbers = date.split("-");
      return new Date(numbers[0], numbers[1] - 1, numbers[2]);
    },
    isDateChanged() {
      if (
        isNullOrUndefined(this.projectData.dueDate) ||
        isNullOrUndefined(this.projectEdit.dueDate)
      ) {
        return false;
      }
      return (
        (this.toDate(this.projectData.dueDate).getFullYear() !==
          this.projectEdit.dueDate.getFullYear() ||
          this.toDate(this.projectData.dueDate).getMonth() !==
            this.projectEdit.dueDate.getMonth() ||
          this.toDate(this.projectData.dueDate).getDate() !==
            this.projectEdit.dueDate.getDate()) &&
        this.projectData.status !== "REQUEST"
      );
    },
    downloadFile(id, fileName) {
      this.$store.dispatch("enterprise_projects/downloadProjectFile", {
        fileId: id,
        fileName: fileName,
      });
    },
    deleteFile(id) {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projectForm.attachmentDeleteModal.message|Biztosan törölni szeretnéd a csatolmányt?"
        ),
        onConfirm: () => {
          if (
            this.$store.dispatch("enterprise_projects/deleteProjectFile", id)
          ) {
            // Vue.set(
            //   this.projectData,
            //   "documents",
            //   this.projectData.documents.filter(
            //     (document) => document.id !== id
            //   )
            // );
            // eslint-disable-next-line vue/no-mutating-props
            this.projectData.documents = this.projectData.documents.filter(
              (document) => document.id !== id
            );
          }
        },
      });
    },
    nextTab() {
      if (!this.projectEdit.type) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "projectForm.error.emptyData|Típus megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      this.touch$();
      if (!this.v$.$invalid) {
        this.currentTab = 1;
      }
    },
  },
  created() {
    this.preloadData();
    if (this.$router.currentRoute.value.hash === PROJECT_TABS.HASH.SUPPORT) {
      this.projectEdit.type = "SUPPORT";
    } else if (
      this.$router.currentRoute.value.hash === PROJECT_TABS.HASH.OUTSOURCE
    ) {
      this.projectEdit.type = "OUTSOURCE";
    }
  },
};
</script>

<style
  src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss">
@import "~@/assets/scss/colors";

.is-half-width {
  flex: 1;
  width: 50%;

  .select {
    select {
      width: 100%;
    }

    width: 100%;
  }

  @media screen and (max-width: 800px) {
    flex: 0 0 100%;
    margin-bottom: 20px;
    width: 100%;
  }
}

.is-full-width {
  .field-body {
    .field {
      .control {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;

  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}

.info-boxes {
  gap: 20px;
  @media screen and (max-width: 800px) {
    justify-content: center !important;
    * {
      width: 100%;
    }
  }
}

.title {
  margin-bottom: 8px;
}

.is-flex {
  gap: 10px;
}

.is-used-key {
  color: $red;
  font-weight: bold;
  cursor: not-allowed;
}

.projet-type-label {
  display: flex;
  align-items: center;
  color: $custom-dark-blue;
}
</style>
