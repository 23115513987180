<template>
  <div>
    <table
      aria-label="since-start"
      class="has-text-dark-blue has-font-weight-500 since-start-table"
    >
      <thead>
        <tr>
          <th></th>
          <th>
            {{ $tf("projectPage.forecastSinceStart.table.mwd|Munkaidő") }}
          </th>
          <th>
            {{
              $tf("projectPage.forecastSinceStart.budgetSpent|Büdzsé/Költés")
            }}
          </th>
          <th>{{ $tf("projectPage.forecastSinceStart.time|Átfutási idő") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="plan-row">
          <td>
            {{ $tf("projectPage.forecastSinceStart.table.plan|Terv") }}
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.plan.mwd.tooltip|A készültség alapján eddig ennyi munkaidő mehetett volna a projektbe'
                )
              "
            >
              {{ workdayify(manDayPlan) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.plan.budgetSpent.tooltip|A készültség alapján eddig ekkora költség lenne ideális a projekten'
                )
              "
            >
              {{ moneyify(budgetPlan) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.plan.time.tooltip|A készültség alapján ennyi munkanapnak kellett volna eltelnie eddig a kezdeti dátumtól'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(daysPlan) }}
            </b-tooltip>
          </td>
        </tr>
        <tr class="fact-row">
          <td>
            {{ $tf("projectPage.forecastSinceStart.table.fact|Tény") }}
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.fact.mwd.tooltip|A logolások alapján eddig ennyi munkaidő ment bele a projektbe'
                )
              "
            >
              {{ workdayify(projectDetails.sumSpentMd) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.fact.budgetSpent.tooltip|A logolások alapján számított eddigi költség'
                )
              "
            >
              {{ moneyify(projectDetails.sumSpentBudget) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.fact.time.tooltip|Ennyi naptári munkanap telt el a projekt kezdetétől a mai napig'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(projectDetails.daysSinceStart) }}
            </b-tooltip>
          </td>
        </tr>
        <tr class="abs-row">
          <td class="is-vertical-align-middle">
            {{
              $tf(
                "projectPage.forecastSinceStart.table.planVsFact|Terv vs Tény"
              )
            }}
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.planVsFact.mwd.tooltip|Az eddigi munkaidő költés és a tervszám különbsége, és e különbség aránya a tervhez képest. (|költés-(vállalás*készültség)| / (vállalás*készültség))'
                )
              "
              position="is-right"
            >
              {{ workdayify(manDayDiffAbs) }}
              <span class="table-percent">
                ({{ percentify(manDayDiffPercent) }})
              </span>
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.planVsFact.budgetSpent.tooltip|Az eddigi büdzsé költés és a tervszám különbsége, és e különbség aránya a tervhez képest. (|költség-(büdzsé*készültség)| / (büdzsé*készültség))'
                )
              "
              position="is-right"
            >
              {{ moneyify(budgetDiffAbs) }}
              <span class="table-percent">
                ({{ percentify(budgetDiffPercent) }})
              </span>
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.planVsFact.mwd.tooltip|Az átfutásból eddig eltelt napok száma és a tervszám különbsége, és e különbség aránya a tervhez képest. (|eddigi-(átfutás*készültség)| / (átfutás*készültség))'
                )
              "
              position="is-left"
            >
              {{
                $tf(
                  "projectPage.forecastSinceStart.table.daysDiffAbs|{days} nap",
                  { days: numberify(daysDiffAbs) }
                )
              }}
              <span class="table-percent">
                ({{ percentify(daysDiffPercent) }})
              </span>
            </b-tooltip>
          </td>
        </tr>
        <tr class="full-project-plan-row">
          <td>
            {{
              $tf(
                "projectPage.forecastSinceStart.table.totalProjectPlan|Teljes projekt terv"
              )
            }}
          </td>
          <td class="has-font-weight-500">
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPlan.mwd.tooltip|A projektre tett teljes vállalás'
                )
              "
            >
              {{ workdayify(projectDetails.commitment) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPlan.budgetSpent.tooltip|A projektre tett teljes büdzsé'
                )
              "
              >{{ moneyify(projectDetails.budget) }}</b-tooltip
            >
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPlan.time.tooltip|A projekt átfutása, tehát a kezdeti dátumától a határidőig fellelhető munkanapok száma'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(projectDetails.daysBetweenDue) }}
            </b-tooltip>
          </td>
        </tr>
        <tr class="full-project-percentage-row">
          <td>
            {{
              $tf(
                "projectPage.forecastSinceStart.table.totalProjectPercentage|Teljes projekt %-ban"
              )
            }}
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPercentage.mwd.tooltip|Az eddig munkaidő költés és a projekt eredeti teljes vállalásának aránya'
                )
              "
            >
              <span class="table-percent">
                {{ percentify(manDayWholePercent) }}
              </span>
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPercentage.budgetSpent.tooltip|Az eddig büdzsé költség és a projekt eredeti teljes büdzséjének aránya'
                )
              "
            >
              {{ percentify(budgetWholePercent) }}
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPercentage.time.tooltip|Az eddig eltelt munkanapok száma és a projekt eredeti teljes átfutásának aránya'
                )
              "
              position="is-left"
            >
              {{ percentify(daysWholePercent) }}
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            {{
              $tf(
                "projectPage.forecastSinceStart.table.projectRiskKPI|Projekt risk KPI"
              )
            }}
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.projectRiskKPI.mwd.tooltip|Az eddigi munkaidő költés és az ekkora készültségre vonatkozó tervszám aránya'
                )
              "
            >
              <RiskKPITag :value="manDayRisk" />
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.projectRiskKPI.budgetSpent.tooltip|Az eddigi büdzsé költség és az ekkora készültségre vonatkozó tervszám aránya'
                )
              "
            >
              <RiskKPITag :value="budgetRisk" />
            </b-tooltip>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.projectRiskKPI.time.tooltip|Az eddig eltelt munkanapok száma és az ekkora készültségre vonatkozó tervszám aránya'
                )
              "
              position="is-left"
            >
              <RiskKPITag :value="daysRisk" />
            </b-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  calendarDayify,
  formatDate,
  moneyify,
  numberify,
  percentify,
  roundToTwoDecimals,
  workdayify,
} from "@/utils/util";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import KpiMixin from "@/mixins/KpiMixin";
import RiskKpiTag from "../RiskKPITag.vue";

export default {
  name: "ForecastSinceStartTable",
  components: { RiskKPITag: RiskKpiTag },
  mixins: [KpiMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },

    manDayPlan() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.commitment * this.projectDetails.readiness
      );
    },
    manDayDiffAbs() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.sumSpentMd - this.manDayPlan
      );
    },
    manDayDiffPercent() {
      if (this.notTracked) return "-";
      return this.manDayDiffAbs / this.manDayPlan;
    },
    manDayWholePercent() {
      return this.projectDetails.sumSpentMd / this.projectDetails.commitment;
    },
    manDayRisk() {
      return roundToTwoDecimals(
        this.manDayWholePercent / this.projectDetails.readiness
      );
    },

    budgetPlan() {
      if (this.notTracked) return "-";
      return this.projectDetails.budget * this.projectDetails.readiness;
    },
    budgetDiffAbs() {
      if (this.notTracked) return "-";
      return this.projectDetails.sumSpentBudget - this.budgetPlan;
    },
    budgetDiffPercent() {
      if (this.notTracked) return "-";
      return this.budgetDiffAbs / this.budgetPlan;
    },
    budgetWholePercent() {
      return this.projectDetails.sumSpentBudget / this.projectDetails.budget;
    },
    budgetRisk() {
      return roundToTwoDecimals(
        this.budgetWholePercent / this.projectDetails.readiness
      );
    },
    daysPlan() {
      return roundToTwoDecimals(
        this.projectDetails.daysBetweenDue * this.projectDetails.readiness
      );
    },
    daysDiffAbs() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.daysSinceStart - this.daysPlan
      );
    },
    daysDiffPercent() {
      if (this.notTracked) return "-";
      return this.daysDiffAbs / this.daysPlan;
    },
    daysWholePercent() {
      return (
        this.projectDetails.daysSinceStart / this.projectDetails.daysBetweenDue
      );
    },
    daysRisk() {
      return roundToTwoDecimals(
        this.daysWholePercent / this.projectDetails.readiness
      );
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: { calendarDayify },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.plan-row > td {
  background: $grey-lightest;
  color: $custom-dark-blue !important;
  // border: none !important;
}

.fact-row > td {
  background: $grey-lighter;
  color: $custom-dark-blue !important;
  // border: none !important;
}

.full-project-plan-row > td {
  background: $grey-lightest;
  color: $custom-dark-blue !important;
  // border: none !important;
}

.abs-row,
.full-project-percentage-row {
  // border-bottom: 1px dashed $grey-lighter;
}

.since-start-table th,
.since-start-table td {
  color: $custom-dark-blue;
}

table {
  border-collapse: unset;
  border-spacing: 0 0.5rem;
}

th {
  border: none !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

tr {
  td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-left: 1px solid #eceff5 !important;
    font-weight: 700 !important;
  }

  td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: 1px solid #eceff5 !important;
  }

  td {
    border-bottom: 1px solid #eceff5 !important;
    border-top: 1px solid #eceff5 !important;
    padding: 16px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.table-percent {
  display: block;
  font-size: 12px;
  font-weight: 400;
}
</style>
