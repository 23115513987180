<template>
  <b-field
    :is="withLoading ? BFieldWithLoading : BField"
    :expanded="expanded"
    v-bind="$attrs"
    :data-has-vuelidate-error="validationRule.$error"
  >
    <vuelidated-form
      :validations="validationRule"
      @submit.prevent="$emit('submit', $event)"
    >
      <template v-slot="{ errors, invalid }">
        <b-field
          :expanded="expanded"
          :label-for="refValue"
          :label-position="labelPosition ? labelPosition : ''"
          :message="validationRule.$error ? errors[0] : null"
          :type="{ 'is-danger': invalid }"
        >
          <template #label>
            {{ label }}
            <span
              v-if="label && isFieldRequired"
              class="has-text-danger"
              >*</span
            >
          </template>
          <slot>
            <template v-if="type === 'color'">
              <b-colorpicker
                v-model="computedValue"
                :autocomplete="'off'"
                v-bind="$attrs"
                @blur="validationRule.$touch()"
                @change="
                  (color) =>
                    (computedValue = color.toString('hex').toUpperCase())
                "
                @focus="selectOnFocus ? $event.target.select() : null"
              />
            </template>
            <template v-else-if="type === 'checkbox'">
              <div
                class="is-flex is-flex-direction-column"
                @blur="validationRule.$touch()"
                @keyup.enter="(val) => $emit('keyup.enter', val)"
              >
                <div v-for="(item, index) in checkboxValues" :key="index">
                  <b-checkbox
                    v-model="computedValue"
                    :disabled="disabled"
                    :native-value="item[checkboxValueField]"
                  >
                    {{ item[checkboxLabelField] }}
                  </b-checkbox>
                </div>
              </div>
            </template>
            <template v-else-if="type === 'select'">
              <b-select
                :id="refValue"
                :ref="refValue"
                v-model="computedValue"
                :disabled="disabled"
                :expanded="expanded"
                :icon="icon"
                :name="refValue"
                :placeholder="
                  placeholder
                    ? placeholder
                    : $tf(
                        'vuelidatedInput.selectPlaceholder|Válassz a listából'
                      )
                "
              >
                <slot name="select"></slot>
              </b-select>
            </template>
            <template v-else-if="type === 'date'">
              <f-datepicker
                :id="refValue"
                :ref="refValue"
                v-model="computedValue"
                :append-to-body="appendToBody"
                :clearable="clearable"
                :disabled="disabled"
                :expanded="expanded"
                :icon="icon"
                :icon-right="iconRight"
                :name="refValue"
                :open-on-focus="openOnFocus"
                :placeholder="
                  placeholder
                    ? placeholder
                    : $tf(
                        'vuelidatedInput.datePickerPlaceholder|Válassz dátumot'
                      )
                "
                :multiple="multiple"
                :inline="inline"
                @blur="validationRule.$touch()"
                @focus="selectOnFocus ? $event.target.select() : null"
              />
            </template>
            <template v-else-if="type === 'autocomplete'">
              <f-autocomplete
                :id="refValue"
                :ref="refValue"
                v-model="computedValue"
                :append-to-body="appendToBody"
                :clearable="clearable"
                :data="data"
                :disabled="disabled"
                :field="field"
                :icon="icon"
                :id-field="idField"
                :name="refValue"
                :open-on-focus="openOnFocus"
                :placeholder="
                  placeholder
                    ? placeholder
                    : $tf(
                        'vuelidatedInput.autocompletePlaceholder|Válassz a listából'
                      )
                "
                @blur="validationRule.$touch()"
                @focus="selectOnFocus ? $event.target.select() : null"
              />
            </template>
            <template v-else-if="type === 'taginput'">
              <b-taginput
                :id="refValue"
                :ref="refValue"
                v-model="computedValue"
                :allow-new="allowNew"
                :data="data"
                :open-on-focus="openOnFocus"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :field="field"
                :icon="icon"
                @typing="$emit('typing', $event)"
              />
            </template>
            <template v-else>
              <b-input
                :id="refValue"
                :ref="refValue"
                v-model="computedValue"
                :autocomplete="'off'"
                :disabled="disabled"
                :expanded="expanded"
                :icon="icon"
                :name="refValue"
                :password-reveal="type === 'password'"
                :placeholder="placeholder"
                :type="type"
                clearable
                @blur="validationRule.$touch()"
                @focus="selectOnFocus ? $event.target.select() : null"
                @keyup.enter="(val) => $emit('keyup.enter', val)"
              />
            </template>
          </slot>
        </b-field>
      </template>
    </vuelidated-form>
  </b-field>
</template>

<script>
import VuelidatedForm from "./VuelidatedForm";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";
import BFieldWithLoading from "@/components/loading/BFieldWithLoading.vue";
import { BField } from "buefy/src/components/field";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import {unref} from "vue";

export default {
  name: "VuelidatedInput",
  components: { FDatepicker, FAutocomplete, VuelidatedForm },
  emits: ["submit", "keyup.enter"],
  props: {
    modelValue: {
      type: [String, Number, Boolean, Array, Date],
      default: () => null,
    },
    validationRule: {
      type: Object,
      default: () => {},
    },
    refValue: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    selectOnFocus: {
      type: Boolean,
      required: false,
    },
    checkboxValues: {
      type: Array,
      required: false,
    },
    checkboxValueField: {
      type: String,
      required: false,
      default: "id",
    },
    checkboxLabelField: {
      type: String,
      required: false,
      default: "name",
    },
    field: {
      type: String,
      required: false,
      default: "name",
    },
    idField: {
      type: String,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    withLoading: {
      type: Boolean,
      default: false,
    },
    allowNew: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isFieldRequired() {
      const requiredFunction = this.validationRule.required;
      if(!requiredFunction) return false;
      if(requiredFunction.$params.type === "required") return true;
      return requiredFunction.$params.type === "requiredIf" && requiredFunction.$response === false;
    }
  },
  data() {
    return {
      BFieldWithLoading,
      BField,
    };
  },
};
</script>

<style scoped></style>
