<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else class="tig-form">
      <component
        :is="
          issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum
            ? 'b-tabs'
            : 'div'
        "
        v-model="tabs"
        class="tab-without-padding"
        expanded
        type="is-toggle"
      >
        <component
          :is="
            issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum
              ? 'b-tab-item'
              : 'div'
          "
          id="current"
          key="current"
          :label="$tf('tigForm.tabs.base|Törzsadatok')"
        >
          <h3 class="title">
            {{ $tf("tigForm.base.identifiers|Azonosítók") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="tooltip">
                  <p>
                    {{
                      $tf(
                        "tigForm.tabs.base.identifiers.tooltip|Az azonosítókat kézzel szükséges kitölteni."
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
          <b-field grouped class="mt-4">
            <b-field
              :label="$tf('tigForm.base.identifier|Azonosító')"
              label-position="on-border"
            >
              <b-input v-model="tigEdit.identifier"></b-input>
            </b-field>
            <b-field
              :label="$tf('tigForm.base.type|Típus')"
              label-position="on-border"
            >
              <b-select
                v-model="tigEdit.type"
                :placeholder="$tf('tigForm.base.type.placeholder|Típus')"
                icon="swatchbook"
              >
                <option
                  v-for="tigType in Object.keys(TIG_TYPES)"
                  :value="tigType"
                  :key="`tigForm.type.dropdown.${tigType}`"
                >
                  {{ $tf(TIG_TYPES[tigType]) }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="$tf('tigForm.base.name|Név')"
              expanded
              label-position="on-border"
            >
              <b-input v-model="tigEdit.name" required></b-input>
            </b-field>
          </b-field>
          <h3 class="title">
            {{ $tf("tigForm.timing.title|Időzítések") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="tooltip">
                  <p>
                    {{
                      $tf(
                        "tigForm.timing.tooltip|Mérföldkőhöz kapcsolódó dátumok"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
          <b-field v-if="datesAreSet" grouped class="mt-4">
            <b-field
              :label="$tf('tigForm.timing.startDate|Kezdés dátuma')"
              label-position="on-border"
            >
              <f-datepicker
                v-model="tigEdit.startDate"
                :placeholder="
                  $tf('tigForm.timing.startDate.placeholder|Kezdés dátuma')
                "
                class="has-blue-icon"
                icon="calendar-alt"
                append-to-body
              ></f-datepicker>
            </b-field>
            <b-field
              :label="$tf('tigForm.timing.dueDate|Határidő')"
              label-position="on-border"
            >
              <f-datepicker
                v-model="tigEdit.dueDate"
                :placeholder="$tf('tigForm.timing.dueDate|Határidő')"
                class="has-blue-icon"
                icon="calendar-alt"
                append-to-body
              ></f-datepicker>
            </b-field>
          </b-field>
          <h3 class="title">
            {{ $tf("tigForm.data.title|Adatok") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="tooltip">
                  <p>{{ $tf("tigForm.data.tooltip|Mérföldkő alapadatai") }}</p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
          <div v-if="tigEdit.type !== 'UNTRACKED'" class="is-flex mt-4">
            <div>
              <money-input
                :label="$tf('tigForm.data.commitment|Vállalás')"
                :modelValue="tigEdit.commitment ? tigEdit.commitment : ''"
                day
                label-position="on-border"
                @update:modelValue="(val) => (tigEdit.commitment = val)"
              />
            </div>
            <div>
              <money-input
                :label="$tf('tigForm.data.budget|Büdzsé')"
                :modelValue="tigEdit.budget ? tigEdit.budget : ''"
                label-position="on-border"
                @update:modelValue="(val) => (tigEdit.budget = val)"
              />
            </div>
          </div>
          <div class="is-flex mb-5 mt-5">
            <div v-if="tigEdit.type !== 'MILESTONE'">
              <money-input
                :label="$tf('tigForm.data.billableAmount|Számlázható összeg')"
                :modelValue="
                  tigEdit.billableAmount ? tigEdit.billableAmount : ''
                "
                label-position="on-border"
                @update:modelValue="(val) => (tigEdit.billableAmount = val)"
                :disabled="projectData.internal"
              />
            </div>
            <div v-if="!create">
              <b-field
                :label="$tf('tigForm.data.status|Státusz')"
                label-position="on-border"
              >
                <b-select
                  v-model="tigEdit.status"
                  :placeholder="$tf('tigForm.data.status.placeholder|Státusz')"
                  icon="thermometer-half"
                >
                  <option value="PLANNED">
                    {{ $tf("tigForm.data.status.planned|Tervezett") }}
                  </option>
                  <option value="PENDING">
                    {{ $tf("tigForm.data.status.pending|Folyamatban") }}
                  </option>
                  <option value="COMPLETED">
                    {{ $tf("tigForm.data.status.completed|Kész") }}
                  </option>
                  <option value="BILLABLE">
                    {{ $tf("tigForm.data.status.billable|Számlázható") }}
                  </option>
                  <option value="BILLED">
                    {{ $tf("tigForm.data.status.billed|Számlázott") }}
                  </option>
                  <option value="PAID">
                    {{ $tf("tigForm.data.status.paid|Kifizetve") }}
                  </option>
                  <option value="CANCELLED">
                    {{ $tf("tigForm.data.status.cancelled|Visszavonva") }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </component>
        <b-tab-item
          v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
          id="issueTargeting"
          key="issueTargeting"
          :disabled="tigEdit.type === 'UNTRACKED'"
          :label="$tf('tigForm.tabs.issueTargeting|Vonatkozó jegyek')"
        >
          <external-issue-targeting-form
            v-model="issueTargeting"
            :prefix="projectData.prefix"
            :project-identifier="projectIdentifier"
            :project-type="PROJECT_TYPES.BASIC"
            @loading="isTargetingLoading = $event"
          />
        </b-tab-item>
      </component>
      <div class="is-flex is-justify-content-flex-end mb-0">
        <b-field class="mb-0">
          <b-button :loading="buttonsLoading" size="is-small" @click="cancel()">
            {{ $tf("tigForm.cancel|Mégse") }}
          </b-button>
        </b-field>
        <b-field class="mb-0">
          <b-button
            v-if="!create"
            :loading="buttonsLoading"
            class="is-info"
            size="is-small"
            @click="saveTig(true)"
          >
            {{ $tf("tigForm.saveWithComment|Mentés megjegyzéssel") }}
          </b-button>
        </b-field>
        <b-field class="mb-0">
          <b-button
            :loading="buttonsLoading"
            size="is-small"
            type="is-info"
            @click="saveTig(false)"
          >
            {{ $tf("tigForm.save|Mentés") }}
          </b-button>
        </b-field>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isTargetingLoading"
      :can-cancel="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { deepCopy, formatDate, moneyify, numberify } from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton";
import JournalTigModal from "@/components/enterprise/project/journal/JournalTigModal";
import { ISSUE_TRACKING_SYSTEM, PROJECT_TYPES, TIG_TYPES } from "@/utils/const";
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "TigForm",
  components: {
    FDatepicker,
    ExternalIssueTargetingForm,
    LoadingSkeleton,
    MoneyInput,
  },
  emits: ["tig-saved", "cancel"],
  setup: () => useCustomVuelidate(),
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    tigData: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moneyify,
      numberify,
      formatDate,
      TIG_TYPES,
      datesAreSet: false,
      tigIdentifier: "",
      tigEdit: {},
      issueTargeting: {},
      buttonsLoading: false,
      loading: false,
      importTig: false,
      tabs: 0,
      ISSUE_TRACKING_SYSTEM,
      PROJECT_TYPES,
      isTargetingLoading: false,
    };
  },
  watch: {},
  computed: {
    filteredKeys() {
      let keys = deepCopy(this.tigKeys);
      keys.forEach(function (element) {
        element["$isDisabled"] = element.used;
      });
      keys.sort((a, b) => a.keys < b.keys);
      return keys;
    },
    ...mapGetters({
      tigKeys: "enterprise_projects/tigIdentifiers",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  validations: {
    tigEdit: {
      identifier: {
        required,
      },
    },
  },
  methods: {
    async saveTig(withComment) {
      this.buttonsLoading = true;
      if (!this.tigEdit.identifier || !this.tigEdit.name) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Azonosító és Név megadása <strong>KÖTELEZŐ</strong>!`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.buttonsLoading = false;
        return;
      }

      if (!this.tigEdit.type) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "tigForm.error.typeMissing|Típus megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        this.buttonsLoading = false;
        return;
      }

      if (!withComment && this.tigData.status !== "PLANNED" && !this.create) {
        if (
          this.tigData.budget !== this.tigEdit.budget ||
          this.tigData.expectedIncome !== this.tigEdit.expectedIncome ||
          this.tigData.commitment !== this.tigEdit.commitment ||
          this.tigData.startDate !== formatDate(this.tigEdit.startDate) ||
          this.tigData.dueDate !== formatDate(this.tigEdit.dueDate) ||
          this.tigData.billableAmount !== this.tigEdit.billableAmount
        ) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$tf(
              "tigForm.protectedDataNeedsComment.error|Védett adat módosításához megjegyzés kötelező!"
            ),
            position: "is-bottom",
            type: "is-warning",
          });
          withComment = true;
        }
      }

      if (withComment) {
        this.$buefy.modal.open({
          parent: this,
          component: JournalTigModal,
          props: {
            tigData: this.tigData,
            tigEdit: this.tigEdit,
          },
          events: {
            "tig-saved": (comment) => {
              this.actuallySaveTig(comment);
            },
          },
          hasModalCard: true,
          trapFocus: true,
          width: "100%",
        });
      } else {
        await this.actuallySaveTig();
      }
      this.buttonsLoading = false;
    },
    async actuallySaveTig(comment = {}) {
      this.buttonsLoading = true;
      let tigRequest = deepCopy(this.tigEdit);

      if (tigRequest.grossFlatRate) {
        tigRequest.grossFlatRate = numberify(tigRequest.grossFlatRate);
      }

      tigRequest.startDate = formatDate(tigRequest.startDate);
      tigRequest.dueDate = formatDate(tigRequest.dueDate);
      delete tigRequest.billablePercentage;

      if (this.projectData.internal) {
        delete tigRequest.billableAmount;
      }

      if (comment) {
        tigRequest.commentSummary = comment.summary;
        tigRequest.commentDescription = comment.description;
      }

      if (this.create) {
        const savedTig = await this.$store.dispatch(
          "enterprise_projects/createTig",
          {
            projectIdentifier: this.projectIdentifier,
            tig: tigRequest,
          }
        );
        tigRequest.id = savedTig.id;
      } else {
        await this.$store.dispatch("enterprise_projects/saveTig", tigRequest);
      }

      if (this.issueTargeting.mode) {
        await this.$store.dispatch("enterprise_projects/createTigTargeting", {
          tigId: tigRequest.id,
          targetingData: this.issueTargeting,
        });
      }

      this.$emit("tig-saved");
      this.buttonsLoading = false;
    },
    preloadData() {
      if (this.create) {
        this.tigEdit.startDate = new Date();
        this.tigEdit.dueDate = new Date();
        this.tigEdit.type = "TIG";
        this.datesAreSet = true;
        this.issueTargeting = {};
      } else {
        this.tigEdit = deepCopy(this.tigData);
        if (this.tigEdit.startDate) {
          this.tigEdit.startDate = new Date(this.tigEdit.startDate);
        } else this.tigEdit.startDate = new Date();
        if (this.tigEdit.startDate) {
          this.tigEdit.dueDate = new Date(this.tigEdit.dueDate);
        } else this.tigEdit.dueDate = new Date();
        this.datesAreSet = true;
        this.tigIdentifier = {
          identifier: this.tigEdit.identifier,
          used: true,
          $isDisabled: true,
        };
        if (
          this.tigEdit.type !== "UNTRACKED" &&
          this.tigEdit.externalIssueTargeting
        )
          this.issueTargeting = deepCopy(this.tigData.externalIssueTargeting);
      }
    },
    async selectTig(selectedOption) {
      this.loading = true;
      await this.$store.dispatch(
        "enterprise_projects/fetchTigData",
        selectedOption.key
      );
      let imported = this.$store.getters["enterprise_projects/tigData"](
        selectedOption.key
      );
      this.tigEdit.key = selectedOption.key;
      if (imported.type) {
        this.tigEdit.type = imported.type;
      }
      if (imported.name) {
        this.tigEdit.name = imported.name;
      }
      if (imported.startDate) {
        this.tigEdit.startDate = new Date(imported.startDate);
      } else this.tigEdit.startDate = new Date();
      if (imported.endDate) {
        this.tigEdit.endDate = new Date(imported.endDate);
      } else this.tigEdit.endDate = new Date();
      if (imported.dueDate) {
        this.tigEdit.dueDate = new Date(imported.dueDate);
      } else this.tigEdit.dueDate = new Date();
      this.loading = false;
    },
    cancel() {
      this.$emit("cancel");
    },
    toDate(date) {
      const numbers = date.split("-");
      return new Date(numbers[0], numbers[1] - 1, numbers[2]);
    },
  },
  created() {
    this.preloadData();
  },
};
</script>

<style
  src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss" scoped>
@import "@/assets/scss/colors";

.is-flex {
  gap: 30px;
}

button {
  border-radius: 8px !important;
}

.is-used-key {
  color: $red;
  font-weight: bold;
  text-decoration: line-through;
}
</style>
