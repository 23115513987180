<template>
  <vuelidated-form
    :validations="normalizedValidationRule"
    @submit.prevent="$emit('submit', $event)"
  >
    <template v-slot="{ errors, invalid }">
      <b-field
        :label-position="labelPosition"
        class="money-input"
        style="width: 100%"
        :class="customClass"
        :type="{ 'is-danger': invalid }"
        :message="normalizedValidationRule.$error ? errors[0] : null"
      >
        <template #label>
          {{ label }}
          <span
            v-if="
              label &&
              normalizedValidationRule?.$params &&
              'required' in normalizedValidationRule.$params
            "
            class="has-text-danger"
            >*</span
          >
        </template>
        <b-input
          v-model="computedValue"
          :disabled="disabled"
          ref="input"
          :expanded="expanded"
        ></b-input>
        <div
          class="is-flex money-label is-justify-content-center is-align-items-center"
          :class="{ 'day-label': day, 'percentage-label': percentage }"
        >
          <div>
            <p v-if="day" class="is-day">{{ $tf("calendar.day|Nap") }}</p>
            <p v-else-if="hour" class="is-day">
              {{ $tf("calendar.hour|Óra") }}
            </p>
            <p v-else-if="percentage" class="is-percentage">%</p>
            <p v-else class="is-money">{{ currencySymbol }}</p>
          </div>
        </div>
      </b-field>
    </template>
  </vuelidated-form>
</template>

<script>
import { mapGetters } from "vuex";

import { isNullOrUndefined, normalize } from "@/utils/util";
import { nextTick } from "vue";
import VuelidatedForm from "@/components/module/input/VuelidatedForm.vue";

export default {
  name: "MoneyInput",
  components: { VuelidatedForm },
  props: {
    modelValue: {
      type: [Number, String],
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelPosition: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
    day: {
      type: Boolean,
      required: false,
    },
    hour: {
      type: Boolean,
      required: false,
    },
    percentage: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRule: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    computedValue: {
      get() {
        if (!isNullOrUndefined(this.modelValue)) {
          let parts = this.modelValue.toString().split(".");
          if (parts[0]) {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
          return parts.join(".");
        }

        return "";
      },
      set(value) {
        this.$emit(
          "update:modelValue",
          value
            .toString()
            .replace(/(?<!^[^.]*)\./g, "")
            .replace(/[^\d.]/g, "")
        );
      },
    },
    normalizedValidationRule() {
      return (
        this.validationRule ?? {
          $params: [],
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.money-input {
  .money-label {
    width: 64px;
    background-color: $grey-lighter;
    border-radius: 0 8px 8px 0;
  }

  .day-label {
    background-color: $custom-dark-blue;
  }

  .percentage-label {
    background-color: $custom-grayer-blue;
  }

  input {
    border-radius: 8px 0 0 8px !important;
  }

  .is-money {
    color: $custom-grayer-blue;
  }

  .is-day {
    color: $white;
  }

  .is-percentage {
    color: $custom-dark-blue;
  }
}
</style>
