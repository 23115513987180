<template>
  <div class="container normal-project-header">
    <div class="brief-data">
      <div class="title">
        <div class="client">
          <b-tooltip
            :label="$tf('normalProjectPage.header.client|A projekt ügyfele')"
          >
            <client-info
              :client="client"
              displayMode="brief"
              tooltipPosition="bottom"
              size="24x24"
              :font-size="0.8"
            />
          </b-tooltip>
        </div>
        <div>
          <h1>
            {{
              $tf("normalProjectPage.header.title|{name} részletei", {
                name: projectData.name,
              })
            }}
          </h1>
          <div
            class="mx-auto py-1 px-2 archived-tag"
            v-if="projectDetails.archived"
          >
            {{ $tf("normalProjectPage.header.archived|Archivált projekt") }}
          </div>
        </div>
      </div>
      <div class="status">
        <div>
          <b-tag rounded :type="projectStatusObject.type">
            {{ projectStatusObject.text }}
          </b-tag>
        </div>
        <b-tooltip
          :label="
            $tf(
              'normalProjectPage.header.lead.tooltip|A Projekt felelős menedzsere'
            )
          "
          append-to-body
        >
          <UserInfo
            :user="lead"
            displayMode="brief"
            tooltipPosition="bottom"
            size="32x32"
          />
        </b-tooltip>
      </div>
      <div class="actions">
        <div>
          <b-tooltip
            :label="
              $tf(
                'normalProjectPage.header.star.tooltip|A Projekt csillagozása egy jelölési lehetőség, ami minden felhasználó számára közös.'
              )
            "
            position="is-left"
          >
            <star-icon
              :filled="starred"
              :clickable="canStarProject"
              @change="star"
              ref="star-icon"
              :text="$tf('normalProjectPage.header.star|Kiemelt')"
            ></star-icon>
          </b-tooltip>
        </div>
        <div>
          <b-tooltip
            :label="
              $tf(
                'normalProjectPage.header.heart.tooltip|A Projekt szivecskézése egy jelölési lehetőség, ami felhasználónként egyedi, csak magadnak állítod be.'
              )
            "
            position="is-left"
          >
            <heart-icon
              :filled="liked"
              :clickable="true"
              @change="changeLike"
              ref="heart-icon"
              :text="$tf('normalProjectPage.header.heart|Kedvenc')"
            ></heart-icon>
          </b-tooltip>
        </div>
        <div>
          <export-button
            :clickable="true"
            @openExport="onExportClick"
          ></export-button>
        </div>
        <div>
          <b-button
            type="is-info"
            size="is-small"
            rounded
            @click="openStatusChangeModal"
            v-if="!projectDetails.archived"
          >
            {{ $tf("normalProjectPage.header.statusChange|Státusz váltás") }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="label-boxes">
      <b-tooltip
        :label="
          $tf(
            'normalProjectPage.header.dueDate.tooltip|A Projekt Határideje, törzsadatként módosítható (naplózásra kerül)'
          )
        "
        :class="{ 'is-flex-1': projectData.internal }"
      >
        <LabelBox
          icon="calendar-check"
          :header="$tf('normalProjectPage.header.dueDate|Határidő')"
          :text="projectDetails.dueDate"
        />
      </b-tooltip>

      <b-tooltip
        :label="
          $tf(
            'normalProjectPage.header.readiness.tooltip|Jelenleg érvényes készültségi érték a projekten, a forecast számítás alapja.'
          )
        "
        :class="{ 'is-flex-1': projectData.internal }"
      >
        <LabelBox
          icon="circle-check"
          :header="$tf('normalProjectPage.header.readiness|Készültség')"
          :text="readiness"
        />
      </b-tooltip>

      <b-tooltip
        :label="
          $tf(
            'normalProjectPage.header.budget.tooltip|A Projekt Büdzséje, törzsadatként módosítható (naplózásra kerül)'
          )
        "
        :class="{ 'is-flex-1': projectData.internal }"
      >
        <LabelBox
          icon="coins"
          size="is-large"
          :header="$tf('normalProjectPage.header.budget|Büdzsé')"
          :text="moneyify(projectDetails.budget)"
        />
      </b-tooltip>

      <b-tooltip
        :label="
          $tf(
            'normalProjectPage.header.spent.tooltip|A Projekt logolásai alapján számolt eddig összes költsége'
          )
        "
        :class="{ 'is-flex-1': projectData.internal }"
      >
        <LabelBox
          icon="hand-holding-circle-dollar"
          size="is-large"
          :header="$tf('normalProjectPage.header.spent|Költés')"
          :text="moneyify(projectDetails.sumSpentBudget)"
        />
      </b-tooltip>

      <template v-if="!projectData.internal">
        <b-tooltip
          :label="
            $tf(
              'normalProjectPage.header.income.tooltip|A Projekt szerződés szerinti Bevétele, törzsadatként módosítható (naplózásra kerül)'
            )
          "
        >
          <LabelBox
            icon="hands-holding-dollar"
            size="is-large"
            :header="$tf('normalProjectPage.header.income|Bevétel')"
            :text="moneyify(projectDetails.expectedIncome)"
          />
        </b-tooltip>

        <b-tooltip
          :label="
            $tf(
              'normalProjectPage.header.invoicedIncome.tooltip|A Projekt logolásai alapján számolt eddig összes költsége'
            )
          "
        >
          <LabelBox
            icon="receipt"
            size="is-large"
            :header="$tf('normalProjectPage.header.invoicedIncome|Számlázva')"
            :text="moneyify(projectDetails.invoicedIncome)"
          />
        </b-tooltip>
      </template>
      <template v-else>
        <div class="is-flex-1"></div>
        <div class="is-flex-1"></div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  formatDate,
  getProjectStatusObject,
  moneyify,
  percentify,
} from "@/utils/util";
import LabelBox from "@/components/module/info/LabelBox";
import StatusChanger from "../../ProjectDetails/StatusChanger";
import StarIcon from "@/components/module/icon/StarIcon.vue";
import { PRIVILEGES } from "@/utils/const";
import HeartIcon from "@/components/module/icon/HeartIcon.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";
import ClientInfo from "@/components/module/info/ClientInfo.vue";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import ProjectExportModal from "@/components/enterprise/export/ProjectExportModal.vue";
import ExportButton from "@/components/module/icon/ExportButton.vue";

export default {
  name: "NormalProjectHeader",
  components: {
    ExportButton,
    HeartIcon,
    StarIcon,
    LabelBox,
    UserInfo,
    ClientInfo,
  },
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      starred: this.$props.projectData.starred,
      isExportInProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      clients: "enterprise_clients/clients",
      likedProjects: "project_like/likedProjects",
    }),
    canStarProject: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT.STAR_PROJECT,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN
      );
    },
    liked() {
      return this.likedProjects.includes(this.projectData.id);
    },
    client() {
      if (!this.projectData) return undefined;
      return {
        identifier: this.projectData.clientIdentifier,
        name: this.projectData.clientName,
        avatar: this.projectData.clientAvatar,
        internal: this.projectData.internal,
      };
    },
    lead() {
      if (!this.projectData) return undefined;
      return {
        userId: this.projectData.lead,
        name: this.projectData.leadName,
        avatar: this.projectData.leadAvatar,
      };
    },
    readiness() {
      if (!this.projectDetails?.readiness) return "-";
      return percentify(this.projectDetails.readiness);
    },
    projectStatusObject() {
      return getProjectStatusObject(
        this.projectData.status,
        this.projectDetails.archived
      );
    },
  },
  methods: {
    moneyify,
    async onExportClick() {
      this.isExportInProgress = true;
      if (this.projectType === "BASIC") {
        try {
          let data = await this.$store.dispatch(
            "enterprise_overview/exportOverview",
            {
              projects: [this.projectDetails.identifier],
            }
          );

          FileSaver.saveAs(data, "project_export.xlsx");
        } finally {
          this.isExportInProgress = false;
        }
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ProjectExportModal,
          props: { projectIdentifier: this.projectDetails.identifier },
          events: { close: () => (this.isExportInProgress = false) },
          hasModalCard: true,
          trapFocus: true,
          onCancel: () => (this.isExportInProgress = false),
        });
      }
    },
    async fetchData(force) {
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: this.projectDetails.identifier,
        force,
      });
    },
    emit(event, args) {
      this.$emit(event, args);
    },
    openStatusChangeModal() {
      this.$buefy.modal.open({
        parent: this,
        component: StatusChanger,
        props: {
          projectData: this.projectData,
          projectType: "BASIC",
        },
        events: {
          save: () => this.fetchData(true),
        },
        hasModalCard: true,
        trapFocus: true,
        canCancel: ["escape"],
      });
    },
    async star() {
      const original = this.starred;
      this.starred = !original;
      await this.$store
        .dispatch("enterprise_projects/star", {
          projectType: "BASIC",
          projectIdentifier: this.projectDetails.identifier,
          starred: original,
        })
        .catch(() => {
          this.starred = original;
        });
      this.$forceUpdate();
    },
    async changeLike() {
      const original = this.liked;
      this.liked = !original;
      await this.$store
        .dispatch("project_like/changeLike", {
          projectId: this.projectData.id,
          liked: original,
        })
        .catch(() => {
          this.liked = original;
        });
      await this.$store.dispatch("project_like/clearCache");
      await this.$store.dispatch("project_like/fetchLikedProjects");
      this.$forceUpdate();
    },
  },
  async mounted() {
    await this.$store.dispatch("project_like/fetchLikedProjects");
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.normal-project-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  border-radius: 8px;
  background: $white;

  .brief-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    gap: 12px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      .client {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 4px;
        margin: 0;

        border-radius: 100px;

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 130%;
          display: flex;
          align-items: center;
          color: $blue;
        }
      }

      h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        margin: 0;

        color: $custom-dark-blue;
      }
    }
    .status {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 12px;
    }
    .actions {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      gap: 24px;
    }
  }
  .label-boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}

.archived-tag {
  background: $red;
  border-radius: 8px;
  color: $white;
  text-align: center;
}
</style>
