<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">
          {{ $tf("sites.modal.newTitle|Új helyszín létrehozása") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("sites.modal.editTiel|Helyszín módosítása") }}
        </h1>

        <form @submit.prevent="submit">
          <b-field class="mt-5" :label="$tf('sites.modal.color|Szín')">
            <b-colorpicker v-model="editData.color" close-on-click />
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.identifier"
              :placeholder="$tf('sites.modal.identifier|Azonosító')"
              :label="$tf('sites.modal.identifier|Azonosító')"
              label-position="on-border"
              :validation-rule="v$.editData.identifier"
            />
          </b-field>

          <b-field class="mt-5">
            <b-switch v-model="isConnecting" disabled>
              {{ $tf("sites.modal.connect|Telephelyhez kapcsolás") }}
            </b-switch>
          </b-field>

          <template v-if="isConnecting">
            <b-field
              :label="$tf('sites.modal.base|Telephely')"
              class="mt-5"
              label-position="on-border"
            >
              <b-select
                v-model="editData.site"
                :placeholder="$tf('sites.modal.base|Telephely')"
                expanded
              >
                <option v-for="value in sites" :key="value.id" :value="value">
                  {{ value.identifier }}
                </option>
              </b-select>
            </b-field>
          </template>

          <b-field class="mt-5">
            <b-switch v-model="editData.selectable">
              {{ $tf("sites.modal.selectable|Kiválasztható") }}
            </b-switch>
          </b-field>

          <b-field class="mt-5">
            <b-switch v-model="editData.isDefault">
              {{ $tf("sites.modal.isDefault|Alapértelmezett") }}
            </b-switch>
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.sign"
              :placeholder="$tf('sites.modal.sign|Jel')"
              :label="$tf('sites.modal.sign|Jel')"
              label-position="on-border"
              :validation-rule="v$.editData.sign"
            />
          </b-field>

          <b-field class="mt-5">
            <p class="has-text-red">
              {{ error }}
            </p>
          </b-field>

          <b-field class="mt-1 is-pulled-right">
            <b-button @click="close">
              {{ $tf("sites.modal.cancel|Mégse") }}
            </b-button>
            <b-button native-type="submit" type="is-info" class="ml-3">
              {{ $tf("sites.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { useVuelidate } from "@vuelidate/core";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "WorkScheduleSiteForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    workScheduleSite: {
      type: Object,
      required: false,
      default: null,
    },
    site: {
      type: Object,
      required: false,
      default: null,
    },
    sites: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      identifier: "",
      editData: {
        id: 0,
        identifier: "",
        site: null,
        sign: "",
        selectable: false,
        color: "#E5692C",
      },
      isCreate: true,
      isConnecting: false,
      error: "",
    };
  },
  async mounted() {
    if (this.workScheduleSite) {
      this.isCreate = false;
      this.identifier = this.workScheduleSite.identifier;
      this.editData = JSON.parse(JSON.stringify(this.workScheduleSite));
    } else {
      this.isCreate = true;
    }
    if (this.site) {
      this.editData.site = this.site;
      this.isConnecting = true;
    }
  },
  validations: {
    editData: {
      identifier: {
        required,
        maxLength: maxLength(255),
      },
      sign: {
        required,
        maxLength: maxLength(1),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("work_schedule/getWorkScheduleAdminSites", true);
    },
    submit() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }

      if (!this.isConnecting) {
        this.editData.site = null;
      }

      this.editData.color = this.editData.color.toString();

      if (this.isCreate) {
        this.$store
          .dispatch("work_schedule/createWorkScheduleSite", this.editData)
          .then(() => {
            this.afterSave();
          })
          .catch((error) => {
            this.error = error?.response?.data;
          });
      } else {
        this.$store
          .dispatch("work_schedule/updateWorkScheduleSites", this.editData)
          .then(() => {
            this.afterSave();
          })
          .catch((error) => {
            this.error = error?.response?.data;
          });
      }
    },
  },
};
</script>
