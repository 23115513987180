<template>
  <section>
    <div class="container">
      <loading-component>
        <template #loader>
          <loading-calendar></loading-calendar>
        </template>
        <template v-if="dates.from && dates.to">
          <div
            class="is-flex is-justify-content-space-between has-text-centered table-header is-align-items-center"
          >
            <div class="is-flex has-gap">
              <b-button
                icon-left="chevron-left"
                @click="prevWeek"
                :disabled="disabled"
                type="is-light"
              />
              <b-button
                icon-right="chevron-right"
                @click="nextWeek"
                :disabled="disabled"
                type="is-light"
              />
            </div>
            <div>
              <p class="title">
                {{ formatDate(dates.from) }} - {{ formatDate(dates.to) }}
              </p>
            </div>
            <div>
              <b-button @click="resetActWeek" type="is-light">{{
                $tf("weeklyCalendar.jumpToActualWeek|Ugrás az aktuális hétre")
              }}</b-button>
            </div>
          </div>
          <table class="dashboard-table">
            <tr>
              <td style="width: 24px"></td>
              <td
                v-if="workschedules"
                style="width: 42px; border-left: none !important"
              ></td>
              <DashboardDay
                v-for="(_, index) in 14"
                :key="index"
                :day-of-the-week="index"
                :day-name="$tf(getDayName(index))"
                :from-date="dates.from"
                :to-date="dates.to"
                :special-days="specialDays"
                class="pb-5"
                :ref="'dashboard-day-' + index"
                :add-divider="index === 7"
              />
            </tr>
            <template v-if="absences">
              <tr
                v-for="(act, index) in getFilteredListForAbsence"
                :key="index"
                :class="{ 'has-top-border': absenceAllowTeamName(index) }"
              >
                <td
                  v-if="absenceAllowTeamName(index)"
                  :rowspan="
                    absenceRowCountForTeam(getTeam(act.userId).identifier)
                  "
                  style="
                    padding: 4px 0;
                    vertical-align: middle;
                    position: relative;
                    border-left: none !important;
                  "
                >
                  <b-tooltip
                    class="rotated-team-tag"
                    :label="getTeam(act.userId).name"
                    :style="{
                      backgroundColor: getTeamColor(
                        getTeam(act.userId).identifier
                      ),
                    }"
                  />
                </td>
                <template v-for="(n, index) in 14">
                  <td
                    :colspan="getColspan(index, act.dates)"
                    v-if="allowTd(index, act.dates)"
                    :key="index"
                    :class="[
                      'absenceData',
                      { 'absence-table-divider': index === 7 },
                    ]"
                  >
                    <div
                      v-if="calculateAbsence(index, act.dates)"
                      class="absenceRow"
                    >
                      <div class="absenceAvatar">
                        <user-info
                          display-mode="compact"
                          :user="getUser(act.userId)"
                          size="32x32"
                          append-tooltip-to-body
                        />
                      </div>
                      <div class="userName ml-auto media-content">
                        {{ getEmployeeName(act.userId) }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <template v-else-if="workschedules">
              <tr
                v-for="(act, index) in getFilteredListForWorkSchedules"
                :key="index"
                :class="{ 'has-top-border': workSchedulesAllowTeamName(index) }"
              >
                <td
                  v-if="workSchedulesAllowTeamName(index)"
                  :rowspan="
                    workScheduleRowCountForTeam(getTeam(act.userId).identifier)
                  "
                  style="
                    padding: 4px 0;
                    vertical-align: middle;
                    position: relative;
                    border-right: none !important;
                    border-left: none !important;
                  "
                >
                  <b-tooltip
                    class="rotated-team-tag"
                    :label="getTeam(act.userId).name"
                    :style="{
                      backgroundColor: getTeamColor(
                        getTeam(act.userId).identifier
                      ),
                    }"
                  />
                </td>
                <td
                  class="position-relative p-0"
                  style="
                    vertical-align: middle;
                    width: 42px;
                    border-left: none !important;
                  "
                >
                  <user-info
                    display-mode="compact"
                    :user="getUser(act.userId)"
                    size="32x32"
                    append-tooltip-to-body
                    class="is-flex is-justify-content-center is-align-items-center h-100"
                  />
                </td>
                <td
                  v-for="(n, index) in 14"
                  :key="index + 'wstd'"
                  style="padding: 4px 0; vertical-align: middle"
                  :style="{ paddingLeft: index === 0 ? '4px' : '0' }"
                >
                  <div class="is-flex is-align-items-center has-gap-2">
                    <div
                      class="has-font-weight-500"
                      style="
                        flex-grow: 1;
                        padding: 4px;
                        word-wrap: break-word;
                        font-size: 13px;
                      "
                      :style="
                        wsStyle(
                          index,
                          act[wsObjProps[index % 7].hoursProp],
                          sites.find(
                            (s) =>
                              s.id.toString() ===
                              act[wsObjProps[index % 7].siteProp]
                          )
                        )
                      "
                    >
                      <div>
                        {{
                          wsName(
                            act[wsObjProps[index % 7].hoursProp],
                            sites.find(
                              (s) =>
                                s.id.toString() ===
                                act[wsObjProps[index % 7].siteProp]
                            )
                          )
                        }}
                      </div>
                      <div>
                        <span style="opacity: 0.7">
                          {{ act[wsObjProps[index % 7].hoursProp] }}h
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </template>
      </loading-component>
    </div>
  </section>
</template>

<script>
import {
  addDay,
  formatDate,
  getContrastedColor,
  hslToHex,
  subtractDay,
} from "@/utils/util";
import { mapGetters } from "vuex";
import DashboardDay from "./DashboardDay";
import { DAY_NAME_SHORT_LABEL } from "@/utils/const";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import LoadingCalendar from "@/components/loading/LoadingCalendar.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";
import { nextTick } from "vue";

export default {
  name: "WeeklyCalendar",
  components: {
    UserInfo,
    DashboardDay,
    LoadingComponent,
    LoadingCalendar,
  },
  props: {
    workschedules: null,
    absences: null,
    workplacesToShow: null,
    employeesToShow: null,
    absenceTypesToShow: null,
    teamsToShow: null,

    from: {
      type: Date,
      required: false,
    },
    to: {
      type: Date,
      required: false,
    },
  },
  mounted() {
    if (this.from) {
      this.dates.from = this.from;
    } else {
      this.dates.from = this.actWeekStart;
    }
    if (this.to) {
      this.dates.to = this.to;
    } else {
      this.dates.to = this.actWeekEnds;
    }
  },
  data() {
    return {
      disabled: false,
      isLoading: false,
      DAY_NAME_SHORT_LABEL,
      formatDate,
      avatars: {},
      wsObjProps: [
        { hoursProp: "mondayHours", siteProp: "mondaySite" },
        { hoursProp: "tuesdayHours", siteProp: "tuesdaySite" },
        { hoursProp: "wednesdayHours", siteProp: "wednesdaySite" },
        { hoursProp: "thursdayHours", siteProp: "thursdaySite" },
        { hoursProp: "fridayHours", siteProp: "fridaySite" },
        { hoursProp: "saturdayHours", siteProp: "saturdaySite" },
        { hoursProp: "sundayHours", siteProp: "sundaySite" },
      ],
      dates: {
        from: null,
        to: null,
      },
    };
  },
  watch: {
    absences() {
      for (let i in this.absences) {
        this.getAvatar(this.absences[i].userId);
      }
    },
    workschedules() {
      for (let i in this.workschedules) {
        this.getAvatar(this.workschedules[i].userId);
      }
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      specialDaysGetter: "specialday/specialDaysAll",
      teams: "census_team/teams",
      sites: "work_schedule/workScheduleSites",
    }),
    years() {
      if (!this.dates.from || !this.dates.to) return [];
      return new Set([
        this.dates.from.getFullYear(),
        this.dates.to.getFullYear(),
      ])
        .values()
        .toArray();
    },
    actWeekStart() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return new Date(curr.setDate(first));
    },
    actWeekEnds() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return new Date(curr.setDate(first + 13));
    },
    specialDays() {
      return this.specialDaysGetter(...this.years);
    },
    getFilteredListForAbsence() {
      return this.getFilteredList(
        this.absences
          .filter(() => true)
          .sort((a, b) =>
            this.getTeam(a.userId).identifier >
            this.getTeam(b.userId).identifier
              ? -1
              : this.getTeam(a.userId).identifier <
                this.getTeam(b.userId).identifier
              ? 1
              : 0
          )
      );
    },
    getFilteredListForWorkSchedules() {
      return this.getFilteredList(
        this.workschedules
          .filter((schedule) =>
            this.workplacesToShow.some((place) =>
              [
                schedule.mondaySite,
                schedule.tuesdaySite,
                schedule.wednesdaySite,
                schedule.thursdaySite,
                schedule.fridaySite,
                schedule.saturdaySite,
                schedule.sundaySite,
              ].includes(place.id.toString())
            )
          )
          .sort((a, b) =>
            this.getTeam(a.userId).identifier >
            this.getTeam(b.userId).identifier
              ? -1
              : this.getTeam(a.userId).identifier <
                this.getTeam(b.userId).identifier
              ? 1
              : 0
          )
      );
    },
  },
  methods: {
    async prevWeek() {
      this.dates.from = this.dates.from.subtractDays(7);
      this.dates.to = this.dates.to.subtractDays(7);
      this.updateDays();
    },
    async nextWeek() {
      this.dates.from = this.dates.from.addDays(7);
      this.dates.to = this.dates.to.addDays(7);
      this.updateDays();
    },
    resetActWeek() {
      this.dates.from = this.actWeekStart;
      this.dates.to = this.actWeekEnds;
      this.updateDays();
    },
    updateDays() {
      nextTick(() => {
        this.years.forEach((year) =>
          this.$store.dispatch("specialday/fetchSpecialDaysAll", { year })
        );
        this.$emit("update", {
          from: this.dates.from,
          to: this.dates.to,
        });

        for (let i = 0; i < 14; i++) {
          this.$refs[`dashboard-day-${i}`][0].updateDays();
        }
      });
    },
    calculateAbsence(dayIndex, absenceDates) {
      if (!this.allowIndex(dayIndex, absenceDates)) {
        return false;
      }

      let startDate = addDay(this.dates.from, dayIndex);

      return !!absenceDates.includes(startDate);
    },
    getUser(userId) {
      return this.employees.find((emp) => emp.id === userId);
    },
    getAvatar(user) {
      return this.getUser(user)?.avatar;
    },
    getEmployeeName(user) {
      return this.getUser(user)?.name ?? "";
    },
    getAbsenceLabel(absence) {
      return absence.type.name;
    },
    getDayName(index) {
      return Object.values(DAY_NAME_SHORT_LABEL)[index % 7];
    },
    getColspan(dayNumber, absenceDates) {
      //megadja, hány oszlopnyi hosszúságú lesz a szabadság
      if (!this.allowIndex(dayNumber, absenceDates)) {
        return 1;
      }
      let startDate = addDay(this.dates.from, dayNumber);
      let absenceInRow = 0;

      let found = true;
      while (found) {
        found = false;
        for (let i in absenceDates) {
          let actAbsenceDate = formatDate(new Date(absenceDates[i]));
          if (startDate === actAbsenceDate) {
            absenceInRow++;
            found = true;
          }
        }
        startDate = addDay(startDate, 1);
      }

      return absenceInRow;
    },

    allowIndex(dayIndex, absenceDates) {
      //annak ellenörzése, hogy az adott napon kezdődik e egy szabadság. true ha igen, false ha nem (a vizsgált nap egy már korábban kirajzolt szabadság)
      //ha dayIndex 1, azaz Hétfő, akkor biztosan nem egy megkezdett szabadság folytatása, mert vasárnapra nem lehet szabadságot felvenni.
      if (dayIndex === 0) {
        return true;
      }

      let absenceStartDate = addDay(this.dates.from, dayIndex);
      let yesterday = subtractDay(absenceStartDate, 1);

      for (let i in absenceDates) {
        let actAbsenceDate = formatDate(new Date(absenceDates[i]));
        if (yesterday === actAbsenceDate) {
          return false;
        }
      }
      return true;
    },
    allowTd(dayIndex, absenceDates) {
      //annak ellenörzése, hogy az adott napon kezdődik e egy szabadság. true ha igen, false ha nem (a vizsgált nap egy már korábban kirajzolt szabadság)
      //az oszlopok kirajzolásánál van szerepe.
      if (dayIndex === 0) {
        return true;
      }

      let absenceStartDate = addDay(this.dates.from, dayIndex);
      let yesterday = subtractDay(absenceStartDate, 1);

      let absenceInRow = 0;

      let found = true;
      while (found) {
        found = false;
        for (let i in absenceDates) {
          let actAbsenceDate = formatDate(new Date(absenceDates[i]));
          if (yesterday === actAbsenceDate) {
            absenceInRow++;
            found = true;
          }
        }
        yesterday = subtractDay(yesterday, 1);
      }

      return absenceInRow <= 1;
    },
    wsStyle(n, hours, site) {
      let styles = "";
      if (site && hours > 0) {
        styles = styles.concat(
          `color: ${getContrastedColor(site.color)}; background-color: ${
            site.color
          }; height: 100%`
        );
      } else {
        styles = styles.concat(
          "color: var(--text); background-color: var(--grey-light); opacity: 0.6;"
        );
      }
      if (n === 0) {
        styles = styles.concat("border-radius: 10px 0 0 10px;");
      } else if (n === 7) {
        styles = styles.concat("border-radius: 0 10px 10px 0;");
      }
      return styles;
    },
    wsName(hours, site) {
      return site && hours > 0 ? site.identifier : "-";
    },
    getFilteredList(list) {
      if (!this.employeesToShow) return list;
      return list.filter((value) =>
        value.user
          ? this.employeesToShow.some(
              (employee) => employee.userId === value.user
            )
          : this.employeesToShow.some(
              (employee) => employee.userId === value.userId
            )
      );
    },
    absenceAllowTeamName(index) {
      return (
        index === 0 ||
        this.getTeam(this.getFilteredListForAbsence[index - 1].userId)
          .identifier !==
          this.getTeam(this.getFilteredListForAbsence[index].userId).identifier
      );
    },
    workSchedulesAllowTeamName(index) {
      return (
        index === 0 ||
        this.getTeam(this.getFilteredListForWorkSchedules[index - 1].userId)
          .identifier !==
          this.getTeam(this.getFilteredListForWorkSchedules[index].userId)
            .identifier
      );
    },
    absenceRowCountForTeam(teamId) {
      return this.getFilteredListForAbsence.filter(
        (abs) => this.getTeam(abs.userId).identifier === teamId
      ).length;
    },
    workScheduleRowCountForTeam(teamId) {
      return this.getFilteredListForWorkSchedules.filter(
        (ws) => this.getTeam(ws.userId).identifier === teamId
      ).length;
    },
    getTeam(empId) {
      let team = this.teamsToShow.find((team) =>
        team.employees
          .concat(team.leads)
          .map((emp) => emp.userId)
          .includes(empId)
      );
      if (team) return team;
      else {
        return {
          identifier: "teamless",
          name: "Csapat nélkül",
        };
      }
    },
    getTeamColor(teamId) {
      let index = this.teams.findIndex((team) => team.identifier === teamId);
      const hue = index * 137.508; // use golden angle approximation
      return hslToHex(hue, 50, 75);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

table {
  width: 100%;
  table-layout: fixed;
}
td,
th {
  display: table-cell;
  text-align: center !important;
  vertical-align: middle;
}

.avatar {
  font-weight: bold;
  margin-top: 0;
}

.container {
  text-align: center;
}

.day-title {
  border-radius: 15px;
}

.customTooltip {
  display: block;
  width: 100%;
  border: none;
}

.absenceData {
  height: 2lh;
  padding: 0.2em 0.3em;
}

.absenceRow {
  padding: 3px 0.125rem 0 0.125rem;
  margin: 0;
  border-radius: 10px;
  box-shadow: 3px 3px 6px 1px $grey-light;
  background-color: $primary-lightest;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  max-height: 2lh;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 0.8em;
  line-height: 0.8lh;

  .absenceAvatar {
    flex: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .avatarImage {
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: center;
  }
  .userName {
    font-weight: bold;
    color: $sidebar-text-color;
    word-wrap: break-word;
    flex: 1;
    white-space: normal;
    justify-self: center;
    overflow-wrap: break-word;
  }
}

.b-tooltip {
  margin-left: 0;
}

p.sub {
  color: $white;
  font-weight: lighter;
  text-transform: lowercase;
}
p.sub::first-letter {
  text-transform: capitalize;
}

.image {
  margin-left: 10px;
}
.active-day {
  color: $white !important;
}
.headerToolTip {
  margin: 0;
}

.dashboard-table {
  td {
    border: none;
    padding-bottom: 0;
  }

  tr:first-of-type {
    td:first-of-type {
      border-left: none !important;
    }
  }
}
.has-gap {
  gap: 20px;
}
table td {
  border-left: 1px solid $grey-lighter !important;
  &.absence-table-divider {
    border-left: 3px solid $grey-lighter !important;
  }
}
.table-header {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 2px solid $grey-lighter;
}

tr.has-top-border > td {
  border-top: 1px solid $grey-lighter !important;
}

.rotated-team-tag {
  border-radius: 8px;
  padding: 4px;
  font-size: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  margin: 4px;
}
</style>
