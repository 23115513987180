<template>
  <div>
    <h3 v-if="!edit" class="title">
      {{ $tf("activityFeeForm.base.title|Új havidíj felvétele") }}
    </h3>
    <h3 v-if="edit" class="title">
      {{ $tf("activityFeeForm.base.editing_title|Havidíj módosítása") }}
    </h3>
    <div class="columns mt-5">
      <div class="column">
        <b-field
          :label="$tf('activityFeeForm.base.validFrom|Érvényesség kezdete')"
          label-position="on-border"
        >
          <f-datepicker
            v-model="feeEdit.validFrom"
            :placeholder="
              $tf('activityFeeForm.base.validFrom.placeholder|Válassz dátumot')
            "
            :max-date="feeEdit.validTo"
            icon="calendar-day"
          >
          </f-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('activityFeeForm.base.validTo|Érvényesség vége')"
          label-position="on-border"
        >
          <f-datepicker
            v-model="feeEdit.validTo"
            :placeholder="
              $tf('activityFeeForm.base.validTo.placeholder|Válassz dátumot')
            "
            :min-date="feeEdit.validFrom"
            icon="calendar-day"
          >
          </f-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <money-input
          v-model="feeEdit.monthlyFee"
          :disabled="disableFee"
          :label="$tf('activityFeeForm.base.monthlyFee|Havidíj')"
          label-position="on-border"
        />
      </div>
      <div v-if="activityType === 'LOCKUP'" class="column is-half">
        <money-input
          v-model="feeEdit.monthlyHours"
          :label="$tf('activityFeeForm.base.lockupHours|Lekötendő órák')"
          hour
          label-position="on-border"
        />
      </div>
      <div v-if="activityType === 'FIX'" class="column is-half">
        <money-input
          v-model="feeEdit.budget"
          :label="$tf('activityFeeForm.base.budget|Büdzsé')"
          label-position="on-border"
        />
      </div>
    </div>
    <div v-if="activityType === 'FIX' && !disableFee" class="columns">
      <div class="column">
        <div class="money-subtext">
          {{
            $tf("activityFeeForm.calculatedProfitRate|Kalkulált profit ráta: ")
          }}
          <span>{{ percentify(profitRate) }}</span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button
          :expanded="true"
          type="is-info"
          @click="submitFee"
          :disabled="disableSubmit"
        >
          {{ $tf("activityFeeForm.submit|Mentés") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import {
  correctWithTimezoneOffset,
  deepCopy,
  numberify,
  percentify,
} from "@/utils/util";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "ActivityFeeForm",
  components: { FDatepicker, MoneyInput },
  emits: ["fee-submitted"],
  props: {
    activity: {
      type: Object,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
    feeData: {
      type: Object,
      required: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
    disableFee: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      feeEdit: {},
      profitRate: 0,
    };
  },
  computed: {
    disableSubmit() {
      return !(
        this.feeEdit.validFrom &&
        this.feeEdit.validTo &&
        this.feeEdit.monthlyFee &&
        this.feeEdit.budget
      );
    },
  },
  mounted() {
    if (this.feeData) {
      this.feeEdit = deepCopy(this.feeData);
      this.feeEdit.validFrom = new Date(this.feeEdit.validFrom);
      this.feeEdit.validTo = new Date(this.feeEdit.validTo);
    }
  },
  methods: {
    percentify,
    async submitFee() {
      const request = deepCopy(this.feeEdit);
      request.activityId = this.activity.id;
      request.activityType = this.activityType;
      request.validFrom = correctWithTimezoneOffset(
        new Date(request.validFrom)
      );
      request.validTo = correctWithTimezoneOffset(new Date(request.validTo));
      if (this.feeData) {
        await this.$store.dispatch("support_projects/updateFee", request);
      } else {
        await this.$store.dispatch("support_projects/createFee", request);
      }
      this.$emit("fee-submitted");
    },
  },
  watch: {
    "feeEdit.budget": function (val) {
      val = numberify(val);
      if (this.feeEdit.monthlyFee) {
        this.profitRate = 1 + (val / this.feeEdit.monthlyFee) * -1;
      } else {
        this.profitRate = 0;
      }
    },
    "feeEdit.monthlyFee": function (val) {
      if (!val) {
        this.profitRate = 0;
        return;
      }
      val = numberify(val);
      if (this.feeEdit.budget) {
        this.profitRate = 1 + (this.feeEdit.budget / val) * -1;
      } else {
        this.profitRate = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;

  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}
</style>
