<template>
  <section>
    <b-modal
      v-model="commitmentModalOpen"
      aria-modal
      aria-role="dialog"
      has-modal-card
      v-on:cancel="commitmentEdit = null"
    >
      <div class="card p-4">
        <commitment-modal
          :commitment-details-sum="commitmentDaysSum"
          :edited-commitment="commitmentEdit"
          :employees="employees"
          :project-commitment="projectData.commitment"
          :roles="
            indicators.filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.ROLE)
          "
          :seniorities="
            indicators.filter(
              (i) => i.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
            )
          "
          @add-commitment="(c) => addToCommitmentList(c)"
        />
      </div>
    </b-modal>

    <div class="number-cards">
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{
              $tf(
                "projectResourceView.summary.soFarAllocatedVsSpent|Eddigi allokáció vs Tényleges költés"
              )
            }}
          </div>
          <div class="card-value">
            {{ workdayify(pastAllocations) }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ workdayify(projectDetails.sumSpentMd) }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{
              $tf(
                "projectResourceView.summary.AllocatedVsSpentDifference|Differencia"
              )
            }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            {{ workdayify(pastAllocations - projectDetails.sumSpentMd) }}
          </div>
        </template>
      </information-card>
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{
              $tf(
                "projectResourceView.summary.futureAllocations|Jövőbeli allokáció"
              )
            }}
          </div>
          <div class="card-value">
            {{ workdayify(futureAllocations) }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ moneyify(futureAllocationCost) }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{
              $tf(
                "projectResourceView.summary.remainingCommitment|Vállalásból hátravan"
              )
            }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            {{ workdayify(projectData.commitment - projectDetails.sumSpentMd) }}
          </div>
        </template>
      </information-card>
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{
              $tf(
                "projectResourceView.summary.soFarPlusFuture|Eddigi költés + Jövőbeli allokáció"
              )
            }}
          </div>
          <div class="card-value">
            {{ workdayify(projectDetails.sumSpentMd + futureAllocations) }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ moneyify(projectDetails.sumSpentBudget + futureAllocationCost) }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectResourceView.summary.budget|Büdzsé") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            {{ moneyify(projectData.budget) }}
          </div>
        </template>
      </information-card>
    </div>

    <div>
      <div class="is-flex">
        <div class="is-flex has-gap-5">
          <h3 class="heading">
            {{ $tf("projectResourceView.resource.title|Erőforrás allokáció") }}
          </h3>
          <div class="is-flex is-align-items-center mb-5">
            <b-checkbox v-model="groupByRole" class="mr-0">
              <span class="has-text-weight-light"
                >{{
                  $tf(
                    "projectResourceView.resource.checkbox.groupByRole|Csoportosítás munkakör szerint"
                  )
                }}
              </span>
            </b-checkbox>
          </div>
        </div>

        <div class="ml-4">
          <b-icon
            class="cursor-pointer mr-2"
            icon="chevrons-left"
            @click="prevMaxWeek"
          />
          <b-icon
            class="cursor-pointer mr-2"
            icon="chevron-left"
            @click="prevWeek"
          />
          <b-icon class="cursor-pointer mr-2" icon="undo" @click="resetWeek" />
          <b-icon
            class="cursor-pointer"
            icon="chevron-right"
            @click="nextWeek"
          />
          <b-icon
            class="cursor-pointer"
            icon="chevrons-right"
            @click="nextMaxWeek"
          />
        </div>
        <div class="ml-auto is-flex is-align-items-center has-gap-2">
          <div>
            <p :class="{ 'has-text-primary': !showAllocatedTimeInHours }">
              {{ $tf("projectResourceView.switch.label.workday|Munkanapok") }}
              <b-icon icon="sun"></b-icon>
            </p>
          </div>
          <div>
            <b-switch
              v-model="showAllocatedTimeInHours"
              passive-type="is-info"
              type="is-info"
            />
          </div>
          <div>
            <p :class="{ 'has-text-primary': showAllocatedTimeInHours }">
              <b-icon icon="clock"></b-icon>
              {{ $tf("projectResourceView.switch.label.workhours|Munkaórák") }}
            </p>
          </div>
        </div>
      </div>

      <template v-if="!isPageLoading">
        <template v-if="groupByRole">
          <div v-for="(commitment, index) in commitmentDetails" :key="index">
            <b-collapse :model-value="false" animation="slide">
              <template #trigger="props">
                <div class="card-header is-grey mb-2" role="button">
                  <div class="is-flex is-align-items-center pl-2">
                    <h3 class="heading mb-2 mt-2 ml-2 has-font-weight-600">
                      {{ commitment.roleName }}
                    </h3>
                  </div>
                  <div class="ml-auto mt-2 mb-2 mr-2">
                    <a>
                      <b-icon
                        :icon="props.open ? 'chevron-up' : 'chevron-down'"
                      />
                    </a>
                  </div>
                </div>
              </template>

              <div class="is-flex is-flex-direction-column">
                <!--       Weeks Header -->
                <div class="row is-flex is-flex-direction-row">
                  <div class="cell is-flex-2 is-flex is-align-items-flex-end">
                    <span
                      class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
                    >
                      {{
                        $tf(
                          "projectResourceView.allocationRow.header.name| Név"
                        )
                      }}
                    </span>
                  </div>
                  <div
                    v-for="(weekData, index) of weeks"
                    :key="index"
                    class="cell is-flex-1"
                  >
                    <div class="pt-1 pb-1">
                      <p
                        class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                      >
                        {{ weekData.week }}.
                        {{
                          $tf(
                            "projectResourceView.allocationRow.header.week| hét"
                          )
                        }}
                      </p>
                      <p class="has-text-grey has-text-centered">
                        {{ weekData.startDate }} - {{ weekData.endDate }}
                      </p>
                    </div>
                  </div>
                </div>

                <!--          Employees-->
                <ResourceAllocationRow
                  v-for="association of filterByRole(
                    associationsWithCosts,
                    commitment.roleId
                  )"
                  :key="association.employeeId"
                  :allocations="association.weekData"
                  :employee="employee(association.employeeId)"
                  :project-id="projectData.id"
                  :show-allocated-time-in-hours="showAllocatedTimeInHours"
                  :weeks="weeks"
                  :cost-summary="association.costSummary"
                  :disabled="!!copying && copying !== association.employeeId"
                  @cloneAllocation="saveAssociation"
                  @modifyAllocation="saveAssociationDebounce"
                  @copyStarted="() => (copying = association.employeeId)"
                  @copyEnded="() => (copying = undefined)"
                />
              </div>
            </b-collapse>
          </div>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-column">
            <!--       Weeks Header -->
            <div class="row is-flex is-flex-direction-row">
              <div class="cell is-flex-3 is-flex is-align-items-flex-end">
                <span
                  class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
                >
                  {{
                    $tf("projectResourceView.allocationRow.header.name| Név")
                  }}
                </span>
              </div>
              <div
                v-for="(weekData, index) of weeks"
                :key="index"
                class="cell is-flex-2"
              >
                <div class="pt-1 pb-1">
                  <p
                    class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                  >
                    {{ weekData.week }}.
                    {{
                      $tf("projectResourceView.allocationRow.header.week| hét")
                    }}
                  </p>
                  <p class="has-text-grey has-text-centered">
                    {{ weekData.startDate }} - {{ weekData.endDate }}
                  </p>
                </div>
              </div>
            </div>

            <!--          Employees-->
            <ResourceAllocationRow
              v-for="association of associationsWithCosts"
              :key="association.employeeId"
              :allocations="association.weekData"
              :employee="employee(association.employeeId)"
              :project-id="projectData.id"
              :show-allocated-time-in-hours="showAllocatedTimeInHours"
              :weeks="weeks"
              :cost-summary="association.costSummary"
              :disabled="!!copying && copying !== association.employeeId"
              @cloneAllocation="cloneAllocation"
              @modifyAllocation="saveAssociationDebounce"
              @copyStarted="() => (copying = association.employeeId)"
              @copyEnded="() => (copying = undefined)"
            />
          </div>
        </template>
        <div class="mt-4">
          <b-field grouped>
            <b-button
              icon-left="plus"
              type="is-primary"
              @click="allocationModalOpen = true"
            >
              {{
                $tf(
                  "projectResourceView.button.newEmployee|Új projekttag hozzáadása"
                )
              }}
            </b-button>
            <ImportExcel
              :tf-label="'projectResourceView.import|Importálás...'"
            />
          </b-field>
        </div>
      </template>
      <b-loading :modelValue="!loaded" :is-full-page="false" />

      <b-modal v-model="allocationModalOpen" has-modal-card>
        <EmployeeSelect
          :employees="employeesToSelect"
          :lead-teams="leadTeams"
          @addEmployee="handleAddEmployee"
        />
      </b-modal>
    </div>

    <b-collapse
      v-model="breakdownOpen"
      animation="slide"
      class="card mt-5 mb-5"
    >
      <template #trigger="">
        <div :aria-expanded="breakdownOpen" class="card-header" role="button">
          <p class="card-header-title m-0">
            {{ $tf("projectForm.commitment.dropdown|Vállalás lebontása") }}
          </p>
        </div>
      </template>
      <div class="card-content">
        <div class="mb-4 is-flex is-justify-content-end">
          <b-button
            class="my-auto"
            icon-right="plus-circle"
            type="is-info"
            @click="commitmentModalOpen = true"
            >{{ $tf("projectForm.commitment.droprown|Lebontás beállítása") }}
          </b-button>
        </div>
        <div v-if="commitmentList?.length > 0">
          <b-table :data="commitmentList">
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.commitment.table.role|Szerepkör')"
              field="competence"
            >
              <indicator-tag :indicator-id="props.row.roleId" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="competence"
              label="Senioritás"
            >
              <indicator-tag :indicator-id="props.row.seniorityId" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="workDays"
              label="Vállalt napok"
            >
              {{ workdayify(props.row.workDays) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('projectpage.override.table.actions|Műveletek')"
              centered
              field="actions"
              width="80"
            >
              <clickable-icon
                :tooltip="
                  $tf('projectpage.override.table.actions.edit|Szerkesztés')
                "
                icon="edit"
                type="is-info"
                @click="editCommitment(props.row)"
              />
              <clickable-icon
                :tooltip="
                  $tf('projectpage.tigList.table.actions.delete|Törlés')
                "
                icon="trash"
                type="is-danger"
                @click="deleteCommitment(props.row)"
              />
            </b-table-column>
          </b-table>
          <div class="mt-4">
            <b-button
              class="is-block ml-auto"
              icon-right="save"
              type="is-info"
              @click="updateCommitment"
              >Lebontás mentése
            </b-button>
          </div>
        </div>
        <div v-else class="mb-6">
          <div class="content has-text-grey has-text-centered">
            <p class="mb-0">
              <b-icon icon="frown"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "projectForm.commitment.table.empty|A projekten még nincs vállalás."
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";
import {
  addDay,
  convertHoursToMWD,
  formatDate,
  generateNextNWeeks,
  getWeekNumber,
  getWeeksBetween,
  isOlderThanPreviousWeek,
  moneyify,
  subtractDay,
  workdayify,
} from "@/utils/util";
import { useDebounceFn } from "@vueuse/core";
import EmployeeSelect from "@/components/ralloc/EmployeeSelect.vue";
import ResourceAllocationRow from "@/components/ralloc/ResourceAllocationRow.vue";
import {
  PROJECT_TYPES,
  RALLOC_PRIORITY,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import CommitmentModal from "@/components/enterprise/project/form/CommitmentModal.vue";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import ImportExcel from "@/components/ImportExcel.vue";
import InformationCard from "@/components/InformationCard.vue";

export default defineComponent({
  name: "ProjectResourceView",
  components: {
    InformationCard,
    ImportExcel,
    IndicatorTag,
    CommitmentModal,
    ClickableIcon,
    ResourceAllocationRow,
    EmployeeSelect,
  },
  mixins: [LoadingMixin],
  async mounted() {
    this.doStartLoading();
    this.generateWeeks();
    await this.$store.dispatch(
      "commitment/getCommitments",
      this.projectData.id
    );
    await this.setupCommitmentDetails();
    //await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("census_team/fetchLeadTeams");
    await this.fetchAssociations();
    this.commitmentList = this.commitments?.commitments ?? [];
    this.doFinishLoading();
    this.loaded = true;
  },
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.saveAssociationDebounced = useDebounceFn(
      async (...args) => await this.saveAssociation(...args),
      500
    );
  },
  data() {
    return {
      USER_INDICATOR_TYPE_ENUM,
      loaded: false,
      startWeekDate: new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() - 1)
        )
      ),
      showAllocatedTimeInHours: false,
      weeks: [],
      commitmentDetails: [],
      allocationModalOpen: false,
      groupByRole: false,
      commitmentModalOpen: false,
      commitmentEdit: null,
      commitmentList: [],
      breakdownOpen: false,
      numberOfWeeks: 10,
      copying: undefined,
    };
  },
  computed: {
    commitmentDaysSum() {
      return (
        this.commitmentList
          ?.map((c) => c.workDays)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0) ?? 0
      );
    },
    ...mapGetters({
      projectDataGetter: "enterprise_projects/projectData",
      projectDetailsGetter: "enterprise_core/projectDetails",
      supportProjectDataGetter: "support_projects/projectDetails",
      outsourceProjectDataGetter: "outsource_projects/projectDetails",
      indicators: "user_indicator/indicators",
      commitments: "commitment/commitments",
      employees: "employee/loggingEmployees",
      leadTeams: "census_team/leadTeams",
      associations: "association/associations",
    }),
    futureAllocations() {
      if (!this.associations?.length) return 0;
      return convertHoursToMWD(
        this.associations
          .map((asc) => asc.costSummary)
          .reduce(
            (acc, costSummary) => acc + costSummary?.futureAllocations ?? 0,
            0
          )
      );
    },
    futureAllocationCost() {
      if (!this.associations?.length) return 0;
      return this.associations
        .map((asc) => asc.costSummary)
        .reduce(
          (acc, costSummary) => acc + costSummary?.futureAllocationCost ?? 0,
          0
        );
    },
    pastAllocations() {
      if (!this.associations?.length) return 0;
      return convertHoursToMWD(
        this.associations
          .map((asc) => asc.costSummary)
          .reduce(
            (acc, costSummary) => acc + costSummary?.pastAllocations ?? 0,
            0
          )
      );
    },
    pastAllocationCost() {
      if (!this.associations?.length) return 0;
      return this.associations
        .map((asc) => asc.costSummary)
        .reduce(
          (acc, costSummary) => acc + costSummary?.pastAllocationCost ?? 0,
          0
        );
    },
    associationsWithCosts() {
      return this.associations.map((asc) => ({
        ...asc,
        costSummary: {
          ...asc.costSummary,
          pastCost: this.costSummary[asc.employeeId]?.moneyspent,
          pastTime: this.costSummary[asc.employeeId]?.timespent,
        },
      }));
    },
    employeesToSelect() {
      return this.employees.filter(
        (emp) => !this.associations.some((asc) => asc.employeeId === emp.id)
      );
    },
    projectData() {
      if (this.projectType === PROJECT_TYPES.BASIC) {
        const data = this.projectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.SUPPORT) {
        const data = this.supportProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.OUTSOURCE) {
        const data = this.outsourceProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      }

      return {};
    },
    projectDetails() {
      if (this.projectType === PROJECT_TYPES.BASIC) {
        const data = this.projectDetailsGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.SUPPORT) {
        const data = this.supportProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.OUTSOURCE) {
        const data = this.outsourceProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      }

      return {};
    },
    costSummary() {
      return (
        this.projectDetails.employeeWorkDetails?.reduce((acc, obj) => {
          acc[obj.id] = {
            moneyspent: obj.moneyspent,
            timespent: obj.timespent,
          };
          return acc;
        }, {}) || {}
      );
    },
    totalCommitmentSum() {
      return this.commitmentDetails.reduce(
        (sum, role) => sum + role.commitmentSum,
        0
      );
    },
    totalAllocatedMwd() {
      return this.convertHoursToMWD(
        this.associations
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
  },
  watch: {
    startWeekDate() {
      this.generateWeeks();
    },
  },
  methods: {
    workdayify,
    moneyify,
    getWeekNumber,
    convertHoursToMWD,
    addDay,
    subtractDay,
    isOlderThanPreviousWeek,
    async saveAssociationDebounce(request) {
      await this.saveAssociationDebounced(request);
    },
    async cloneAllocation(args) {
      if (
        args.cloningWeek.year > args.year ||
        (args.cloningWeek.year === args.year &&
          args.cloningWeek.week > args.week)
      ) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$tf(
            "projectResourceView.saveAssociation.toast.pastAllocationCloneForbidden|Allokáció másolása a múltba nem lehetséges!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      const weeks = getWeeksBetween(args.cloningWeek, args);
      let requestArray = [];
      for (let weekData of weeks) {
        const request = {
          ...args,
          projectId: this.projectData.id,
          type: this.projectType,
          year: weekData.year,
          week: weekData.week,
          hours: args.cloningWeek.allocatedHours,
          priority: args.cloningWeek.priority,
        };
        requestArray.push(request);
      }
      await this.$store.dispatch("association/createAssociation", requestArray);
      await this.fetchAssociations();
    },
    async saveAssociation(args) {
      const request = {
        ...args,
        projectId: this.projectData.id,
        type: this.projectType,
      };
      if (this.isOlderThanPreviousWeek(request.year, request.week)) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$tf(
            "projectResourceView.saveAssociation.toast.pastAllocationForbidden|Egy hétnél korábbi allokáció nem megengedett!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }

      await this.$store.dispatch("association/createAssociation", [request]);
      await this.fetchAssociations();
    },
    async fetchAssociations(force = false) {
      this.loaded = false;
      await this.$store.dispatch("association/getAssociationsForProject", {
        projectId: this.projectData?.id,
        projectType: this.projectType,
        from: formatDate(this.startWeekDate),
        to: formatDate(this.addDay(this.startWeekDate, 7 * 10)),
      });
      this.loaded = true;
    },
    async setupCommitmentDetails() {
      if (!this.commitments || this.commitments.length === 0) {
        this.commitmentDetails = [];
        return;
      }

      const roleMap = new Map();

      this.commitments?.commitments.forEach((commitment) => {
        const getIndicatorById = (id) =>
          this.indicators.find((indicator) => indicator.id === id);

        const roleId = commitment.roleId;
        const roleName = getIndicatorById(roleId)?.name;

        if (!roleMap.has(roleId)) {
          roleMap.set(roleId, {
            roleName,
            roleId,
            commitmentSum: 0,
            commitments: [],
          });
        }

        const role = roleMap.get(roleId);

        role.commitments.push({
          seniorityId: commitment.seniorityId,
          workDays: commitment.workDays,
          seniority: getIndicatorById(commitment.seniorityId),
        });

        role.commitmentSum += commitment.workDays;
      });

      this.commitmentDetails = Array.from(roleMap.values());
    },
    allocatedMWDByRole(roleId) {
      const employeeRoles = new Map(
        this.employees.map((emp) => [emp.id, emp.roleId])
      );

      return this.convertHoursToMWD(
        this.associations
          .filter((asc) => employeeRoles.get(asc.employeeId) === roleId)
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
    async handleAddEmployee(employeeId) {
      const today = new Date();
      await this.saveAssociation({
        employeeId: employeeId,
        year: today.getFullYear(),
        week: getWeekNumber(today),
        hours: 8,
        priority: RALLOC_PRIORITY.MEDIUM.value,
      });

      this.allocationModalOpen = false;
    },
    nextWeek() {
      this.startWeekDate = new Date(this.addDay(this.startWeekDate, 7));
      this.fetchAssociations();
    },
    nextMaxWeek() {
      this.startWeekDate = new Date(
        this.addDay(this.startWeekDate, 7 * this.numberOfWeeks)
      );
      this.fetchAssociations();
    },
    prevWeek() {
      this.startWeekDate = new Date(this.subtractDay(this.startWeekDate, 7));
      this.fetchAssociations();
    },
    prevMaxWeek() {
      this.startWeekDate = new Date(
        this.subtractDay(this.startWeekDate, 7 * this.numberOfWeeks)
      );
      this.fetchAssociations();
    },
    resetWeek() {
      this.startWeekDate = new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() - 1)
        )
      );
      this.fetchAssociations();
    },
    generateWeeks() {
      this.weeks = generateNextNWeeks(this.startWeekDate, this.numberOfWeeks);
    },
    employee(employeeId) {
      return this.employees.find((emp) => emp.id === employeeId);
    },
    filterByRole(associations, roleId) {
      const employeeMap = this.employees.reduce((map, emp) => {
        map[emp.id] = emp;
        return map;
      }, {});

      return associations.filter((asc) => {
        const employee = employeeMap[asc.employeeId];
        return employee && employee.roleId === roleId;
      });
    },
    addToCommitmentList(c) {
      let found = false;
      this.commitmentList = this.commitmentList.map((com) => {
        if (com.roleId === c.role.id && com.seniorityId === c.seniority?.id) {
          com.workDays = c.workDays;
          found = true;
        }
        return com;
      });
      if (!found) {
        this.commitmentList.push({
          roleId: c.role.id,
          seniorityId: c.seniority?.id,
          workDays: c.workDays,
        });
      }
      this.commitmentModalOpen = false;
    },
    editCommitment(c) {
      this.$buefy.dialog.prompt({
        message: this.$tf(
          "projectResourceView.commitment.editModal.message|Vállalt napok:"
        ),
        inputAttrs: {
          type: "number",
          value: c.workDays,
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.commitmentList = this.commitmentList.map((com) => {
            if (com.roleId === c.roleId && com.seniorityId === c.seniorityId) {
              com.workDays = value;
            }
            return com;
          });
        },
      });
    },
    deleteCommitment(c) {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projectResourceView.commitment.deleteModal.message|Biztos vagy benne, hogy törölni szeretnéd a vállalást?"
        ),
        confirmText: this.$tf(
          "projectResourceView.commitment.deleteModal.confirm|Törlés"
        ),
        cancelText: this.$tf(
          "projectResourceView.commitment.deleteModal.cancel|Mégse"
        ),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.commitmentList = this.commitmentList.filter((com) => com !== c);
        },
      });
    },
    async updateCommitment() {
      await this.$store.dispatch("commitment/updateCommitment", {
        projectId: this.projectData.id,
        commitments: this.commitmentList.map((c) => {
          return {
            roleId: c.roleId,
            seniorityId: c.seniorityId,
            workDays: c.workDays,
          };
        }),
      });
      await this.$store.dispatch(
        "commitment/getCommitments",
        this.projectData.id
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors";

.commitment-box {
  border: 1px solid black;
  border-radius: 5px;
}

.commitment-progress {
  height: 0.6rem !important;
  background-color: #eee;

  &::-webkit-progress-bar {
    background-color: #eee;
  }

  &::-webkit-progress-value {
    background-color: var(--custom-dark-blue);
  }

  &::-moz-progress-bar {
    background-color: var(--custom-dark-blue);
  }
}

.is-grey {
  background: #e8e8e8;
}
</style>
