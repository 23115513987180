<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <div>
          <h1 class="title">
            {{ $tf("adminSettings.title|Admin beállítások") }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "adminSettings.subtitle|Admin beállítások megtekintése és módosítása"
              )
            }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        class="is-flex is-align-items-center is-justify-content-space-between mb-3"
      >
        <div>
          <b-tabs v-model="activeTab" type="is-toggle" class="toggle-only">
            <b-tab-item
              value="ENTERPRISE"
              :label="$tf('adminSetting.tabs.enterprise.label|Enterprise')"
            />
            <b-tab-item
              value="ISSUE_TRACKING"
              :label="$tf('adminSetting.tabs.issueTracking.label|Taskkezelés')"
            />
          </b-tabs>
        </div>
        <div>
          <b-button
            class="is-primary"
            v-if="!editMode && enterpriseData && issueTrackingData"
            @click="editMode = true"
            >{{ $tf("adminSettings.edit|Szerkesztés") }}
          </b-button>
        </div>
      </div>

      <div v-show="activeTab === 'ENTERPRISE'">
        <template v-if="editMode">
          <div
            class="is-flex is-flex-direction-column has-gap-4 mt-5"
            style="max-width: 500px"
          >
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                v-model="enterpriseDataEdit.lowerRiskKPILimit"
                :validation-rule="v$.enterpriseDataEdit.lowerRiskKPILimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                v-model="enterpriseDataEdit.upperRiskKPILimit"
                :validation-rule="v$.enterpriseDataEdit.upperRiskKPILimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                type="dropdown"
                :label="$tf('adminSettings.currency|Deviza')"
                :placeholder="$tf('adminSettings.currency|Deviza')"
                :validation-rule="v$.enterpriseDataEdit.currency"
                label-position="on-border"
              >
                <b-dropdown
                  v-model="enterpriseDataEdit.currency"
                  scrollable
                  :max-height="200"
                  aria-role="list"
                  append-to-body
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <img
                        :src="selectedCurrency.icon"
                        :alt="selectedCurrency.code"
                      />
                      <div class="is-flex-grow-1">
                        {{ selectedCurrency.code }}
                        &nbsp;-&nbsp;{{ selectedCurrency.name }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="curr in currencies"
                    :key="curr.id"
                    :value="curr.id"
                    style="z-index: 999"
                  >
                    <div class="media">
                      <img
                        class="media-left is-align-self-center"
                        :alt="curr.code"
                        :src="curr.icon"
                      />
                      <div
                        class="media-content has-text-dark-blue has-font-weight-500"
                      >
                        {{ curr.code }}
                        - {{ curr.name }}
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData?.lowerRiskKPILimit || "-"
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData?.upperRiskKPILimit || "-"
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{ $tf("adminSettings.currency|Deviza") }}:
              </span>
              <span class="data-details-value">{{
                selectedCurrency?.name || "-"
              }}</span>
            </div>
          </div>
        </template>
      </div>
      <div v-show="activeTab === 'ISSUE_TRACKING'">
        <template v-if="editMode">
          <div class="is-flex is-flex-direction-column has-gap-4 mt-5">
            <div>
              <vuelidated-input
                type="dropdown"
                :label="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :validation-rule="v$.issueTrackingDataEdit.system"
                label-position="on-border"
              >
                <b-dropdown
                  v-model="issueTrackingDataEdit.system"
                  scrollable
                  :max-height="200"
                  aria-role="list"
                  append-to-body
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <div class="is-flex-grow-1">
                        {{
                          $tf(
                            ISSUE_TRACKING_SYSTEM[issueTrackingDataEdit.system]
                              ?.label ?? ""
                          )
                        }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="its in Object.keys(ISSUE_TRACKING_SYSTEM)"
                    :key="ISSUE_TRACKING_SYSTEM[its].enum"
                    :value="ISSUE_TRACKING_SYSTEM[its].enum"
                    style="z-index: 999"
                  >
                    {{ $tf(ISSUE_TRACKING_SYSTEM[its].label) }}
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>

            <template
              v-if="
                issueTrackingDataEdit.system !== ISSUE_TRACKING_SYSTEM.SELF.enum
              "
            >
              <template
                v-if="
                  issueTrackingDataEdit.system ===
                  ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
                "
              >
                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      :label="
                        $tf(
                          'adminSettings.estimationFieldName.label|Feladat becsült időtartam mező'
                        )
                      "
                      :placeholder="
                        $tf(
                          'adminSettings.estimationFieldName.placeholder|Estimation'
                        )
                      "
                      v-model="issueTrackingDataEdit.estimationFieldName"
                      :validation-rule="
                        v$.issueTrackingDataEdit.estimationFieldName
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      :label="
                        $tf('adminSettings.stateFieldName.label|Státusz mező')
                      "
                      :placeholder="
                        $tf('adminSettings.stateFieldName.placeholder|State')
                      "
                      v-model="issueTrackingDataEdit.stateFieldName"
                      :validation-rule="v$.issueTrackingDataEdit.stateFieldName"
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div>
                  <span class="data-details-label"
                    ><vuelidated-input
                      :label="
                        $tf(
                          'adminSettings.todoIssueStateNames.label|Todo task státusz értékek'
                        )
                      "
                      v-model="issueTrackingDataEdit.todoIssueStateNames"
                      :validation-rule="
                        v$.issueTrackingDataEdit.todoIssueStateNames
                      "
                      label-position="on-border"
                      type="taginput"
                      allow-new
                    />
                  </span>
                </div>

                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      :label="
                        $tf(
                          'adminSettings.typeFieldName.label|Feladattípus mező'
                        )
                      "
                      :placeholder="
                        $tf('adminSettings.typeFieldName.placeholder|Type')
                      "
                      v-model="issueTrackingDataEdit.typeFieldName"
                      :validation-rule="v$.issueTrackingDataEdit.typeFieldName"
                      label-position="on-border"
                    />
                  </span>
                </div>
              </template>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.BUG.label|BUG feladattípus értékek'
                      )
                    "
                    v-model="issueTrackingDataEdit.issueTypeMap.BUG"
                    :validation-rule="v$.issueTrackingDataEdit.issueTypeMap.BUG"
                    label-position="on-border"
                    type="taginput"
                    allow-new
                  />
                </span>
              </div>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.DEV.label|DEV feladattípus értékek'
                      )
                    "
                    v-model="issueTrackingDataEdit.issueTypeMap.DEV"
                    :validation-rule="v$.issueTrackingDataEdit.issueTypeMap.DEV"
                    label-position="on-border"
                    type="taginput"
                    allow-new
                  />
                </span>
              </div>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.GENERAL.label|GENERAL feladattípus értékek'
                      )
                    "
                    v-model="issueTrackingDataEdit.issueTypeMap.GENERAL"
                    :validation-rule="
                      v$.issueTrackingDataEdit.issueTypeMap.GENERAL
                    "
                    label-position="on-border"
                    type="taginput"
                    allow-new
                  />
                </span>
                <h2
                  class="subtitle"
                  v-html="
                    $tf(
                      'adminSettings.issueTypeMap.OTHER|A fel nem sorolt értékek az <i>OTHER</i> kategóriába kerülnek'
                    )
                  "
                ></h2>
              </div>
              <div>
                <span class="data-details-label">
                  <b-field
                    :label="
                      $tf(
                        'adminSettings.reportingFieldNames.label|Riporthoz lekért extra mezők'
                      )
                    "
                    label-position="on-border"
                  >
                    <b-taginput
                      v-model="issueTrackingDataEdit.reportingFieldNames"
                      allow-new
                    />
                  </b-field>
                </span>
              </div>

              <div>
                <span class="data-details-label">
                  <vuelidated-input
                    :label="
                      $tf('adminSettings.url.label|Taskkezelő rendszer elérése')
                    "
                    :placeholder="
                      $tf(
                        'adminSettings.url.placeholder|https://example.youtrack.cloud'
                      )
                    "
                    v-model="issueTrackingDataEdit.url"
                    :validation-rule="v$.issueTrackingDataEdit.url"
                    label-position="on-border"
                  />
                </span>
              </div>
              <div class="is-flex is-align-items-center has-gap-1">
                <div class="is-flex-1">
                  <span class="data-details-label">
                    <vuelidated-input
                      :label="
                        $tf(
                          'adminSettings.email.label|Taskkezelő rendszer eléréséhez használt email'
                        )
                      "
                      :placeholder="
                        issueTrackingData.email
                          ? $tf('adminSettings.email.placeholder|Beállítva')
                          : ''
                      "
                      v-model="issueTrackingDataEdit.email"
                      :validation-rule="v$.issueTrackingDataEdit.email"
                      label-position="on-border"
                      :disabled="!editEmail"
                    />
                  </span>
                </div>
                <div v-if="issueTrackingData.email">
                  <clickable-icon
                    :icon="editEmail ? 'xmark' : 'pencil'"
                    type="is-info"
                    size="is-large"
                    @click="editEmail = !editEmail"
                  />
                </div>
              </div>
              <div class="is-flex is-align-items-center has-gap-1">
                <div class="is-flex-1">
                  <span class="data-details-label">
                    <vuelidated-input
                      :label="
                        $tf(
                          'adminSettings.apiKey.label|Taskkezelő rendszer eléréséjhez használ API kulcs'
                        )
                      "
                      :placeholder="
                        issueTrackingData.apiKey
                          ? $tf('adminSettings.apiKey.placeholder|Beállítva')
                          : ''
                      "
                      v-model="issueTrackingDataEdit.apiKey"
                      :validation-rule="v$.issueTrackingDataEdit.apiKey"
                      label-position="on-border"
                      :disabled="!editApiKey"
                    />
                  </span>
                </div>
                <div v-if="issueTrackingData.apiKey">
                  <clickable-icon
                    :icon="editApiKey ? 'xmark' : 'pencil'"
                    type="is-info"
                    size="is-large"
                    @click="editApiKey = !editApiKey"
                  />
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueTrackingSystem|Külső taskkezelő rendszer"
                  )
                }}:
              </span>
              <span v-if="issueTrackingData" class="data-details-value">{{
                $tf(
                  ISSUE_TRACKING_SYSTEM[issueTrackingData.system]?.label ?? ""
                )
              }}</span>
            </div>
            <template
              v-if="
                issueTrackingData?.system !== ISSUE_TRACKING_SYSTEM.SELF.enum
              "
            >
              <template
                v-if="
                  issueTrackingData?.system ===
                  ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
                "
              >
                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.estimationFieldName.label|Feladat becsült időtartam mező"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    issueTrackingData?.estimationFieldName || "-"
                  }}</span>
                </div>
                <div>
                  <span class="data-details-label"
                    >{{
                      $tf("adminSettings.stateFieldName.label|Státusz mező")
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    issueTrackingData?.stateFieldName || "-"
                  }}</span>
                </div>

                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.todoIssueStateNames|Todo task státusz értékek"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    issueTrackingData?.todoIssueStateNames?.join(", ")
                  }}</span>
                </div>

                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.typeFieldName.label|Feladattípus mező"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    issueTrackingData?.typeFieldName || "-"
                  }}</span>
                </div>
              </template>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.BUG|BUG feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  issueTrackingData?.issueTypeMap?.BUG?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.DEV|DEV feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  issueTrackingData?.issueTypeMap?.DEV?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.GENERAL|GENERAL feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  issueTrackingData?.issueTypeMap?.GENERAL?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{ $tf("adminSettings.reportingFieldNames|Riport mezők") }}:
                </span>
                <span class="data-details-value">{{
                  issueTrackingData?.reportingFieldNames?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf("adminSettings.url.label|Taskkezelő rendszer elérése")
                  }}:
                </span>
                <span class="data-details-value">{{
                  issueTrackingData?.url || "-"
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.email.label|Taskkezelő rendszer eléréséhez használt email"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  !!issueTrackingData?.email
                    ? $tf("adminSettings.email.set|Beállítva")
                    : $tf("adminSettings.email.missing|Nincs beállítva")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.apiKey.label|Taskkezelő rendszer eléréséhez használt API kulcs"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  !!issueTrackingData?.apiKey
                    ? $tf("adminSettings.apiKey.set|Beállítva")
                    : $tf("adminSettings.apiKey.missing|Nincs beállítva")
                }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>

      <b-loading :can-cancel="false" :is-full-page="false" v-model="loading" />
      <div class="overflow-x-auto">
        <div
          class="is-flex is-justify-content-space-between is-align-items-end pb-2 mt-4"
        >
          <div class="ml-auto">
            <div>
              <div v-if="editMode" class="is-flex has-gap-2">
                <b-button
                  class="is-light"
                  v-if="enterpriseData && issueTrackingData"
                  @click="editMode = false"
                  >{{ $tf("adminSettings.cancel|Mégse") }}
                </b-button>
                <b-button class="is-primary ml-auto" @click="save"
                  >{{ $tf("adminSettings.save|Mentés") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { decimal, minValue, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "../../components/module/input/VuelidatedInput";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { deepCopy } from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import useCustomVuelidate from "@/plugins/vuelidate";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";

export default {
  name: "AdminConfigEdit",
  components: { ClickableIcon, VuelidatedInput },
  setup: () => useCustomVuelidate(),
  data() {
    return {
      loading: false,
      editMode: false,
      enterpriseData: null,
      issueTrackingData: null,
      enterpriseDataEdit: {
        lowerRiskKPILimit: 0.8,
        upperRiskKPILimit: 0.99,
        currency: undefined,
      },
      issueTrackingDataEdit: {
        system: ISSUE_TRACKING_SYSTEM.JIRA.enum,
        url: undefined,
        email: undefined,
        apiKey: undefined,
        estimationFieldName: "Estimation",
        stateFieldName: "State",
        todoIssueStateNames: [],
        typeFieldName: "Type",
        issueTypeMap: {
          BUG: [],
          DEV: [],
          GENERAL: [],
        },
        reportingFieldNames: [],
      },
      editEmail: false,
      editApiKey: false,
      ISSUE_TRACKING_SYSTEM,
      activeTab: "ENTERPRISE",
    };
  },
  validations: {
    enterpriseDataEdit: {
      lowerRiskKPILimit: {
        required,
        decimal,
        minValue: minValue(0),
      },
      upperRiskKPILimit: {
        required,
        decimal,
      },
      currency: {
        required,
      },
    },
    issueTrackingDataEdit: {
      system: {
        required,
      },
      url: {
        required,
      },
      estimationFieldName: {
        required: requiredIf(function (modelValue) {
          return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
        }),
      },
      stateFieldName: {
        required: requiredIf(function (modelValue) {
          return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
        }),
      },
      todoIssueStateNames: {
        required: requiredIf(function (modelValue) {
          return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
        }),
      },
      typeFieldName: {
        required: requiredIf(function (modelValue) {
          return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
        }),
      },
      issueTypeMap: {
        BUG: {
          required: requiredIf(function (modelValue) {
            return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
          }),
        },
        DEV: {
          required: requiredIf(function (modelValue) {
            return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
          }),
        },
        GENERAL: {
          required: requiredIf(function (modelValue) {
            return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
          }),
        },
      },
      email: {
        required: requiredIf(function () {
          return !!this.editEmail;
        }),
      },
      apiKey: {
        required: requiredIf(function () {
          return !!this.editApiKey;
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      clients: "enterprise_clients/clients",
      currencies: "adminConfig/getCurrencies",
    }),
    selectedCurrency() {
      return (
        this.currencies.find(
          (x) => x.id === this.enterpriseDataEdit?.currency
        ) || {
          name: "-",
        }
      );
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch(force) {
      this.loading = true;
      await this.$store.dispatch("adminConfig/fetchEnterpriseData", force);
      await this.$store.dispatch("adminConfig/fetchCurrencies", force);
      this.enterpriseData =
        this.$store.getters["adminConfig/getEnterpriseData"];
      if (this.enterpriseData) {
        this.enterpriseDataEdit = cloneDeep(this.enterpriseData);
        this.enterpriseDataEdit.currency = this.enterpriseDataEdit.currency?.id;
      }
      await this.$store.dispatch("adminConfig/fetchIssueTrackingData", force);
      this.issueTrackingData =
        this.$store.getters["adminConfig/getIssueTrackingData"];
      if (this.issueTrackingData) {
        this.issueTrackingDataEdit = cloneDeep(this.issueTrackingData);
        delete this.issueTrackingDataEdit.email;
        delete this.issueTrackingDataEdit.apiKey;
        if (!this.issueTrackingData.email) this.editEmail = true;
        if (!this.issueTrackingData.apiKey) this.editApiKey = true;
      }
      this.loading = false;
    },
    async save() {
      let success = false;
      success =
        (await this.saveEnterpriseData()) &&
        (await this.saveIssueTrackingData());
      if (success) {
        await this.fetch(true);
        this.editMode = false;
      }
    },
    async saveEnterpriseData() {
      this.v$.enterpriseDataEdit.$touch();
      if (!this.v$.enterpriseDataEdit.$invalid) {
        const payload = deepCopy(this.enterpriseDataEdit);
        payload.currency = this.selectedCurrency;
        try {
          await this.$store.dispatch("adminConfig/saveEnterpriseData", payload);
          return true;
        } catch (_) {
          return false;
        }
      } else {
        this.activeTab = "ENTERPRISE";
      }
      return false;
    },
    async saveIssueTrackingData() {
      this.touch$();
      if (!this.v$.issueTrackingDataEdit.$invalid) {
        const payload = deepCopy(this.issueTrackingDataEdit);
        if (!this.editEmail) delete payload.email;
        if (!this.editApiKey) delete payload.apiKey;
        try {
          await this.$store.dispatch(
            "adminConfig/saveIssueTrackingData",
            payload
          );
          return true;
        } catch (_) {
          return false;
        }
      } else {
        this.activeTab = "ISSUE_TRACKING";
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.data-details-label {
  color: $custom-light-blue;
}

.data-details-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.currency-dropdown-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 250px;
  cursor: pointer;
  background: $white;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: $custom-dark-blue;
  font-weight: 500;
  font-size: 14px;
}
</style>
