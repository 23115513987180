<template>
  <div>
    <money-modal
      :label="$tf('projectpage.invoices|Számlák')"
      @close="invoiceModal = false"
      :employees="employees"
      :show="invoiceModal"
      :create="createInvoice"
      :edit-data="baseInvoice"
      type="invoices"
      :data-type="$tf('projectpage.invoice|Számla')"
      :tigs="projectData.tigDatas || []"
    />
    <b-modal v-model="newTig" @close="afterNewTig()">
      <div class="card p-4">
        <tig-form
          :create="editTigData === null"
          :project-identifier="projectData.identifier"
          :tig-data="editTigData ? editTigData : newTigData"
          :project-data="projectData"
          v-on:cancel="newTig = false"
          v-on:tig-saved="afterNewTig"
        />
      </div>
    </b-modal>
    <div class="is-flex is-justify-content-space-between">
      <div>
        <h3 class="title mb-4">
          {{ $tf("projectPage.tigList.title|Mérföldkövek") }}
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            type="is-info"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectPage.tigList.tooltip|A projekthez már felvett mérföldkövek listája."
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </div>
      <div>
        <b-button rounded size="is-small" type="is-info" @click="addNewTig">
          {{ $tf("projectpage.tigList.add|Új mérföldkő hozzáadása") }}
        </b-button>
      </div>
    </div>
    <div class="tigs">
      <template v-if="!hasTigs">
        <div
          class="is-flex is-align-items-center is-justify-content-center mt-2"
        >
          <h3 class="title">
            {{ $tf("projectpage.tigList.noTig|Nincs mérföldkő a projekten") }}
          </h3>
        </div>
      </template>
      <template v-else>
        <b-table
          ref="tigTable"
          :data="projectData.tigDatas || []"
          :loading="loading"
          :aria-current-label="$tf('aria.current.label|Aktuális lap')"
          :aria-next-label="$tf('aria.next.label|Következő lap')"
          :aria-page-label="$tf('aria.page.label|Lap')"
          :aria-previous-label="$tf('aria.previous.label|Előző lap')"
        >
          <b-table-column
            v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
            v-slot="props"
            field="link"
            :label="$tf('projectpage.tigList.table.url|Link')"
            centered
          >
            <external-issue-targeting-link
              v-if="props.row.type !== 'UNTRACKED'"
              :modelValue="props.row.externalIssueTargeting"
            />
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="identifier"
            :label="$tf('projectpage.tigList.table.identifier|Azonosító')"
            sortable
          >
            {{ props.row.identifier }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="type"
            :label="$tf('projectpage.tigList.table.type|Típus')"
          >
            <b-tag :type="tigTypeTag(props.row.type)">{{
              $tf(TIG_TYPES[props.row.type])
            }}</b-tag>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="name"
            :label="$tf('projectpage.tigList.table.name|Név')"
            sortable
            :subheading="
              $tf('projectpage.tigList.table.subheading.sum|ÖSSZESEN:')
            "
          >
            <span v-if="props.row.name === '@'">
              <strong>{{
                $tf("projectpage.tigList.table.sum|ÖSSZESEN:")
              }}</strong>
            </span>
            <span v-else>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="dueDate"
            :label="$tf('projectpage.tigList.table.dueDate|Időzítések')"
            sortable
            :subheading="formatDate(tigMaxDate, '-')"
          >
            <div>{{ formatDate(props.row.startDate) }}</div>
            <div>{{ formatDate(props.row.dueDate) }}</div>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="budget"
            :label="
              $tf(
                'projectpage.tigList.table.budgetAndCommitment|Büdzsé / Vállalás'
              )
            "
            sortable
            :subheading="
              moneyify(tigSumBudget) + ' / ' + workdayify(tigSumCommitment)
            "
          >
            <template v-if="props.row.type === 'UNTRACKED'"> - </template>
            <template v-else>
              <div>{{ moneyify(props.row.budget) }}</div>
              <div>{{ workdayify(props.row.commitment) }}</div>
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="budget"
            :label="
              $tf('projectpage.tigList.table.billableAmount|Számlázható összeg')
            "
            sortable
            :subheading="projectData.internal ? '-' : moneyify(tigSumIncome)"
          >
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              <template v-if="props.row.type === 'MILESTONE'"> - </template>
              <template v-else>
                <div>{{ moneyify(props.row.billableAmount) }}</div>
                <div>{{ percentify(props.row.billablePercentage / 100) }}</div>
              </template>
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="status"
            :label="$tf('projectpage.tigList.table.status|Státusz')"
            sortable
          >
            <b-tag :type="getTigStatusObject(props.row.status).type">
              {{ getTigStatusObject(props.row.status).text }}
            </b-tag>
          </b-table-column>
          <b-table-column
            v-slot="props"
            centered
            field="actions"
            :label="$tf('projectpage.tigList.table.actions|Műveletek')"
            width="80"
          >
            <clickable-icon
              icon="file-lines"
              type="is-info"
              :tooltip="
                $tf(
                  'projectpage.tigList.table.actions.createInvoice|Számla kiállítása'
                )
              "
              @click="openInvoiceModal(props.row)"
            />
            <clickable-icon
              icon="edit"
              type="is-info"
              :tooltip="
                $tf('projectpage.tigList.table.actions.edit|Szerkesztés')
              "
              @click="editTig(props.row)"
            />
            <clickable-icon
              icon="trash"
              type="is-danger"
              :tooltip="$tf('projectpage.tigList.table.actions.delete|Törlés')"
              @click="deleteTig(props.row)"
            />
          </b-table-column>
        </b-table>
        <br />
        <div class="is-flex is-flex-wrap-wrap info-boxes">
          <project-form-info-box
            :header="$tf('projectpage.tigList.check.title|Ellenőrzés:')"
            :text="
              $tf(
                'projectpage.tigList.check.inProjectNumbers|Projektbeli számokban'
              )
            "
            suffix=""
            has-borders
            type="is-light"
          />
          <project-form-info-box
            :header="
              $tf(
                'projectpage.tigList.check.projectCommitment|Projektbeli vállalás'
              )
            "
            :text="workdayify(projectData.commitment)"
            has-borders
            :type="
              tigSumCommitment === projectData.commitment
                ? 'is-success'
                : 'is-warning'
            "
          />
          <project-form-info-box
            :header="
              $tf('projectpage.tigList.check.projectBudget|Projekt büdzsé')
            "
            :text="projectData.budget"
            :mask="moneyify"
            has-borders
            :type="
              tigSumBudget === projectData.budget ? 'is-success' : 'is-warning'
            "
          />
          <project-form-info-box
            :header="
              $tf(
                'projectpage.tigList.check.projectExpectedIncome|Projekt várt bevétel'
              )
            "
            :text="projectData.internal ? ' - ' : projectData.expectedIncome"
            :mask="projectData.internal ? null : moneyify"
            has-borders
            :type="
              projectData.internal ||
              tigSumIncome === projectData.expectedIncome
                ? 'is-success'
                : 'is-warning'
            "
          />
          <project-form-info-box
            :header="
              $tf('projectpage.tigList.check.projectDueDate|Projekt határideje')
            "
            :text="projectData.dueDate"
            suffix=""
            has-borders
            :type="
              tigMaxDate - new Date(projectData.dueDate) < 1200
                ? 'is-success'
                : 'is-warning'
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  deepCopy,
  formatDate,
  getTigStatusObject,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import TigForm from "@/components/enterprise/project/form/TigForm";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import ProjectFormInfoBox from "@/components/enterprise/project/module/ProjectFormInfoBox.vue";
import ExternalIssueTargetingLink from "@/components/enterprise/targeting/ExternalIssueTargetingLink.vue";
import { mapGetters } from "vuex";
import {
  INVOICE_STATUS,
  ISSUE_TRACKING_SYSTEM,
  TIG_TYPES,
} from "@/utils/const";
import MoneyModal from "@/components/enterprise/ProjectDetails/TabItems/MoneyModal.vue";

export default {
  name: "TigList",
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    journalEntries: {
      type: Array,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
  },
  components: {
    MoneyModal,
    ExternalIssueTargetingLink,
    ProjectFormInfoBox,
    TigForm,
    ClickableIcon,
  },
  emits: ["fetch"],
  computed: {
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    tigTypeNameMap() {
      return {};
    },
    tigSumBudget() {
      let sumBudget = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumBudget += tig.budget ? tig.budget : 0;
        }
      }
      return sumBudget;
    },
    tigSumCommitment() {
      let sumCommitment = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumCommitment += tig.commitment ? tig.commitment : 0;
        }
      }
      return sumCommitment;
    },
    tigSumIncome() {
      let sumIncome = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumIncome += tig.billableAmount ? tig.billableAmount : 0;
        }
      }
      return sumIncome;
    },
    tigSumPercent() {
      let sumPercent = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumPercent += tig.billablePercentage ? tig.billablePercentage : 0;
        }
      }
      return sumPercent;
    },
    hasTigs() {
      return !!this.projectData.tigDatas?.length;
    },
    tigMaxDate() {
      let maxDate = new Date(1337);
      if (this.hasTigs) {
        for (let tig of this.projectData.tigDatas) {
          if (maxDate < new Date(tig.dueDate)) {
            maxDate = new Date(tig.dueDate);
          }
        }
      } else {
        return null;
      }
      return maxDate;
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      employees: "employee/loggingEmployees",
    }),
  },
  data() {
    return {
      formatDate,
      moneyify,
      workdayify,
      percentify,
      TIG_TYPES,
      newTig: false,
      newTigData: {},
      editTigData: null,
      loading: false,
      invoiceModal: false,
      baseInvoice: {},
    };
  },
  methods: {
    openInvoiceModal(tig) {
      this.baseInvoice = {
        tigIdentifiers: [tig.identifier],
        summary: "",
        cost: tig.billableAmount ?? 0,
        number: "",
      };
      this.invoiceModal = true;
    },
    async createInvoice(invoice) {
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        projectId: this.projectData.id,
        number: invoice.number,
        status: INVOICE_STATUS.DUE.enum,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    tigTypeTag(type) {
      switch (type) {
        case "TIG":
        case "CR":
          return "is-info";
        case "MILESTONE":
          return "is-warning";
        case "UNTRACKED":
          return "is-success";
      }
      return "is-dark";
    },
    getTigStatusObject,
    async fetchData(force) {
      this.loading = true;
      this.$emit("fetch", force);
      this.loading = false;
    },
    addNewTig() {
      this.newTigData = {};
      this.newTig = true;
    },
    async afterNewTig() {
      this.newTig = false;
      this.editTigData = null;
      await this.fetchData(true);
    },
    editTig(data) {
      this.editTigData = deepCopy(data);
      this.newTig = true;
    },
    deleteTig(data) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectpage.tigList.deleteModal.title|Mérföldkö törlése"
        ),
        message: this.$tf(
          "projectpage.tigList.deleteModal.message|Biztos, hogy kitörlöd a {name} mérföldkövet?",
          { name: data.name }
        ),
        confirmText: this.$tf("projectpage.tigList.deleteModal.confirm|Törlés"),
        cancelText: this.$tf("projectpage.tigList.deleteModal.cancel|Mégsem"),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch("enterprise_projects/deleteTig", data.id);
          await this.fetchData(true);
        },
      });
    },
  },
};
</script>
<style lang="scss">
.info-boxes {
  gap: 20px;
  @media screen and (max-width: 800px) {
    justify-content: center !important;
    * {
      width: 100%;
    }
  }
}
</style>
