import { createRouter, createWebHistory } from "vue-router";
import Other from "../views/Other.vue";
import EmployeeTime from "../views/census/user/EmployeeTime";
import MyTime from "../views/sidebar/MyTime";
import Employees from "../views/census/user/EmployeeList";
import UserList from "../views/census/user/UserList";
import EnterpriseProjectList from "../views/enterprise/project/ProjectList";
import EnterpriseClientList from "../views/enterprise/client/Clients";
import EnterpriseProjectCreate from "../views/enterprise/project/ProjectCreate";
import EnterpriseProjectDetails from "../views/enterprise/project/ProjectDetails";
import EnterpriseOutsourceProjectDetails from "../views/enterprise/project/OutsourceProjectDetails";
import EnterpriseSupportProjectDetails from "../views/enterprise/project/SupportProjectDetails";
import EnterpriseTiers from "../views/enterprise/tiers/Tiers";
import EnterpriseOverview from "../views/enterprise/admin/Overview";
import InvoiceList from "@/views/enterprise/invoices/InvoiceList.vue";
import TenantList from "../views/tenant/TenantList";
import AbsenceRequestsAdmin from "../views/absence/request/AllAbsenceRequests";
import AbsenceReports from "../views/absence/Reports";
import AbsenceSpecialDay from "../views/absence/SpecialDay";
import Dashboard from "../views/sidebar/Dashboard";
import AbsenceRequest from "../views/sidebar/AbsenceRequest";
import AdminRequest from "../views/absence/request/AdminRequest";
import WorkScheduleList from "../views/census/workschedule/WorkScheduleList";
import WorkScheduleForm from "../views/census/workschedule/WorkScheduleForm";
import AbsenceHandleAbsenceRequestList from "../views/absence/request/HandleAbsenceRequestList";
import AbsenceHandleAbsenceRequestForm from "../views/absence/request/HandleAbsenceRequestForm";
import QuotaList from "../views/absence/quota/QuotaList";
import QuotaForm from "../views/absence/quota/QuotaForm";
import MyWorkSchedule from "../views/sidebar/MyWorkSchedule";
import MyWorkScheduleForm from "../views/census/workschedule/MyWorkScheduleForm";
import ProjectDimensions from "../views/enterprise/project/dimension/ProjectDimensions.vue";
import ProjectDimensionAssigner from "@/views/enterprise/project/dimension/ProjectDimensionAssigner.vue";
import ProjectBaseData from "@/views/enterprise/project/ProjectBaseData.vue";
import OvertimePolicies from "../views/enterprise/overtime/policy/OvertimePolicies";
import HandleOvertimeRequestList from "../views/enterprise/overtime/request/HandleOvertimeRequestList";
import HandleOvertimeRequestForm from "../views/enterprise/overtime/request/HandleOvertimeRequestForm";
import Teams from "../views/census/team/TeamList";
import TeamEdit from "../views/census/team/TeamEdit";
import RiskManagement from "../views/enterprise/admin/RiskManagement";
import Statistics from "../views/enterprise/admin/Statistics";
import UserIndicatorList from "../views/census/indicator/UserIndicatorList";
import WorkScheduleSiteList from "../views/census/workschedule/WorkScheduleSiteList";
import AbsenceTypeList from "../views/absence/type/AbsenceTypeList";
import Profile from "../views/sidebar/Profile";
import SignIn from "../views/SignIn";
import RootSignIn from "../views/RootSignIn.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import store from "../store";
import Sites from "../views/census/seating/SiteList";
import SiteEdit from "../views/census/seating/SiteEdit";
import RoomEdit from "../views/census/seating/RoomEdit";
import SeatEdit from "../views/census/seating/SeatEdit";
import MySeatingArrangement from "../views/census/seating/MySeatingArrangement";
import SeatingCanvasDrawing from "../views/census/seating/SeatingArrangementCanvasDrawing";
import SeatingCanvasReadOnly from "../views/census/seating/SeatingArrangementCanvasReadOnly";
import Permissions from "../views/census/permission/UserPermissionList";
import HandePermissionRequestList from "../views/census/permission/HandlePermissionRequestList";
import ResetPassword from "../views/ResetPassword";
import ActiveLoginsList from "../views/session/ActiveLoginsList";
import AdminConfigEdit from "../views/admin-config/AdminConfigEdit.vue";
import ExecutiveDashboard from "@/views/enterprise/admin/ExecutiveDashboard.vue";
import UploadAssociations from "@/views/ralloc/UploadAssociations.vue";
import LoggingView from "@/views/enterprise/logging/LoggingView.vue";
import SelfTrackingTaskList from "@/views/enterprise/logging/SelfTrackingTaskList.vue";

import ForecastSegment from "../views/enterprise/forecast/ForecastSegment";
import ForecastChanceCategory from "@/views/enterprise/forecast/ForecastChanceCategory.vue";
import ForecastScenario from "@/views/enterprise/forecast/ForecastScenario.vue";
import ForecastPlan from "../views/enterprise/forecast/ForecastPlan";
import ForecastFact from "../views/enterprise/forecast/ForecastFact";
import { PRIVILEGES } from "@/utils/const";
import RoleEditor from "@/views/admin-config/RoleEditor.vue";

import OnboardingPage from "@/views/OnboardingPage.vue";
import ForecastDashboard from "@/views/enterprise/forecast/ForecastDashboard.vue";
import RallocMainPage from "@/views/ralloc/RallocMainPage.vue";
import ProjectResourceView from "@/views/ralloc/ProjectResourceView.vue";

const routes = [
  // Main views
  {
    path: "/",
    name: "Home",
    component: store.getters["session/isRoot"] ? TenantList : Dashboard,
  },
  {
    path: "/my-time",
    name: "MyTime",
    component: MyTime,
  },
  {
    path: "/logging",
    name: "Logging",
    component: LoggingView,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: SelfTrackingTaskList,
  },
  {
    path: "/my-schedule",
    name: "MySchedule",
    component: MyWorkSchedule,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.WORKSCHEDULE.GET,
        PRIVILEGES.CENSUS.WORKSCHEDULE.ADMIN,
      ],
    },
  },
  {
    path: "/my-schedule/edit/:year?",
    name: "AbsenceMyWorkScheduleForm",
    component: MyWorkScheduleForm,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.WORKSCHEDULE.GET,
        PRIVILEGES.CENSUS.WORKSCHEDULE.ADMIN,
      ],
    },
  },
  {
    path: "/my-seating",
    name: "MySeatingArrangement",
    component: MySeatingArrangement,
  },
  {
    path: "/colleagues",
    name: "Colleagues",
    component: Employees,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.MISC.LIST_COLLEAGUES,
        PRIVILEGES.CENSUS.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/statistics",
    name: "ControllingStatistics",
    component: Statistics,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.MISC.LIST_STATISTICS,
        PRIVILEGES.ENTERPRISE.MISC.ADMIN,
      ],
    },
  },
  // Absence pages
  {
    path: "/absence/my-requests",
    name: "AbsenceMyRequests",
    component: AbsenceRequest,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.REQUEST.GET,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN,
      ],
    },
  },
  // AbsenceAdmin pages
  {
    path: "/absence/admin/requests",
    name: "AbsenceAdminRequests",
    component: AbsenceRequestsAdmin,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.REQUEST.CREATE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN,
      ],
    },
  },
  {
    path: "/absence/admin/create",
    name: "AbsenceAdminCreate",
    component: AdminRequest,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.REQUEST.CREATE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN,
      ],
    },
  },
  {
    path: "/absence/admin/special-days",
    name: "AbsenceAdminSpecialDays",
    component: AbsenceSpecialDay,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.SPECIAL_DAY.CREATE,
        PRIVILEGES.ABSENCE.SPECIAL_DAY.ADMIN,
      ],
    },
  },
  {
    path: "/absence/admin/quotas",
    name: "AbsenceQuotas",
    component: QuotaList,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.QUOTA.LIST,
        PRIVILEGES.ABSENCE.QUOTA.ADMIN,
      ],
    },
  },
  {
    path: "/absence/admin/quota/:year",
    name: "AbsenceQuota",
    component: QuotaForm,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.QUOTA.CREATE,
        PRIVILEGES.ABSENCE.QUOTA.ADMIN,
      ],
    },
  },
  {
    path: "/absence/admin/reports",
    name: "AbsenceReports",
    component: AbsenceReports,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.MISC.EXPORT_ABSENCES,
        PRIVILEGES.ABSENCE.MISC.ADMIN,
      ],
    },
  },
  // Controlling pages
  {
    path: "/controlling/dashboard",
    name: "ControllingDashboard",
    component: ExecutiveDashboard,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.MISC.LIST_OVERVIEW,
        PRIVILEGES.ENTERPRISE.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/controlling/timesheet",
    name: "ControllingTimesheet",
    component: EmployeeTime,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.TIMESHEET.GET_ASSIGNED,
        PRIVILEGES.ENTERPRISE.TIMESHEET.GET_ANY,
        PRIVILEGES.ENTERPRISE.TIMESHEET.ADMIN,
      ],
    },
  },
  {
    path: "/projects/list",
    name: "ControllingProjects",
    component: EnterpriseProjectList,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ASSIGNED,
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
  },
  {
    path: "/projects/project-create",
    name: "ControllingProjectCreate",
    component: EnterpriseProjectCreate,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.CREATE,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
  },
  {
    path: "/projects/project/:id",
    name: "NormalProjectDetails",
    component: EnterpriseProjectDetails,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.GET_ASSIGNED,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
  },
  {
    path: "/projects/outsource/:id",
    name: "OutsourceProjectDetails",
    component: EnterpriseOutsourceProjectDetails,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.GET_ASSIGNED,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
  },
  {
    path: "/projects/support/:id",
    name: "SupportProjectDetails",
    component: EnterpriseSupportProjectDetails,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.GET_ASSIGNED,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
  },
  {
    path: "/controlling/query",
    name: "ControllingQuery",
    component: EnterpriseOverview,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ASSIGNED,
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN,
      ],
    },
    props: {
      showMoney: true,
    },
  },
  {
    path: "/controlling/risk-management",
    name: "ControllingRiskManagement",
    component: RiskManagement,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.LIST_ASSIGNED,
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.ADMIN,
      ],
    },
  },
  {
    path: "/controlling/seating",
    name: "ControllingSeating",
    component: SeatingCanvasDrawing,
  },
  {
    path: "/controlling/seating-availability",
    name: "CanvasReadonly",
    component: SeatingCanvasReadOnly,
  },
  {
    path: "/approval/permissions",
    name: "UserPermissionList",
    component: HandePermissionRequestList,
  },
  {
    path: "/controlling/logins",
    name: "ActiveLoginsList",
    component: ActiveLoginsList,
  },
  {
    path: "/controlling/invoices",
    name: "InvoiceList",
    component: InvoiceList,
    meta: {
      authorities: [PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.ADMIN],
    },
  },
  {
    path: "/controlling/upload-associations",
    name: "UploadAssociations",
    component: UploadAssociations,
    meta: {
      authorities: [
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.CREATE,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN,
      ],
    },
  },
  {
    path: "/ralloc/utilization",
    name: "RallocMainPage",
    component: RallocMainPage,
    meta: {
      authorities: [
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.CREATE,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN,
      ],
    },
  },
  {
    path: "/ralloc/project/:id",
    name: "ProjectResourceView",
    component: ProjectResourceView,
    meta: {
      authorities: [
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.CREATE,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN,
      ],
    },
  },

  // Reporting pages
  {
    path: "/reports/timesheet",
    name: "ReportingTimesheet",
    component: EmployeeTime,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.TIMESHEET.GET_ANY,
        PRIVILEGES.ENTERPRISE.TIMESHEET.ADMIN,
      ],
    },
  },
  {
    path: "/reports/query",
    name: "ReportingMoneylessReport",
    component: EnterpriseOverview,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.REPORT.GET_MONEYLESS,
        PRIVILEGES.ENTERPRISE.REPORT.ADMIN,
      ],
    },
  },

  // Settings pages
  {
    path: "/settings/teams",
    name: "Teams",
    component: Teams,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.TEAM.CREATE,
        PRIVILEGES.CENSUS.TEAM.ADMIN,
      ],
    },
  },
  {
    path: "/settings/team/:id?",
    name: "TeamEdit",
    component: TeamEdit,
    props: true,
    meta: {
      authorities: [PRIVILEGES.CENSUS.TEAM.EDIT, PRIVILEGES.CENSUS.TEAM.ADMIN],
    },
  },
  {
    path: "/settings/tiers",
    name: "SettingsTiers",
    component: EnterpriseTiers,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.TIER.LIST_ANY,
        PRIVILEGES.ENTERPRISE.TIER.ADMIN,
      ],
    },
  },
  {
    path: "/settings/project-dimensions",
    name: "SettingsProjectDimensions",
    component: ProjectDimensions,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT_CATEGORIES.CREATE,
        PRIVILEGES.ENTERPRISE.PROJECT_CATEGORIES.ADMIN,
      ],
    },
  },
  {
    path: "/settings/project-dimensions/assign",
    name: "SettingsProjectDimensionsAssign",
    component: ProjectDimensionAssigner,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT_CATEGORIES.CREATE,
        PRIVILEGES.ENTERPRISE.PROJECT_CATEGORIES.ADMIN,
      ],
    },
  },
  {
    path: "/settings/project-base-data",
    name: "SettingsProjectBaseData",
    component: ProjectBaseData,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.LIST,
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.ADMIN,
      ],
    },
  },
  {
    path: "/settings/clients",
    name: "SettingsClients",
    component: EnterpriseClientList,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.CLIENT.CREATE,
        PRIVILEGES.ENTERPRISE.CLIENT.ADMIN,
      ],
    },
  },
  {
    path: "/settings/overtime-policies",
    name: "SettingsOvertimePolicies",
    component: OvertimePolicies,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.OVERTIME.LIST_POLICY,
        PRIVILEGES.ENTERPRISE.OVERTIME.ADMIN,
      ],
    },
  },
  {
    path: "/settings/seating",
    name: "SettingsSites",
    component: Sites,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.MISC.CREATE_SITE,
        PRIVILEGES.CENSUS.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/census/seating/site/:id?",
    name: "SiteEdit",
    component: SiteEdit,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.MISC.EDIT_SITE,
        PRIVILEGES.CENSUS.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/census/seating/room/:id?",
    name: "RoomEdit",
    component: RoomEdit,
    props: true,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.MISC.EDIT_SITE,
        PRIVILEGES.CENSUS.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/census/seating/seat/:id?",
    name: "SeatEdit",
    component: SeatEdit,
    props: true,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.MISC.EDIT_SITE,
        PRIVILEGES.CENSUS.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/settings/permissions",
    name: "Permissions",
    component: Permissions,
  },
  {
    path: "/settings/tenants",
    name: "TenantList",
    component: TenantList,
  },
  {
    path: "/settings/admin-config",
    name: "AdminConfig",
    component: AdminConfigEdit,
    meta: {
      authorities: [PRIVILEGES.TENANT.CONFIG.ADMIN],
    },
  },
  {
    path: "/settings/roles",
    name: "RoleEditor",
    component: RoleEditor,
    meta: {
      authorities: [
        PRIVILEGES.BULWARK.ROLE.LIST,
        PRIVILEGES.BULWARK.ROLE.ADMIN,
      ],
    },
  },
  // Requests pages
  {
    path: "/approval/overtime",
    name: "HandleOvertimeRequestList",
    component: HandleOvertimeRequestList,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.OVERTIME.LIST_REQUEST,
        PRIVILEGES.ENTERPRISE.OVERTIME.ADMIN,
      ],
    },
  },
  {
    path: "/approval/overtime/handle-requests/:id",
    name: "HandleOvertimeRequestForm",
    component: HandleOvertimeRequestForm,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.OVERTIME.LIST_REQUEST,
        PRIVILEGES.ENTERPRISE.OVERTIME.ADMIN,
      ],
    },
  },
  {
    path: "/approval/absence",
    name: "AbsenceHandleAbsenceRequestList",
    component: AbsenceHandleAbsenceRequestList,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.REQUEST.LIST_ASSIGNED,
        PRIVILEGES.ABSENCE.REQUEST.LIST_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN,
      ],
    },
  },
  {
    path: "/approval/absence/handle-requests/:id",
    name: "AbsenceHandleAbsenceRequestForm",
    component: AbsenceHandleAbsenceRequestForm,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ASSIGNED,
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN,
      ],
    },
  },
  // UserAdmin pages
  {
    path: "/census/management",
    name: "PeopleManagement",
    component: UserList,
    meta: {
      authorities: [PRIVILEGES.CENSUS.USER.GET, PRIVILEGES.CENSUS.USER.ADMIN],
    },
  },
  {
    path: "/census/attributes",
    name: "UserIndicatorList",
    component: UserIndicatorList,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.INDICATOR.CREATE,
        PRIVILEGES.CENSUS.INDICATOR.ADMIN,
      ],
    },
  },
  {
    path: "/settings/work-schedule/sites",
    name: "WorkScheduleSiteList",
    component: WorkScheduleSiteList,
  },
  {
    path: "/absence/types",
    name: "AbsenceTypeList",
    component: AbsenceTypeList,
    meta: {
      authorities: [
        PRIVILEGES.ABSENCE.TYPE.CREATE,
        PRIVILEGES.ABSENCE.TYPE.ADMIN,
      ],
    },
  },
  {
    path: "/census/schedules",
    name: "WorkScheduleList",
    component: WorkScheduleList,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.WORKSCHEDULE.LIST,
        PRIVILEGES.CENSUS.WORKSCHEDULE.ADMIN,
      ],
    },
  },
  {
    path: "/census/admin/work-schedule/",
    name: "WorkScheduleFormAdmin",
    component: WorkScheduleForm,
    meta: {
      authorities: [
        PRIVILEGES.CENSUS.WORKSCHEDULE.EDIT,
        PRIVILEGES.CENSUS.WORKSCHEDULE.ADMIN,
      ],
    },
  },

  {
    path: "/forecast/segment",
    name: "ForecastSegment",
    component: ForecastSegment,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.SEGMENT.LIST,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/forecast/chance-category",
    name: "ForecastChanceCategory",
    component: ForecastChanceCategory,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.CHANCE_CATEGORY.LIST,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/forecast/scenario",
    name: "ForecastScenario",
    component: ForecastScenario,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.SCENARIO.LIST,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/forecast/plan",
    name: "ForecastPlan",
    component: ForecastPlan,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.PLAN.LIST_OWN,
        PRIVILEGES.ENTERPRISE.FORECAST.PLAN.LIST_ANY,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/forecast/fact",
    name: "ForecastFact",
    component: ForecastFact,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.LIST_OWN,
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  {
    path: "/forecast/dashboard",
    name: "ForecastDashboard",
    component: ForecastDashboard,
    meta: {
      authorities: [
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.DASHBOARD,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN,
      ],
    },
  },
  // Többiek
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    props: true,
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
    meta: {
      protected: false,
      loginLayout: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      protected: false,
      loginLayout: true,
    },
  },
  {
    path: "/root",
    name: "RootSignIn",
    component: RootSignIn,
    meta: {
      protected: false,
      loginLayout: true,
    },
  },
  {
    path: "/reset-password/:token?",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      protected: false,
      loginLayout: true,
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: Other,
    meta: {
      protected: false,
    },
  },
  {
    path: "/onboarding/:onboardingToken",
    name: "Onboarding",
    component: OnboardingPage,
    meta: {
      protected: false,
      hideSidebar: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Other",
    component: Other,
    meta: {
      protected: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  console.debug("Routing to " + to.name);
  let isLoggedIn = store.getters["session/isReady"];
  if (to.meta && to.meta.protected === false) {
    if (isLoggedIn) {
      if (to.path === "/reset-password" || to.name === "Onboarding") {
        next();
      } else {
        next({ path: "/" });
      }
    } else {
      next();
    }
  } else {
    if (isLoggedIn && !to.meta?.authorities) {
      next();
    } else if (
      isLoggedIn &&
      to.meta?.authorities &&
      store.getters["session/hasAnyAuthority"](...to.meta.authorities)
    ) {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

export default router;
