export default {
  computed: {
    calendarLocale() {
      return {
        weekDays: [
          this.$tf("days.monday|Hétfő"),
          this.$tf("days.tuesday|Kedd"),
          this.$tf("days.wednesday|Szerda"),
          this.$tf("days.thursday|Csütörtök"),
          this.$tf("days.friday|Péntek"),
          this.$tf("days.saturday|Szombat"),
          this.$tf("days.sunday|Vasárnap"),
        ],
        months: [
          this.$tf("months.january|Január"),
          this.$tf("months.february|Február"),
          this.$tf("months.march|Március"),
          this.$tf("months.april|Április"),
          this.$tf("months.may|Május"),
          this.$tf("months.june|Június"),
          this.$tf("months.july|Július"),
          this.$tf("months.august|Augusztus"),
          this.$tf("months.september|Szeptember"),
          this.$tf("months.october|Október"),
          this.$tf("months.november|November"),
          this.$tf("months.december|December"),
        ],
        years: this.$tf("calendar.years|Évek"),
        year: this.$tf("calendar.year|Év"),
        month: this.$tf("calendar.month|Hónap"),
        week: this.$tf("calendar.week|Hét"),
        day: this.$tf("calendar.day|Nap"),
        today: this.$tf("calendar.today|Ma"),
        noEvent: this.$tf("loggingCalendar.noEvent|Nincs munkanapló"),
        allDay: this.$tf("calendar.allDay|Egész nap"),
        deleteEvent: this.$tf("loggingCalendar.deleteEvent|Törlés"),
        createEvent: this.$tf("loggingCalendar.createEvent|Munka naplózása"),
        dateFormat: "dddd D MMMM YYYY",
      };
    },
  },
};
