<template>
  <div class="mt-5">
    <template v-if="worklogs && worklogs.length > 0">
      <b-table
        :data="worklogs"
        paginated
        per-page="20"
        default-sort="day"
        default-sort-direction="asc"
      >
        <b-table-column
          field="day"
          v-slot="props"
          :label="$tf('timesheetRows.table.date|Dátum')"
          sortable
        >
          <div v-html="props.row.day.toString()" class="has-font-weight-500" />
        </b-table-column>
        <b-table-column
          field="projectName"
          v-slot="props"
          :label="$tf('timesheetRows.table.projectName|Projektnév')"
          searchable
          sortable
        >
          {{ props.row.projectName }}
        </b-table-column>
        <b-table-column
          field="projectIdentifier"
          v-slot="props"
          :label="$tf('timesheetRows.table.projectIdentifier|Projektazonosító')"
          searchable
          sortable
        >
          <p class="has-font-weight-500">
            <template v-if="myTimeSheet">
              {{ props.row.projectIdentifier }}
            </template>
            <router-link
              :to="projectIdentifierToPageUrl(props.row.projectIdentifier)"
              v-else
            >
              {{ props.row.projectIdentifier }}
            </router-link>
          </p>
        </b-table-column>
        <b-table-column
          field="taskKeyAndName"
          v-slot="props"
          :label="$tf('timesheetRows.table.task|Taszk')"
          searchable
          sortable
        >
          <a
            class="has-font-weight-500"
            :href="createTicketUrl(props.row.issueKey)"
          >
            {{ props.row.taskKeyAndName }}
          </a>
        </b-table-column>
        <b-table-column
          field="timespent"
          :label="$tf('timesheetRows.table.timespent|Időtartam')"
          sortable
          v-slot="props"
        >
          <div class="cursor-pointer">
            <label class="cursor-pointer has-font-weight-500">
              {{ $filterSecondToTimeFormat(props.row.timespent) }}
            </label>
          </div>
        </b-table-column>
        <b-table-column v-slot="props" width="32" v-if="myTimeSheet">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            v-if="canCreateOvertime(props.row) || canRevokeOvertime(props.row)"
          >
            <template #trigger>
              <b-icon
                role="button"
                icon="ellipsis-v"
                class="timesheet-details-row-header-worklogDto-table-icon"
              ></b-icon>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              v-if="canCreateOvertime(props.row)"
              @click="openOvertimeRequest(props.row)"
              >{{
                $tf("timesheetRows.table.actions.addOvertime|Túlóra rögzítése")
              }}</b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              v-if="canRevokeOvertime(props.row)"
              @click="revokeOvertimeRequest(props.row)"
              >{{
                $tf(
                  "timesheetRows.table.actions.revokeOvertime|Túlóra visszavonása"
                )
              }}</b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              @click="deleteLog(props.row)"
              >{{
                $tf("timesheetRows.table.actions.delete|Logolás törlése")
              }}</b-dropdown-item
            >
          </b-dropdown>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import {
  createTicketUrl,
  deepCopy,
  openDeleteConfirm,
  projectIdentifierToPageUrl,
} from "@/utils/util";
import { DAY_NUMBER_LABEL, OVERTIME_REQUEST_STATUS } from "@/utils/const";
import OvertimeRequestForm from "@/components/enterprise/overtime/OvertimeRequestForm.vue";
import eventBus, { EVENTS } from "@/utils/eventBus";

export default {
  name: "TimesheetRows",
  components: {},
  props: {
    timelogs: {
      type: Array,
      required: true,
      default: () => [],
    },
    expected: {
      type: Array,
      required: true,
      default: () => [],
    },
    myTimeSheet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projectIdentifierToPageUrl,
      createTicketUrl,
      DAY_NUMBER_LABEL,
    };
  },
  computed: {
    worklogs() {
      return deepCopy(this.timelogs)
        .map((day) =>
          day.worklogs.map((worklogDto) => ({
            day: {
              date: day.day,
              dayOfWeekLabel: this.$tf(
                DAY_NUMBER_LABEL[new Date(day.day).getDay()]
              ),
              toString() {
                return `<p class="my-0" style="white-space: nowrap">${this.date}</p><p class="my-0" style="white-space: nowrap">${this.dayOfWeekLabel}</p>`;
              },
            },
            taskKeyAndName: this.buildTaskName(worklogDto),
            ...worklogDto,
          }))
        )
        .flat();
    },
  },
  methods: {
    buildTaskName(worklogDto) {
      return `${worklogDto.issueKey} - ${worklogDto.taskName}`;
    },

    async deleteLog(props) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("worklogDto/deleteWorklog", props.id);
      });
    },

    canCreateOvertime(row) {
      return (
        !row.overtimeRequestId ||
        row.overtimeRequestStatus === OVERTIME_REQUEST_STATUS.ENUM.REVOKED
      );
    },
    canRevokeOvertime(row) {
      return (
        (row.overtimeRequestId &&
          row.overtimeRequestStatus === OVERTIME_REQUEST_STATUS.ENUM.REQUEST) ||
        row.overtimeRequestStatus === OVERTIME_REQUEST_STATUS.ENUM.APPROVED
      );
    },
    openOvertimeRequest(row) {
      this.$buefy.modal.open({
        parent: this,
        component: OvertimeRequestForm,
        props: { issueKey: row.issueKey, timespent: row.timespent, id: row.id },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    revokeOvertimeRequest(row) {
      this.$buefy.dialog.confirm({
        type: "is-warning",
        hasIcon: true,
        title: "Túlóra kérelem visszavonása",
        message: "Szeretnéd visszavonni a túlóra kérelmet?",
        cancelText: "Mégse",
        confirmText: "Igen",
        onConfirm: async () =>
          this.$store
            .dispatch("overtime_requests/revoke", row.overtimeRequestId)
            .then(() => {
              eventBus.$emit(EVENTS.OVERTIME_REQUESTED);
            }),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/colors";

.empty-timesheet-details {
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  color: $custom-dark-blue;
}
</style>
