<template>
  <section class="section">
    <div class="container">
      <b-tabs v-model="activeTab" class="toggle-only" expanded type="is-toggle">
        <b-tab-item
          v-for="tab in PROFILE_TABS"
          :key="tab.value"
          :label="$tf(tab.label)"
          :value="tab.value"
        />
      </b-tabs>
      <template v-if="activeTab === PROFILE_TABS.BASE_DATA.value">
        <div class="columns">
          <div class="column is-half">
            <div class="card">
              <div class="card-header">
                <p class="card-header-title">
                  {{ $tf("profile.idCard|Névjegy") }}
                </p>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <f-avatar :data="profile" size="96x96" :font-size="2" />
                  </div>
                  <div class="media-content">
                    <p class="title is-4">{{ profile.name }}</p>
                    <p class="subtitle is-5">{{ profile.title }}</p>
                    <p class="subtitle is-6">
                      <b-tag rounded>{{ profile.email }}</b-tag>
                      <b-tag rounded>{{ $filterPhone(profile.phone) }}</b-tag>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="card">
              <div class="card-header">
                <p class="card-header-title">
                  {{
                    $tf("profile.editableProfileData|Módosítható profil adatok")
                  }}
                </p>
              </div>
              <div class="card-content">
                <b-field grouped>
                  <phone-input
                    ref="phoneInput"
                    v-model="userEdit.phone"
                    :label="$tf('profile.phone|Telefonszám')"
                    :placeholder="
                      $tf('profile.phone.placeholder|Munkatárs telefonszáma')
                    "
                    required
                    width="100%"
                    with-loading
                    expanded
                    @validate="validPhoneInput = $event"
                  />

                  <phone-input
                    ref="contactInput"
                    v-model="userEdit.contact"
                    :label="$tf('profile.contact|Elérhetőség')"
                    :placeholder="
                      $tf('profile.contact.placeholder|Elérhetőség')
                    "
                    width="100%"
                    with-loading
                    expanded
                    @validate="validContactInput = $event"
                  />
                </b-field>
                <b-field grouped>
                  <b-field-with-loading
                    :label="$tf('profile.birthMonth|Születési hónap')"
                    expanded
                    label-position="on-border"
                    width="100%"
                  >
                    <f-datepicker
                      v-model="userEdit.birthDate"
                      :placeholder="
                        $tf('profile.birthMonth.placeholder|Születési hónap')
                      "
                      icon="calendar-week"
                      month-view
                      position="is-bottom-right"
                      type="month"
                    >
                      <template #header>
                        <span> </span>
                      </template>
                    </f-datepicker>
                  </b-field-with-loading>
                  <b-field-with-loading
                    :label="$tf('profile.birthDay|Születési nap')"
                    expanded
                    label-position="on-border"
                    width="100%"
                  >
                    <f-datepicker
                      v-model="userEdit.birthDate"
                      :day-names="[]"
                      :nearby-month-days="false"
                      :placeholder="
                        $tf('profile.birthDay.placeholder|Születési nap')
                      "
                      day-view
                      icon="calendar-week"
                      position="is-bottom-right"
                    >
                      <template #header>
                        <span> </span>
                      </template>
                    </f-datepicker>
                  </b-field-with-loading>
                </b-field>

                <b-field-with-loading
                  :label="$tf('profile.flair|Bio')"
                  expanded
                  label-position="on-border"
                  width="100%"
                >
                  <b-input
                    v-model="userEdit.flair"
                    :placeholder="
                      $tf(
                        'profile.flair.placeholder|Egyedi megjegyzés / idézet / ars poetica'
                      )
                    "
                    icon="quote-left"
                  >
                  </b-input>
                </b-field-with-loading>
                <hr />
                <b-field-with-loading
                  :label="$tf('profile.avatar|Avatar')"
                  expanded
                  label-position="on-border"
                  width="100%"
                >
                  <ForcaUpload
                    v-model="userEdit.avatar"
                    :accept="UPLOAD_ACCEPT.IMAGE"
                  />
                </b-field-with-loading>
                <div class="is-flex is-justify-content-end">
                  <b-field-with-loading>
                    <b-button
                      type="is-info"
                      @click="changeProfile"
                      :disabled="!validPhoneInput || !validContactInput"
                    >
                      {{ $tf("profile.save|Mentés") }}
                    </b-button>
                  </b-field-with-loading>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card">
              <div class="card-header">
                <p class="card-header-title">
                  {{ $tf("profile.identifiers|Azonosítók") }}
                </p>
              </div>
              <div class="card-content">
                <ul>
                  <li>
                    {{ $tf("profile.identifiers.id|ID") }}:
                    <b-tag rounded>{{ profile.id }}</b-tag>
                  </li>
                  <li>
                    {{ $tf("profile.identifiers.username|Felhasználó név") }}:
                    <b-tag rounded>{{ profile.username }}</b-tag>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="card">
              <div class="card">
                <div class="card-header">
                  <p class="card-header-title">
                    {{ $tf("profile.attributes|Attribútumok") }}
                  </p>
                </div>
                <div class="card-content">
                  <ul>
                    <li>
                      {{ $tf("profile.attributes.department|Terület") }}:
                      <b-tag rounded>{{ profile.department }}</b-tag>
                    </li>
                    <li>
                      {{ $tf("profile.attributes.role|Munkakör") }}:
                      <b-tag rounded>{{ profile.role }}</b-tag>
                    </li>
                    <li>
                      {{ $tf("profile.attributes.seniority|Szenioritás") }}:
                      <b-tag rounded>{{ profile.seniority }}</b-tag>
                    </li>
                    <li>
                      {{ $tf("profile.attributes.competencies|Kompetenciák") }}:
                      <b-tag
                        v-for="(competency, index) in profile.competencies"
                        :key="index"
                        rounded
                      >
                        {{ competency.name }}
                      </b-tag>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div class="card">
              <div class="card">
                <div class="card-header">
                  <p class="card-header-title">
                    {{ $tf("profile.companyRelated|Céges adatok") }}
                  </p>
                </div>
                <div class="card-content">
                  <ul>
                    <li>
                      {{
                        $tf("profile.companyRelated.joined|Felvétel dátuma")
                      }}:
                      <b-tag rounded>{{ profile.startDate }}</b-tag>
                    </li>
                    <li v-if="profile.external">
                      {{
                        $tf(
                          "profile.companyRelated.external|Külsős kolléga, anyacég"
                        )
                      }}:
                      <b-tag rounded>{{ profile.parentCompany }}</b-tag>
                    </li>
                    <li v-if="profile.loggingRequired">
                      {{
                        $tf(
                          "profile.companyRelated.timesheetingRequired|Logolásra kötelezett."
                        )
                      }}
                    </li>
                    <li v-if="!profile.loggingRequired">
                      {{
                        $tf(
                          "profile.companyRelated.notTimesheeting|Logolásra nem kötelezett."
                        )
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="activeTab === PROFILE_TABS.API_KEYS?.value">
        <ApikeyInput
          v-model="apiKeyEdit[issueTrackingSystem]"
          :expiration="apiKeyExpirationEdit[issueTrackingSystem]"
          :type="issueTrackingSystem"
          @delete="deleteApikey"
          @expirationChange="apiKeyExpirationEdit[issueTrackingSystem] = $event"
        />
        <ApikeyInput
          v-for="apiKeyType in Object.keys(API_KEY_TYPES.OTHER)"
          :key="apiKeyType"
          v-model="apiKeyEdit[apiKeyType]"
          :expiration="apiKeyExpirationEdit[apiKeyType]"
          :type="apiKeyType"
          @delete="deleteApikey"
          @expirationChange="apiKeyExpirationEdit[apiKeyType] = $event"
        />
        <br />
        <b-button type="is-info" @click="changeProfile">
          {{ $tf("profile.save|Mentés") }}
        </b-button>
        <!--        <ApikeyInput TODO: ez egyelőre értelmetlen, temporarily disabled -->
        <!--          v-for="apiKeyType in Object.keys(API_KEY_TYPES.GENERATE)"-->
        <!--          :key="apiKeyType"-->
        <!--          v-model="apiKeyEdit[apiKeyType]"-->
        <!--          :expiration="apiKeyExpirationEdit[apiKeyType]"-->
        <!--          :type="apiKeyType"-->
        <!--          @expirationChange="apiKeyExpirationEdit[apiKeyType] = $event"-->
        <!--          @delete="deleteApikey"-->
        <!--          generate-->
        <!--          @saveNow="saveApiKey($event)"-->
        <!--        />-->
      </template>

      <template v-else-if="activeTab === PROFILE_TABS.SITE.value">
        <div class="columns">
          <div class="column is-half">
            <p class="subtitle">
              {{ $tf("profile.colorScheme.title|Színséma") }}
            </p>
            <div class="is-flex has-gap-2 is-align-items-center">
              <b-select
                :modelValue="localFetch('saved_ui_theme')"
                @update:modelValue="setTheme"
              >
                <option :value="UI_THEMES.SYSTEM">
                  {{ $tf("profile.colorScheme.system|Rendszerbeállítás") }}
                </option>
                <option :value="UI_THEMES.LIGHT">
                  {{ $tf("profile.colorScheme.light|Világos") }}
                </option>
                <option :value="UI_THEMES.DARK">
                  {{ $tf("profile.colorScheme.dark|Sötét") }}
                </option>
              </b-select>
            </div>
            <br />
            <p class="subtitle">
              {{ $tf("profile.language.title|Nyelv") }}
            </p>
            <div class="is-flex has-gap-2 is-align-items-center">
              <b-select
                :modelValue="$i18n.locale"
                @update:modelValue="changeLanguage"
              >
                <option
                  v-for="entry in languages"
                  :key="`profileLanguage-${entry.value}`"
                  :value="entry.value"
                >
                  {{ $tf(entry.label) }}
                </option>
              </b-select>
            </div>
          </div>
          <div class="column is-half">&nbsp;</div>
        </div>
      </template>

      <template v-else-if="activeTab === PROFILE_TABS.SECURITY.value">
        <form @submit.prevent="changePassword">
          <hr />
          <div v-if="profile.hasPassword === true">
            <p class="subtitle">
              {{ $tf("profile.changePassword.title|Jelszómódosítás") }}
            </p>

            <b-field grouped>
              <b-field
                :label="$tf('profile.changePassword.newPassowrd|Új jelszó')"
                label-position="on-border"
              >
                <password-checker-input
                  v-model="newPassword"
                  @validated="passwordValid = $event"
                >
                </password-checker-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field>
                <b-button
                  :disabled="!passwordValid"
                  native-type="submit"
                  type="is-info"
                >
                  {{ $tf("profile.changePassword.modify|Módosítás") }}
                </b-button>
              </b-field>

              <b-field>
                <vuelidated-form :validations="v$.currentPassword">
                  <template v-slot="{ errors, invalid }">
                    <b-field
                      :label="
                        $tf(
                          'profile.changePassword.currentPassword|Jelenlegi jelszó'
                        )
                      "
                      :message="v$.currentPassword.$error ? errors[0] : null"
                      :type="{ 'is-danger': invalid }"
                      label-position="on-border"
                    >
                      <b-input
                        v-model="currentPassword"
                        icon="lock"
                        placeholder="******"
                        type="password"
                        @blur="v$.$touch()"
                      >
                      </b-input>
                    </b-field>
                  </template>
                </vuelidated-form>
              </b-field>
            </b-field>
          </div>
          <div v-else>
            <b-field>
              <b-button type="is-info" @click="resetPassword">
                {{ $tf("profile.resetPassword|Jelszó beállítása") }}
              </b-button>
            </b-field>
          </div>
        </form>
        <hr />
        <div class="is-flex is-align-items-center mb-3" style="gap: 20px">
          <div>
            <p class="subtitle">
              {{ $tf("profile.activeSessions.title|Aktív bejelentkezések") }}
            </p>
          </div>
          <div>
            <b-button icon-left="power-off" type="is-danger" @click="logout()"
              >{{ $tf("profile.logout|Kilépés") }}
            </b-button>
          </div>
        </div>
        <b-table :data="logins('CURRENT_USER')">
          <b-table-column
            v-slot="props"
            :label="$tf('profile.activeSessions.table.login|Belépés')"
            field="created"
            searchable
            sortable
          >
            {{ formatDate(props.row.created) }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :label="$tf('profile.activeSessions.table.location|Helyszín')"
            field="location"
            searchable
            sortable
          >
            {{ props.row.location }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :label="
              $tf('profile.activeSessions.table.system|Operációs rendszer')
            "
            field="location"
            searchable
            sortable
          >
            {{ props.row.device }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :label="$tf('profile.activeSessions.table.browser|Böngésző')"
            field="location"
            searchable
            sortable
          >
            {{ props.row.browser }}
          </b-table-column>
          <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
            <b-button
              type="is-danger"
              @click="logout(false, props.row.generation)"
              >{{ $tf("profile.activeSessions.table.logout|Kijelentkezés") }}
            </b-button>
          </b-table-column>
        </b-table>
        <div class="mt-2">
          <b-button
            icon-left="plug"
            rounded
            size="is-small"
            type="is-danger"
            @click="logout(true)"
            >{{
              $tf(
                "profile.activeSessions.logoutFromEveryDevices|Kijelentkezés minden eszközről"
              )
            }}
          </b-button>
        </div>
        <hr />
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import PasswordCheckerInput from "@/components/module/input/PasswordCheckerInput";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  changeTheme,
  correctWithTimezoneOffset,
  dateToMonthDay,
  deepCopy,
  formatDate,
  localFetch,
  openDeleteConfirm,
} from "@/utils/util";
import {
  API_KEY_TYPES,
  DEBUG_LANGUAGE,
  ISSUE_TRACKING_SYSTEM,
  PRIVILEGES,
  UI_THEMES,
  UPLOAD_ACCEPT,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import network from "@/utils/network";
import { loadLanguageAsync } from "@/plugins/i18n";
import PhoneInput from "@/components/module/input/PhoneInput.vue";
import LoadingMixin from "@/mixins/LoadingMixin";
import BFieldWithLoading from "@/components/loading/BFieldWithLoading.vue";
import ApikeyInput from "@/components/census/user/ApiKeyInput.vue";
import ApiKeyModal from "@/components/census/user/ApiKeyModal.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import FAvatar from "@/components/module/icon/FAvatar.vue";
import ForcaUpload from "@/components/ForcaUpload.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

const PROFILE_TABS = {
  BASE_DATA: {
    value: "BASE_DATA",
    label: "profile.profile|Profil",
  },
  API_KEYS: {
    value: "API_KEYS",
    label: "profile.apiKeys|API kulcsok",
  },
  SITE: {
    value: "SITE",
    label: "profile.site|Felület",
  },
  SECURITY: {
    value: "SECURITY",
    label: "profile.security|Biztonság",
  },
};

export default {
  name: "PortalProfile",
  components: {
    ForcaUpload,
    FAvatar,
    FDatepicker,
    ApikeyInput,
    BFieldWithLoading,
    PhoneInput,
    VuelidatedForm,
    PasswordCheckerInput,
  },
  mixins: [LoadingMixin],
  setup: () => useCustomVuelidate(),
  async mounted() {
    this.doStartLoading();
    await this.fetchProfile();
    this.userEdit = deepCopy(this.profile);
    if (this.userEdit.birthDate) {
      this.userEdit.birthDate = new Date("2024-" + this.userEdit.birthDate); // 2024 because leap year
    }
    if (this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum) {
      delete this.PROFILE_TABS.API_KEYS;
    }
    this.doFinishLoading();
  },
  data() {
    return {
      USER_INDICATOR_TYPE_ENUM,
      API_KEY_TYPES,
      PROFILE_TABS,
      activeTab: PROFILE_TABS.BASE_DATA.value,
      currentPassword: "",
      newPassword: "",
      passwordValid: false,
      formatDate,
      userEdit: {},
      currentDate: new Date(),
      apiKeyEdit: {},
      apiKeyExpirationEdit: {},
      validPhoneInput: true,
      validContactInput: true,
    };
  },
  validations: {
    currentPassword: { required },
    newPassword: {
      required,
    },
    userEdit: {
      name: {
        required,
      },
      username: {
        required,
      },
      email: {
        required,
      },
    },
  },
  computed: {
    UPLOAD_ACCEPT() {
      return UPLOAD_ACCEPT;
    },
    UI_THEMES() {
      return UI_THEMES;
    },
    ...mapGetters({
      profile: "census_user/profile",
      logins: "session/sessionData",
      currentSession: "session/isCurrentSession",
      permissions: "user_permission/permissions",
      permits: "user_permission/userPermits",
      indicators: "user_indicator/indicators",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      apiKeys: "api_key/list",
    }),
    languages() {
      const baseLanguages = [
        {
          value: "hu",
          label: "profile.language.hu|Magyar",
        },
        {
          value: "en",
          label: "profile.language.en|Angol",
        },
      ];
      const extLanguages = [];
      if (
        this.$store.getters["session/hasAuthority"](PRIVILEGES.MISC.BETA.BETA)
      ) {
        extLanguages.push({
          value: DEBUG_LANGUAGE,
          label: `${DEBUG_LANGUAGE}|${DEBUG_LANGUAGE}`,
        });
      }
      return baseLanguages.concat(extLanguages);
    },
  },
  methods: {
    changeTheme,
    localFetch,
    editApikey(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("api_key/delete", id);
        await this.fetchProfile(true);
      });
    },
    deleteApikey(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("api_key/delete", id);
        await this.fetchProfile(true);
      });
    },
    apiKeyWithType(type) {
      return this.apiKeys.find((it) => it.type === type);
    },
    async saveApiKey(type) {
      const key = this.apiKeyEdit[type];
      if (key) {
        let expireAt = this.apiKeyExpirationEdit[type];
        if (expireAt) {
          expireAt = correctWithTimezoneOffset(expireAt);
        }
        const self = this;
        await this.$store
          .dispatch("api_key/create", {
            key,
            type,
            expireAt,
          })
          .then(() => {
            this.$buefy.modal.open({
              parent: this,
              component: ApiKeyModal,
              props: {
                value: key,
              },
              hasModalCard: true,
              trapFocus: true,
              events: {
                async close() {
                  await self.fetchProfile(true);
                },
              },
            });
          });
      }
    },
    async changeProfile() {
      this.v$.userEdit.$touch();

      const request = deepCopy(this.userEdit);
      const avatar = this.userEdit.avatar;

      if (request.birthDate) {
        request.birthDate = dateToMonthDay(request.birthDate);
      }

      let avatarForm;
      if (avatar && typeof avatar === "object") {
        avatarForm = new FormData();
        avatarForm.append("file", avatar);
        avatarForm.append("fileName", avatar.name);
        avatarForm.append("documentType", "USER_AVATAR");
      }

      let fetch = false;

      const keyPromises = [];

      Object.keys(this.apiKeyEdit).forEach((type) => {
        fetch = true;
        let expiration = this.apiKeyExpirationEdit[type];
        if (expiration) {
          expiration = correctWithTimezoneOffset(expiration);
        }
        const current = this.apiKeyWithType(type);
        if (current) {
          keyPromises.push(
            this.$store.dispatch("api_key/update", {
              id: current.id,
              type,
              key: this.apiKeyEdit[type],
              expireAt: expiration,
            })
          );
        } else {
          keyPromises.push(
            this.$store.dispatch("api_key/create", {
              type,
              key: this.apiKeyEdit[type],
              expireAt: expiration,
            })
          );
        }
      });
      this.apiKeyEdit = {};
      this.apiKeyExpirationEdit = {};

      await Promise.all(keyPromises);

      if (!this.v$.userEdit.$invalid) {
        await this.$store.dispatch("census_user/saveProfile", {
          profile: request,
          own: true,
          avatarForm,
        });
        fetch = true;
      }
      if (fetch) await this.fetchProfile(true);
    },
    async changePassword() {
      this.v$.currentPassword.$touch();
      this.v$.newPassword.$touch();
      if (
        !this.passwordValid ||
        this.v$.currentPassword.$invalid ||
        this.v$.newPassword.$invalid
      ) {
        return;
      }

      let passwordChangeRequest = {
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
      };

      await this.$store.dispatch(
        "census_user/changePassword",
        passwordChangeRequest
      );
    },

    async resetPassword() {
      try {
        await this.$store.dispatch("census_user/resetPassword");
        this.$buefy.notification.open({
          message: this.$tf(
            "profile.resetPasswordModal.message|A jelszó bállításához szükséges teendők e-mailben elküldésre kerültek."
          ),
          duration: 5000,
          type: "is-success",
          hasIcon: true,
          pauseOnHover: true,
          position: "is-top",
        });
      } catch (e) {
        //do nothing
      }
    },
    async logout(all = false, id = null) {
      if (window.gapi.auth2) {
        let auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut();
      }
      if (!all && id === null) {
        await this.$store.dispatch("session/logMeOut").then(() => {
          this.$router.push("/login");
        });
      } else if (all) {
        await this.$store
          .dispatch("session/logMeOutEverywhere")
          .then(async () => this.$router.push("/login"));
      } else {
        await this.$store
          .dispatch(
            "session/logout",
            id
              ? [id]
              : this.logins("CURRENT_USER").map((login) => login.generation)
          )
          .then(async () => {
            if (this.currentSession) await this.$router.push("/login");
            else {
              await this.fetchProfile();
            }
          });
      }
    },
    async fetchProfile(force) {
      this.doStartLoading();
      await this.$store.dispatch("census_user/fetchProfile", { force });
      await this.$store.dispatch("session/fetchSessionData");
      await this.$store.dispatch("api_key/list", force);
      this.doFinishLoading();
    },
    async changeLanguage(newLang) {
      await loadLanguageAsync(newLang);
      const axios = await network.connection();
      try {
        await axios.patch(`census/lang/mine/${newLang}`);
      } catch (ex) {
        console.log("Failed changing language");
      }
    },
    setTheme(theme) {
      changeTheme(theme);
      this.$store.commit("uiConfigStore/setCurrentTheme", theme);
    },
  },
};
</script>
<style>
#avatar {
  margin-bottom: 0;
  margin-left: 0;
}
</style>
<style lang="scss" module scoped>
@import "~@/assets/scss/colors.scss";

.is-highlighted {
  border-radius: 8px;
  box-shadow: 0 0 5px 2px $purple;
}
</style>
