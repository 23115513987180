<template>
  <div>
    <b-modal v-model="showReadinessModal" @close="showReadinessModal = false">
      <div class="card">
        <div class="card-header">
          <h3 class="title m-5">
            {{ $tf("projectPage.readinessModal.title|Készültség beállítása") }}
          </h3>
        </div>
        <div class="card-content m-4">
          <forecast-readiness-chart
            :project-details="projectDetails"
          ></forecast-readiness-chart>
          <div class="my-4">
            <b-field grouped>
              <b-field
                :label="
                  $tf(
                    'projectPage.readinessModal.readinessType|Előrehaladás típusa'
                  )
                "
                label-position="on-border"
              >
                <b-select
                  v-model="progressEdit.progressType"
                  :placeholder="
                    $tf(
                      'projectPage.readinessModal.readinessType.placeholder|Típus'
                    )
                  "
                  icon="percentage"
                  class="has-blue-icon"
                  expanded
                >
                  <option
                    v-for="item in progressTypes"
                    :key="item"
                    :value="item"
                    :selected="item === projectDetails.progressType"
                  >
                    {{ $tf(progressToken[item]) }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-button type="is-info" @click="saveProgress">
                  {{ $tf("projectPage.readinessModal.save|Mentés") }}
                </b-button>
              </b-field>
            </b-field>
          </div>

          <div
            class="my-4"
            v-if="progressEdit.progressType === progressTypes.MANUAL"
          >
            <b-field grouped>
              <b-field class="m-0">
                <money-input
                  :label="
                    $tf(
                      'projectPage.readinessModal.manualReadiness.percentage|Készültség %'
                    )
                  "
                  label-position="on-border"
                  percentage
                  v-model="progressEdit.progress"
                />
              </b-field>
              <b-field
                :label="
                  $tf(
                    'projectPage.readinessModal.manualReadiness.summary|Összegzés'
                  )
                "
                label-position="on-border"
                expanded
                style="margin-left: 5px"
              >
                <b-input
                  expanded
                  type="text"
                  :placeholder="
                    $tf(
                      'projectPage.readinessModal.manualReadiness.summary.placeholder|Rövid magyarázat'
                    )
                  "
                  clearable
                  v-model="progressEdit.summary"
                />
              </b-field>
            </b-field>
          </div>
          <hr />
          <div class="has-text-dark-blue">
            <div class="has-font-weight-500">
              {{
                $tf(
                  "projectPage.readinessModal.manualReadiness.past|Korábban megadott manuális készültségek"
                )
              }}
            </div>
            <ul v-if="projectData && projectData.progressHistory?.length">
              <li
                v-for="(progressHistoryRecord, index) in sortedProgressHistory"
                :key="index"
              >
                <span>
                  {{ formatDateTime(progressHistoryRecord.timestamp) }} -
                </span>
                <span>
                  {{ getEmployeeNameById(progressHistoryRecord.userId) }} :
                </span>
                <span class="has-font-weight-500">
                  {{ percentify(progressHistoryRecord.progress) }}
                </span>
                <span class="is-italic">
                  {{ progressHistoryRecord.summary }}
                </span>
              </li>
            </ul>
            <div v-else class="is-italic">
              {{
                $tf(
                  "projectPage.readinessModal.manualReadiness.empty|Nem volt még beállítva manuális készültség"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <h3 class="title leading-title">
      {{ $tf("projectPage.cards.overview|Áttekintés") }}
    </h3>
    <div class="number-cards">
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.readiness|Készültség") }}
          </div>
          <div class="card-value">
            {{ percentify(projectDetails.readiness) }}
          </div>
        </template>
        <template #card-upper-right>
          <ClickableIcon
            class="mt-2"
            size="is-medium"
            icon="wrench"
            :tooltip="
              $tf('projectPage.cards.readiness.tooltip|Készültség beállítása')
            "
            @click="showReadinessModal = true"
          />
          <div class="card-right-information-with-icon">
            <template v-if="projectDetails.progressType === 'MANUAL'">
              {{ $tf("projectPage.cards.manual|Manuális") }}
            </template>
            <template v-else>
              {{ $tf("projectPage.cards.automatic|Automatikus") }}
            </template>
          </div>
        </template>
        <template #card-lower-left>
          <template v-if="sortedProgressHistory.length">
            <div class="card-additional-information">
              {{ $tf("projectPage.cards.refreshed|Frissítve") }}
            </div>
          </template>
        </template>
        <template #card-lower-right>
          <template v-if="sortedProgressHistory.length">
            <div class="card-right-value">
              {{ formatDate(sortedProgressHistory[0]?.timestamp) }}
            </div>
          </template>
        </template>
      </information-card>
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.spentSoFar|Eddigi költés") }}
          </div>
          <div class="card-value">
            {{ percentify(projectDetails.sumSpentBudget / projectData.budget) }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ moneyify(projectDetails.sumSpentBudget) }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.budget|Budget") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            {{ moneyify(projectData.budget * projectDetails.readiness) }}
          </div>
        </template>
      </information-card>
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{
              $tf("projectPage.cards.sumSpentMd|Eddigi erőforrás ráfordítás")
            }}
          </div>
          <div class="card-value">
            {{ workdayify(projectDetails.sumSpentMd) }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ percentify(projectDetails.sumSpentMd / projectData.commitment) }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.sumMdPlanned|Terv szerint") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            {{ workdayify(projectData.commitment * projectDetails.readiness) }}
          </div>
        </template>
      </information-card>
      <information-card class="number-cards-card">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.pass|Projekt átfutás") }}
          </div>
          <div class="card-value">
            {{
              percentify(
                projectDetails.daysSinceStart / projectDetails.daysBetweenDue
              )
            }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{
              percentify(
                projectDetails.daysSinceStart / projectDetails.daysBetweenDue
              )
            }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.dueDate|Határidő") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">{{ projectData.dueDate }}</div>
        </template>
      </information-card>
    </div>
    <div class="number-cards-forecast-title">
      <div class="number-cards-forecast-title-selected">
        <h3 class="title forecast-title">
          {{ forecastViews[selectedForecastView] }}
        </h3>
      </div>
      <div class="number-cards-forecast-title-option">
        {{ $tf("projectPage.card.forecastLabel|Forecast:") }}
        <b-select v-model="selectedForecastView">
          <option :disabled="hideUnavailableForecastType" value="trend">
            {{ $tf("projectPage.cards.trendForecast|Trendtartó Forecast") }}
          </option>
          <option :disabled="hideUnavailableForecastType" value="plan">
            {{ $tf("projectPage.cards.planForecast|Tervtartó Forecast") }}
          </option>
          <option value="ralloc">
            {{
              $tf("projectPage.cards.resourceAllocation|Erőforrás allokáció")
            }}
          </option>
          <option disabled value="ai">
            {{ $tf("projectPage.cards.AIForecast|AI (soon)") }}
          </option>
        </b-select>
      </div>
    </div>

    <div class="number-cards">
      <information-card class="number-cards-card" :loading="loading">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.forecast.profit|Várható profit") }}
          </div>
          <div class="card-value">
            {{ forecastedProfit }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ forecastedDiffPercentProfit }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.forecast.profit.planned|Tervezett") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">
            <template v-if="projectData.internal">
              {{ moneyify(0) }}
            </template>
            <template v-else>
              {{ moneyify(profit) }}
            </template>
          </div>
        </template>
      </information-card>

      <information-card class="number-cards-card" :loading="loading">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.forecast.spent|Költés Forecast") }}
          </div>
          <div class="card-value">
            {{ forecastedBudget }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ forecastedDiffPercentBudget }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.forecast.profit.riskKPI|Risk KPI") }}
          </div>
        </template>
        <template #card-lower-right>
          <div v-if="!loading" class="card-right-value">
            <RiskKPITag
              :value="forecastedBudgetRisk"
              class="card-right-value-kpi-tag"
            />
          </div>
        </template>
      </information-card>
      <information-card class="number-cards-card" :loading="loading">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.forecast.mwd|Erőforrás Forecast") }}
          </div>
          <div class="card-value">
            {{ forecastedCommitment }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ forecastedDiffPercentMd }}
          </div>
        </template>
        <template #card-lower-left>
          <div class="card-additional-information">
            {{ $tf("projectPage.cards.forecast.mwd.planned|Terv szerint") }}
          </div>
        </template>
        <template #card-lower-right>
          <div class="card-right-value">{{ workdayify(commitment) }}</div>
        </template>
      </information-card>
      <information-card class="number-cards-card" :loading="loading">
        <template #card-upper-left>
          <div class="card-title">
            {{ $tf("projectPage.cards.forecast.dueDate|Határidő Forecast") }}
          </div>
          <div class="card-value">
            {{ forecastedDueDate }}
          </div>
        </template>
        <template #card-upper-right>
          <div class="card-right-information">
            {{ forecastedDiffTime }}
            {{ $tf("projectPage.cards.forecast.day|nap") }}
          </div>
        </template>
      </information-card>
    </div>
  </div>
</template>

<script>
import {
  formatDateTime,
  formatDate,
  moneyify,
  percentify,
  workdayify,
  deepCopy,
  roundToTwoDecimals,
  numberify,
} from "@/utils/util";

import { mapGetters } from "vuex";
// import KpiBox from "@/components/module/info/KpiBox.vue";
import KpiMixin from "@/mixins/KpiMixin";
import ForecastReadinessChart from "@/components/enterprise/project/module/forecast/ForecastReadinessChart.vue";
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import {
  PROJECT_PROGRESS_TYPE,
  PROJECT_PROGRESS_TYPE_TOKEN,
} from "@/utils/const";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import InformationCard from "@/components/InformationCard.vue";
import progressHistory from "../project/journal/ProgressHistory.vue";
import RiskKpiTag from "../project/module/RiskKPITag.vue";
import network from "@/utils/network";

export default {
  name: "NormalProjectNumberCards",
  components: {
    RiskKPITag: RiskKpiTag,
    ClickableIcon,
    MoneyInput,
    ForecastReadinessChart,
    // KpiBox,
    InformationCard,
  },
  mixins: [KpiMixin],
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.hideUnavailableForecastType) this.selectedForecastView = "ralloc";
  },
  data() {
    return {
      progressToken: PROJECT_PROGRESS_TYPE_TOKEN,
      progressTypes: PROJECT_PROGRESS_TYPE,
      numberify,
      showReadinessModal: false,
      progressEdit: {
        progressType: this.projectDetails.progressType,
        progress: this.projectDetails.readiness * 100,
        summary: "",
      },
      selectedForecastView: "trend",
      forecastViews: {
        trend: this.$tf("projectPage.cards.trendForecast|Trendtartó Forecast"),
        plan: this.$tf("projectPage.cards.planForecast|Tervtartó Forecast"),
        ralloc: this.$tf(
          "projectPage.cards.resourceAllocation|Erőforrás allokáció"
        ),
        ai: this.$tf("projectPage.cards.AIForecast|AI (soon)"),
      },
      resourceForecast: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      allEmployees: "employee/employees",
      riskKPILimits: "uiConfigStore/getRiskKPILimits",
    }),

    hideUnavailableForecastType() {
      return !this.projectDetails?.sumSpentMd;
    },
    progressHistory() {
      return progressHistory;
    },
    sortedProgressHistory() {
      let sorted = deepCopy(this.projectDetails.progressHistory);
      if (!sorted) return [];
      return sorted.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0
      );
    },
    forecastedTrendingEndDate() {
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.projectDetails.forecastTrendingTime
      );
    },
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },
    currentColors() {
      return getCurrentColorSet();
    },
    commitment() {
      return this.projectDetails.commitment;
    },
    trendingCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingMd;
    },
    diffTrendingMd() {
      if (this.notTracked) return "-";
      return this.trendingCommitment - this.commitment;
    },
    diffPercentTrendingMd() {
      if (this.notTracked) return "-";
      return this.diffTrendingMd / this.commitment;
    },
    riskTrendingMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingCommitment / this.commitment);
    },
    planningCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningMd;
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningCommitment - this.commitment;
    },
    diffPercentPlanningMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.commitment;
    },
    riskPlanningMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningCommitment / this.commitment);
    },
    rallocCommitment() {
      if (this.notTracked) return "-";
      return (
        this.resourceForecast?.time + (this.projectDetails?.sumSpentMd || 0)
      );
    },
    diffRallocMd() {
      if (this.notTracked) return "-";
      return this.rallocCommitment - this.commitment;
    },
    diffPercentRallocMd() {
      if (this.notTracked) return "-";
      return this.diffRallocMd / this.commitment;
    },

    budget() {
      return this.projectDetails.budget;
    },
    trendingBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingBudget;
    },
    diffTrendingBudget() {
      if (this.notTracked) return "-";
      return this.trendingBudget - this.budget;
    },
    diffPercentTrendingBudget() {
      if (this.notTracked) return "-";
      return this.diffTrendingBudget / this.budget;
    },
    riskTrendingBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingBudget / this.budget);
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningBudget;
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.budget;
    },
    diffPercentPlanningBudget() {
      if (this.notTracked) return "-";
      return this.diffPlanningBudget / this.budget;
    },
    riskPlanningBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningBudget / this.budget);
    },
    rallocBudget() {
      if (this.loading) return "-";
      return (this.resourceForecast?.cost || 0) + this.projectDetails.spent;
    },
    diffRallocBudget() {
      if (this.notTracked) return "-";
      return this.rallocBudget - this.budget;
    },
    diffPercentRallocBudget() {
      if (this.notTracked) return "-";
      return this.diffRallocBudget / this.budget;
    },
    riskRallocBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.rallocBudget / this.budget);
    },

    time() {
      return this.projectDetails.daysBetweenDue;
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingTime;
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      return this.trendingTime - this.time;
    },
    diffPercentTrendingTime() {
      if (this.notTracked) return "-";
      return this.diffTrendingTime / this.time;
    },
    riskTrendingTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingTime / this.time);
    },
    planningTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningTime;
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      return this.planningTime - this.time;
    },
    diffPercentPlanningTime() {
      if (this.notTracked) return "-";
      return this.diffPlanningTime / this.time;
    },
    riskPlanningTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningTime / this.time);
    },
    rallocTime() {
      if (this.notTracked) return "-";
      return this.resourceForecast?.daysBetweenDue || 0;
    },
    diffRallocTime() {
      if (this.notTracked) return "-";
      return this.rallocTime - this.time;
    },

    dueDate() {
      return new Date(this.projectDetails.dueDate);
    },
    trendingDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.trendingTime
      );
    },
    planningDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.planningTime
      );
    },
    rallocDueDate() {
      if (this.notTracked || !this.resourceForecast?.endDate) return "-";
      return new Date(this.resourceForecast.endDate);
    },

    income() {
      return this.projectDetails.expectedIncome;
    },
    trendingIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffTrendingIncome() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.income;
    },
    diffPercentTrendingIncome() {
      if (this.notTracked) return "-";
      return this.diffTrendingIncome / this.income;
    },
    planningIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffPlanningIncome() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.income;
    },
    diffPercentPlanningIncome() {
      if (this.notTracked) return "-";
      return this.diffPlanningIncome / this.income;
    },
    rallocIncome() {
      return this.income;
    },

    profit() {
      return this.income - this.budget;
    },
    trendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.trendingBudget;
    },
    diffTrendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingProfit - this.profit;
    },
    diffPercentTrendingProfit() {
      if (this.notTracked) return "-";
      return this.diffTrendingProfit / this.profit;
    },
    planningProfit() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.planningBudget;
    },
    diffPlanningProfit() {
      if (this.notTracked) return "-";
      return this.planningProfit - this.profit;
    },
    diffPercentPlanningProfit() {
      if (this.notTracked) return "-";
      return this.diffPlanningProfit / this.profit;
    },
    rallocProfit() {
      if (this.notTracked) return "-";
      return this.rallocIncome - this.rallocBudget;
    },
    diffRallocProfit() {
      if (this.notTracked) return "-";
      return this.rallocProfit - this.income;
    },
    diffPercentRallocProfit() {
      if (this.notTracked) return "-";
      return this.diffRallocProfit / this.profit;
    },

    profitPercent() {
      return this.profit / this.income;
    },
    trendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfit / this.trendingIncome;
    },
    diffTrendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfit / this.profit - 1;
    },
    planningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfit / this.planningIncome;
    },
    diffPlanningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfit / this.profit - 1;
    },
    rallocProfitPercent() {
      if (this.notTracked) return "-";
      return this.rallocProfit / this.rallocIncome;
    },
    diffRallocProfitPercent() {
      if (this.notTracked) return "-";
      return this.rallocProfit / this.profit - 1;
    },

    forecastedBudget() {
      switch (this.selectedForecastView) {
        case "trend":
          return moneyify(this.trendingBudget);
        case "plan":
          return moneyify(this.planningBudget);
        case "ralloc":
          return moneyify(this.rallocBudget);
      }
      return 0;
    },
    forecastedDiffPercentBudget() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return percentify(this.diffPercentTrendingBudget, true);
        case "plan":
          return percentify(this.diffPercentPlanningBudget, true);
        case "ralloc":
          return percentify(this.diffPercentRallocBudget, true);
      }
      return 0;
    },
    forecastedBudgetRisk() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return this.riskTrendingBudget;
        case "plan":
          return this.riskPlanningBudget;
        case "ralloc":
          return this.riskRallocBudget;
      }
      return 0;
    },
    forecastedProfit() {
      if (this.loading || this.projectData?.internal) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return moneyify(this.trendingProfit);
        case "plan":
          return moneyify(this.planningProfit);
        case "ralloc":
          return moneyify(this.rallocProfit);
      }
      return 0;
    },
    forecastedDiffPercentProfit() {
      if (this.loading || this.projectData?.internal) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return percentify(this.diffTrendingProfitPercent, true);
        case "plan":
          return percentify(this.diffPlanningProfitPercent, true);
        case "ralloc":
          return percentify(this.diffRallocProfitPercent, true);
      }
      return 0;
    },
    forecastedCommitment() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return workdayify(this.trendingCommitment);
        case "plan":
          return workdayify(this.planningCommitment);
        case "ralloc":
          return workdayify(this.rallocCommitment);
      }
      return 0;
    },
    forecastedDiffPercentMd() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return percentify(this.diffPercentTrendingMd, true);
        case "plan":
          return percentify(this.diffPercentPlanningMd, true);
        case "ralloc":
          return percentify(this.diffPercentRallocMd, true);
      }
      return 0;
    },
    forecastedDueDate() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return formatDate(this.trendingDueDate);
        case "plan":
          return formatDate(this.planningDueDate);
        case "ralloc":
          return formatDate(this.rallocDueDate);
      }
      return 0;
    },
    forecastedDiffTime() {
      if (this.loading) return "-";
      switch (this.selectedForecastView) {
        case "trend":
          return numberify(this.diffTrendingTime, true);
        case "plan":
          return numberify(this.diffPlanningTime, true);
        case "ralloc":
          return numberify(this.diffRallocTime, true);
      }
      return 0;
    },
  },
  watch: {
    selectedForecastView(val) {
      if (val === "ralloc") {
        this.fetchProjectResourceForecast();
      }
    },
  },
  methods: {
    workdayify,
    moneyify,
    formatDateTime,
    percentify,
    formatDate,
    getEmployeeNameById(employeeId) {
      return this.allEmployees.find((e) => e.id === employeeId)?.name;
    },
    async fetchProjectResourceForecast() {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set("project", this.projectData?.id);
      this.loading = true;
      const axios = network.createAxiosInstance();
      const { data } = await axios.get(
        `/enterprise/project/resource/forecast?${urlSearchParams.toString()}`
      );
      this.resourceForecast = data;
      this.loading = false;
    },
    async saveProgress() {
      let request = deepCopy(this.progressEdit);
      request.progress = request.progress / 100;
      await this.$store.dispatch("enterprise_projects/progressChange", {
        identifier: this.projectDetails.identifier,
        body: request,
      });
      await this.$store.dispatch("enterprise_core/fetchProjectDetails", {
        projectIdentifier: this.projectDetails.identifier,
        force: true,
      });
      this.showReadinessModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.leading-title {
  padding-top: 30px;
}

.forecast-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.card {
  &-title {
    font-size: 12px;
    line-height: 16px;
    color: $custom-dark-blue;
    font-weight: 500;
  }

  &-value {
    font-size: 26px;
    line-height: 30px;
    color: $custom-dark-blue;
    font-weight: 700;
    padding-bottom: 6px;
    padding-top: 4px;
  }

  &-additional-information {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $custom-grayer-blue;
  }

  &-right-information {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $custom-grayer-blue;
    padding-top: 34px;
  }

  &-right-information-with-icon {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $custom-grayer-blue;
  }

  &-right-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $custom-dark-blue;

    &-kpi-tag {
      margin-left: auto;
    }
  }
}

.number-cards {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 10px;

  &-forecast-title {
    display: flex;
    flex-direction: row;
    padding-top: 10px;

    .control {
      display: inline-block;
      transform: translateY(-8px);
      margin-left: 8px;
    }

    &-selected,
    &-options {
      flex: 1 0 50%;
    }
  }

  &-card {
    flex: 1 0 24%;
  }
}
</style>
