<template>
  <div>
    <b-modal
      v-model="isModalOpen"
      :destroy-on-hide="false"
      aria-modal
      aria-role="dialog"
      has-modal-card
      render-on-mounted
      @close="handleModalClose"
    >
      <div class="card p-4">
        <b-field
          :label="$tf('logging.calendar.modal.task|Feladat')"
          class="mb-2"
          label-position="on-border"
        >
          <b-autocomplete
            ref="autocomplete"
            v-model="taskSearch"
            :custom-formatter="issueFormatter"
            :data="filteredIssues"
            :disabled="editedEvent.worklogId"
            :loading="isFetchingTasks"
            :placeholder="
              $tf(
                'logging.calendar.modal.task.placeholder|Min dolgoztál? (Ticket ID, név, projekt)'
              )
            "
            class="has-blue-icon"
            clearable
            icon="search"
            rounded
            @select="(option) => (editedEvent.issue = option)"
          >
            <template v-slot:empty>
              <template v-if="taskSearch?.length > 2 && !isFetchingTasks">
                {{ $tf("logging.calendar.modal.task.empty|Nincs találat") }}
              </template>
              <template v-else-if="isFetchingTasks">
                {{ $tf("logging.calendar.modal.task.loading|Betöltés...") }}
              </template>
              <template v-else>
                {{
                  $tf(
                    "logging.calendar.modal.task.notEnoughCharacters|Gépelj legalább 3 karaktert a kereséshez"
                  )
                }}
              </template>
            </template>
          </b-autocomplete>
        </b-field>
        <div
          v-if="recommendedIssues?.length > 0 && !editedEvent.worklogId"
          class="columns m-0"
        >
          <div class="column is-narrow px-0 py-0">
            {{
              $tf("logging.calendar.modal.recommendedIssues|Ajánlott jegyek:")
            }}
          </div>
          <div
            v-for="issue in recommendedIssues"
            :key="issue.id"
            class="column py-0 pr-0"
          >
            <b-button expanded @click="selectIssue(issue)"
              >{{ issue.key }}
            </b-button>
          </div>
        </div>
        <div class="is-inline-flex mt-2">
          <b-field class="mb-0">
            <b-timepicker
              v-model="editedEvent.start"
              :max-time="new Date(editedEvent.end?.valueOf() - 60000)"
              icon="clock"
              rounded
            >
            </b-timepicker>
          </b-field>
          <b-icon class="mx-2 my-auto" icon="arrow-right"></b-icon>
          <b-field class="mb-0">
            <b-timepicker
              v-model="editedEvent.end"
              :min-time="new Date(editedEvent.start?.valueOf() + 60000)"
              icon="clock"
              rounded
            >
            </b-timepicker>
          </b-field>
        </div>
        <div class="mt-2 columns mx-0">
          <div class="column p-0 has-font-weight-700 is-inline-flex">
            <div class="my-auto is-inline-flex">
              <b-icon icon="hourglass-half"></b-icon>
              <div class="my-auto">
                {{ secondsToJiraTime(eventDuration(editedEvent)) }}
              </div>
            </div>
          </div>
          <div class="column p-0 is-narrow">
            <b-button
              :disabled="editedEvent.saved"
              :loading="savingWorklog"
              class="ml-auto"
              type="is-primary"
              @click="saveLog"
            >
              {{ $tf("logging.calendar.modal.save|Munka rögzítése") }}
            </b-button>
          </div>
        </div>
        <b-field
          :label="$tf('logging.calendar.modal.comment|Megjegyzés (opcionális)')"
          class="mt-2"
          label-position="on-border"
        >
          <b-input
            v-model="editedEvent.comment"
            max-length="32767"
            type="textarea"
          ></b-input>
        </b-field>
      </div>
    </b-modal>
    <div>
      <div class="is-flex is-justify-content-center mb-2">
        <b-button
          class="ml-auto"
          icon-left="chevron-left"
          style="height: 48px"
          @click="previousWeek"
        >
        </b-button>
        <div>
          <b-dropdown
            v-model="selectedWeek"
            append-to-body
            aria-role="menu"
            expanded
            max-height="200"
            scrollable
          >
            <template #trigger>
              <b-button class="mx-2" style="height: 48px">
                <div>
                  {{
                    $tf("logging.calendar.weekNumber|{weekNumber}. hét", {
                      weekNumber: selectedWeek?.number,
                    })
                  }}
                </div>
                <div>
                  {{
                    `${formatDate(selectedWeek?.start)} - ${formatDate(
                      selectedWeek?.end
                    )}`
                  }}
                </div>
              </b-button>
            </template>

            <b-dropdown-item aria-role="listitem" custom>
              <div class="is-inline-flex">
                <b-button icon-left="chevron-left" @click="selectedYear--">
                </b-button>
                <b-input
                  v-model="selectedYear"
                  custom-class="has-text-centered"
                  disabled
                  expanded
                ></b-input>
                <b-button icon-left="chevron-right" @click="selectedYear++">
                </b-button>
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-for="week of weeksInYear(this.selectedYear)"
              :key="week.number"
              :value="week"
              aria-role="listitem"
            >
              <div class="has-text-centered">
                {{
                  $tf("logging.calendar.weekNumber|{weekNumber}. hét", {
                    weekNumber: week.number,
                  })
                }}
              </div>
              <div class="has-text-centered">
                {{ `${formatDate(week.start)}-${formatDate(week.end)}` }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-button
          class="mr-auto"
          icon-left="chevron-right"
          style="height: 48px"
          @click="nextWeek"
        >
        </b-button>
      </div>
      <div
        style="
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(100%);
        "
      >
        <b-field>
          <b-checkbox v-model="showWeekends"
            >{{ $tf("logging.calendar.showWeekends|Hétvége mutatása") }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div>
      <vue-cal
        ref="calendar"
        :disable-views="['day']"
        :drag-to-create-threshold="15"
        :editable-events="{
          title: false,
          drag: true,
          resize: true,
          delete: true,
          create: true,
        }"
        :events="events ?? []"
        :hide-weekends="!showWeekends"
        :locale="calendarLocale"
        :on-event-click="openModalForEvent"
        :selected-date="now"
        :snap-to-time="15"
        :specialHours="{
          1: { from: 9 * 60, to: 17 * 60, class: 'business-hours' },
          2: { from: 9 * 60, to: 17 * 60, class: 'business-hours' },
          3: { from: 9 * 60, to: 17 * 60, class: 'business-hours' },
          4: { from: 9 * 60, to: 17 * 60, class: 'business-hours' },
          5: { from: 9 * 60, to: 17 * 60, class: 'business-hours' },
        }"
        :time-from="4 * 60"
        :time-to="21 * 60"
        active-view="week"
        class="vuecal--full-height-delete"
        hide-title-bar
        hide-view-selector
        @event-drag-create="onEventCreate($event)"
        @event-duration-change="onEventChange($event, 'end')"
        @event-drop="onEventChange($event, 'start')"
        @event-delete="deleteEvent($event)"
      >
        <template #no-event>
          <span></span>
        </template>
        <template #weekday-heading="{ heading }">
          <div class="block mb-2" style="width: 12rem">
            <div class="has-font-weight-700 is-inline-flex">
              <div
                v-if="
                  (loggedHours[formatDate(heading.date)] ?? 1) === 0 &&
                  (loggingForDate(formatDate(heading.date)) ?? -1) === 0
                "
                style="max-height: 2rem; max-width: 2rem; margin-right: 0.25rem"
              >
                <holiday-beach />
              </div>
              <span>{{ heading.label }}</span>
            </div>
            <div class="has-text-grey" style="font-size: small">
              {{ formatDate(heading.date) }}
            </div>
            <div
              v-if="
                loggingForDate(formatDate(heading.date)) !== 0 ||
                loggedHours[formatDate(heading.date)] !== 0
              "
            >
              <b-progress
                :max="loggingForDate(formatDate(heading.date))"
                :type="resolveProgressType(heading.date)"
                :value="loggedHours[formatDate(heading.date)] ?? 0"
                show-value
                >{{
                  $tf(
                    "logging.calendar.loggedTime|Napra logolt idő: {logged}h / {required}h",
                    {
                      logged: loggedHours[formatDate(heading.date)] ?? 0,
                      required: loggingForDate(formatDate(heading.date)) ?? 0,
                    }
                  )
                }}
              </b-progress>
            </div>
          </div>
        </template>
        <template #event="{ event }">
          <span v-if="event.issue">
            <span class="has-font-weight-700">{{ event.issue.key }}</span> -
            <span>{{ event.issue.name }}</span>
            <br />
          </span>
          <span class="has-font-weight-700">{{
            `${formatTime(event.start)} - ${formatTime(event.end)}`
          }}</span>
        </template>
        <template #delete></template>
      </vue-cal>
      <b-loading v-model="loading" :is-full-page="false"></b-loading>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import {
  formatDate,
  localFetch,
  roundToTwoDecimals,
  secondsToJiraTime,
  weeksInYear,
} from "@/utils/util";
import { mapGetters } from "vuex";
import HolidayBeach from "@/components/timesheet/tab/tile/HolidayBeach.vue";
import calendarLocale from "@/mixins/calendarLocale";

export default {
  name: "LoggingCalendar",
  components: { HolidayBeach, VueCal },
  mixins: [calendarLocale],
  data() {
    return {
      formatDate,
      now: new Date(),
      events: [],
      selectedYear: new Date().getFullYear(),
      selectedWeek: null,
      showWeekends: false,
      storedLocale: localFetch("stored_locale") ?? "hu",
      isModalOpen: false,
      taskSearch: null,
      editedEvent: {},
      eventCounter: 0,
      savingWorklog: false,
      loading: false,
      isFetchingTasks: false,
    };
  },
  computed: {
    filteredIssues() {
      return (
        this.searchedIssues(this.taskSearch?.substring(0, 3))?.filter(
          (issue) => {
            if (
              !this.taskSearch ||
              !issue.name ||
              !issue.projectName ||
              !issue.key
            )
              return false;
            return (
              issue.name
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0 ||
              issue.projectName
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0 ||
              issue.key
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0
            );
          }
        ) ?? []
      );
    },
    loggedHours() {
      let hours = {};
      this.events.forEach((event) => {
        const formattedDate = formatDate(event.start);
        return (hours[formattedDate] = hours[formattedDate]
          ? roundToTwoDecimals(
              hours[formattedDate] + this.eventDuration(event) / 3600
            )
          : roundToTwoDecimals(this.eventDuration(event) / 3600));
      });
      return hours;
    },
    ...mapGetters({
      recommendedIssues: "enterprise_core/recommendedIssues",
      searchedIssues: "enterprise_core/filteredIssues",
      worklogs: "worklog/worklogs",
      loggingRequired: "worklog/myLoggingRequired",
    }),
  },
  methods: {
    weeksInYear,
    secondsToJiraTime,
    loggingForDate(date) {
      return this.loggingRequired?.find((day) => day.day === date)?.hours;
    },
    async previousWeek() {
      if (this.selectedWeek.number > 1) {
        this.selectedWeek = weeksInYear(this.selectedYear)[
          this.selectedWeek.number - 2
        ];
      }
    },
    async nextWeek() {
      if (this.selectedWeek.number < weeksInYear(this.selectedYear).length) {
        this.selectedWeek = weeksInYear(this.selectedYear)[
          this.selectedWeek.number
        ];
      }
    },
    eventDuration(event) {
      return (event.end - event.start) / 1000;
    },
    resolveProgressType(date) {
      const formattedDate = formatDate(date);
      if (
        this.loggedHours[formattedDate] <
        this.loggingForDate(formattedDate) / 2
      ) {
        return "is-danger";
      } else if (
        this.loggedHours[formattedDate] < this.loggingForDate(formattedDate)
      ) {
        return "is-warning";
      }
      return "is-success";
    },
    onEventCreate(event) {
      event.tempId = this.eventCounter++;
      event.class = "unsaved";
      event.saved = false;
      this.events.push(event);
      this.openModalForEvent(event);
    },
    onEventChange(event, field) {
      if (event.event[field] !== event.oldDate) {
        this.events = this.events.map((e) => {
          if (e.tempId === event.event.tempId) {
            e = event.event;
            e.class = "unsaved";
            e.saved = false;
          }
          return e;
        });
      }
    },
    openModalForEvent(event) {
      this.editedEvent = event;
      this.$refs.autocomplete.setSelected(this.editedEvent.issue);
      this.isModalOpen = true;
    },
    handleModalClose() {
      if (this.editedEvent.changed) {
        this.$buefy.dialog.confirm({
          title: this.$tf(
            "logging.calendar.modal.closeConfirm.title|Logolás visszavonása"
          ),
          message: this.$tf(
            "logging.calendar.modal.closeConfirm.message|A beírt adatok nem kerültek mentésre, biztos szeretnél kilépni?"
          ),
          confirmText: this.$tf(
            "logging.calendar.modal.closeConfirm.confirm|Kilépés"
          ),
          cancelText: this.$tf(
            "logging.calendar.modal.closeConfirm.cancel|Mégse"
          ),
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.cancelEvent(),
          onCancel: () => {
            this.isModalOpen = true;
          },
        });
      } else {
        this.cancelEvent();
      }
    },
    issueFormatter(issue) {
      if (issue.key && issue.name) {
        return `${issue.key} - ${issue.name}`;
      }
      return issue.key;
    },
    cancelEvent() {
      if (!this.editedEvent.worklogId) {
        this.events = this.events.filter(
          (event) => event.tempId !== this.editedEvent.tempId
        );
      }
      this.resetModal();
    },
    selectIssue(issue) {
      this.editedEvent.issue = issue;
      this.$refs.autocomplete.setSelected(issue);
    },
    resetModal() {
      this.editedEvent = {};
      this.$refs.autocomplete.setSelected(null);
      this.taskSearch = null;
    },
    formatTime(date) {
      return date
        ? `${date.getHours()}:${
            date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes()
          }`
        : "0:00";
    },
    async saveLog() {
      if (this.editedEvent.issue) {
        this.savingWorklog = true;
        if (this.editedEvent.worklogId) {
          await this.$store.dispatch("worklog/editWorklog", {
            externalId: this.editedEvent.worklogId,
            issueKey: this.editedEvent.issue.key,
            issueId: this.editedEvent.issue.id,
            timeSpentSeconds: this.eventDuration(this.editedEvent),
            comment: this.editedEvent.comment,
            started: this.editedEvent.start,
          });
        } else {
          const response = await this.$store.dispatch("worklog/postWorklog", {
            issueKey: this.editedEvent.issue.key,
            issueId: this.editedEvent.issue.id,
            timeSpentSeconds: this.eventDuration(this.editedEvent),
            comment: this.editedEvent.comment,
            started: this.editedEvent.start,
          });
          this.editedEvent.worklogId = response.externalId;
        }
        await this.$store.dispatch("enterprise_core/fetchRecommendedIssues");
        let event = this.events.find(
          (e) => e.tempId === this.editedEvent.tempId
        );
        event.class = "saved";
        event.saved = true;
        event.worklogId = this.editedEvent.worklogId;
        this.savingWorklog = false;
        this.isModalOpen = false;
        this.resetModal();
      } else {
        this.$buefy.toast.open({
          message: this.$tf(
            "logging.calendar.modal.noIssue|Nincs jegy kiválasztva"
          ),
          type: "is-danger",
        });
      }
    },
    async deleteEvent(event) {
      await this.$store.dispatch("worklog/deleteWorklog", event.worklogId);
      await this.$store.dispatch("worklog/fetchWorklogs", {
        from: formatDate(this.selectedWeek.start),
        to: formatDate(this.selectedWeek.end),
        force: true,
      });
      this.events = this.events.filter((e) => e.tempId !== event.tempId);
    },
    async fetchWeeklyWorklogs(week) {
      let alreadyFetched = this.worklogs(formatDate(week.start));
      await this.$store.dispatch("worklog/fetchWorklogs", {
        from: formatDate(week.start),
        to: formatDate(week.end),
        force: false,
      });
      if (!alreadyFetched) {
        this.addToCalendar(week.start);
      }
    },
    addToCalendar(weekStart) {
      this.worklogs(formatDate(weekStart))?.forEach((worklog) => {
        this.events.push({
          start: new Date(worklog.date),
          end: new Date(
            Date.parse(worklog.date) + parseInt(worklog.timeSpentSeconds) * 1000
          ),
          tempId: this.eventCounter++,
          issue: {
            id: worklog.issueId,
            key: worklog.issueKey,
            name: worklog.issueName,
          },
          class: "saved",
          saved: true,
          worklogId: worklog.worklogId,
        });
      });
    },
    updateField(field, oldVal, newVal) {
      if (newVal) {
        let event = this.events.find(
          (e) => e.tempId === this.editedEvent.tempId
        );
        if (oldVal && oldVal !== newVal) {
          event.class = "unsaved";
          event.saved = false;
          this.editedEvent.changed = true;
        }
        event[field] = newVal;
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.selectedWeek = weeksInYear(this.selectedYear).find((week) => {
      return week.start <= this.now && week.end >= this.now;
    });
    await this.$store.dispatch("enterprise_core/fetchRecommendedIssues");
    await this.$store.dispatch("worklog/fetchMyLoggingRequired", {
      from: formatDate(this.selectedWeek.start),
      to: formatDate(this.selectedWeek.end),
    });
    this.loading = false;
  },
  watch: {
    async selectedWeek(newVal) {
      this.$refs.calendar.switchView("week", newVal.start);
      this.loading = true;
      await this.fetchWeeklyWorklogs(newVal);
      await this.$store.dispatch("worklog/fetchMyLoggingRequired", {
        from: formatDate(this.selectedWeek.start),
        to: formatDate(this.selectedWeek.end),
      });
      this.loading = false;
    },
    async taskSearch(newVal) {
      if (newVal?.length === 3) this.isFetchingTasks = true;
      await this.$store.dispatch("enterprise_core/searchIssues", newVal);
      this.isFetchingTasks = false;
    },
    "editedEvent.start": function (newVal, oldVal) {
      this.updateField("start", oldVal, newVal);
    },
    "editedEvent.end": function (newVal, oldVal) {
      this.updateField("end", oldVal, newVal);
    },
    "editedEvent.comment": function (newVal, oldVal) {
      this.updateField("comment", oldVal, newVal);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.business-hours {
  background-color: rgba(128, 128, 128, 0.15) !important;
}

.saved {
  background-color: $primary;
  color: white !important;
  border-radius: 8px;
}

.unsaved {
  background-color: rgb(88, 200, 199);
  color: white !important;
  border-radius: 8px;
}

.vuecal__heading {
  height: 4em;
}
</style>
