<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("sites.title|Helyszínek") }}</h1>
      <h2 class="subtitle">
        {{ $tf("sites.subtitle|Helyszínek létrehozása, szerkesztése") }}
      </h2>

      <div class="button-container">
        <b-button
          type="is-info"
          class="is-pulled-right"
          @click="openEditPopup(null)"
          icon-left="plus-circle"
          >{{ $tf("sites.add|Új helyszín") }}
        </b-button>
      </div>
    </div>

    <div class="container">
      <b-table
        :data="!!sites ? sites : []"
        ref="table"
        hoverable
        paginated
        per-page="15"
        :show-detail-icon="true"
      >
        <b-table-column
          field="color"
          :label="$tf('sites.table.color|Szín')"
          v-slot="props"
        >
          <div class="color">
            <div class="color-box" :style="style(props.row.color)"></div>
            {{ props.row.color }}
          </div>
        </b-table-column>

        <b-table-column
          field="identifier"
          :label="$tf('sites.table.identifier|Azonosító')"
          v-slot="props"
          sortable
          searchable
        >
          {{ props.row.identifier }}
        </b-table-column>

        <b-table-column
          field="sign"
          :label="$tf('sites.table.sign|Jel')"
          v-slot="props"
          sortable
        >
          {{ props.row.sign }}
        </b-table-column>

        <b-table-column
          field="selectable"
          :label="$tf('sites.table.selectable|Választható')"
          v-slot="props"
          sortable
        >
          {{
            props.row.selectable
              ? $tf("sites.table.selectable.yes|Igen")
              : $tf("sites.table.selectable.no|Nem")
          }}
        </b-table-column>

        <b-table-column
          field="site?.indentifier"
          :label="$tf('sites.table.connection|Kapcsolat')"
          v-slot="props"
        >
          <template v-if="props.row.site">
            {{ props.row.site.identifier }}
          </template>
          <template v-else>
            {{ $tf("sites.table.connection.empty|Nincs") }}
          </template>
        </b-table-column>

        <b-table-column
          centered
          field="isDefault"
          :label="$tf('sites.table.isDefault|Alapértelmezett')"
          v-slot="props"
        >
          <b-checkbox
            class="cursor-arrow"
            :modelValue="props.row.isDefault"
            @click.prevent
          ></b-checkbox>
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$tf('sites.table.actions|Műveletek')"
          centered
          v-slot="props"
          cell-class="do-not-wrap"
          width="32"
        >
          <clickable-icon
            icon="pencil"
            type="is-info"
            class="mr-2"
            @click="openEditPopup(props.row)"
          ></clickable-icon>
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteRow(props.row)"
          ></clickable-icon>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { openDeleteConfirm } from "@/utils/util";
import WorkScheduleSiteForm from "../../../components/census/workschedule/WorkScheduleSiteForm";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "WorkScheduleSiteList",
  components: { ClickableIcon },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchList();
    await this.doFinishLoading();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      sites: "work_schedule/workScheduleSites",
      physicalSites: "census_seating/sites",
    }),
  },
  methods: {
    openEditPopup(row) {
      let site;
      if (row) site = row.site;
      this.$buefy.modal.open({
        parent: this,
        component: WorkScheduleSiteForm,
        props: { workScheduleSite: row, site: site, sites: this.physicalSites },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteRow(row) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch(
          "work_schedule/deleteWorkScheduleSite",
          row.id
        );
        await this.fetchList(true);
      });
    },
    async fetchList(force) {
      await this.$store.dispatch(
        "work_schedule/getWorkScheduleAdminSites",
        force
      );
      // await this.$store.dispatch("census_seating/fetchSites");
    },
    style(color) {
      return (
        "width: 10px; height: 10px;  display: inline-block; background-color:" +
        color
      );
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  display: flex;

  button {
    margin-left: auto;
  }
}

.tab-content {
  padding: 0 !important;
  padding-top: 2rem !important;
}
</style>
