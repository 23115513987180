<template>
  <div>
    <h3 class="title">
      {{
        $tf(
          "externalIssueTargetingForm.title|Vonatkozó jegyek szűrésének konfigurációja"
        )
      }}
    </h3>
    <br />
    <b-field grouped>
      <b-field
        :label="$tf('externalIssueTargetingForm.mode|Célzás módja')"
        label-position="on-border"
      >
        <VuelidatedInput
          v-model="computedValue.mode"
          :validation-rule="v$.modelValue.mode"
          type="select"
          :placeholder="$tf('externalIssueTargetingForm.mode|Célzási mód')"
          icon="swatchbook"
        >
          <template #select>
            <option value="ALL">
              {{
                $tf(
                  "externalIssueTargetingForm.mode.all|Minden, a projekthez tartozó jegy"
                )
              }}
            </option>
            <option value="TASK">
              {{ $tf("externalIssueTargetingForm.mode.task|Egy task") }}
            </option>
            <option
              value="EASY"
              v-if="issueTrackingSystem === ISSUE_TRACKING_SYSTEM.JIRA.enum"
            >
              {{
                $tf(
                  "externalIssueTargetingForm.mode.easy|Egyszerűsített célzás"
                )
              }}
            </option>
            <option value="QUERY">
              {{ $tf("externalIssueTargetingForm.mode.query|Explicit query") }}
            </option>
          </template>
        </VuelidatedInput>
      </b-field>
      <b-field
        :label="
          $tf('externalIssueTargetingForm.easyType|Egyszerűsített módszer')
        "
        label-position="on-border"
        v-if="computedValue.mode === 'EASY'"
      >
        <VuelidatedInput
          v-model="computedValue.easySubMode"
          :validation-rule="v$.modelValue.easySubMode"
          type="select"
          :placeholder="
            $tf('externalIssueTargetingForm.easyType.easySubMode|Módszer')
          "
          icon="swatchbook"
        >
          <template #select>
            <option value="EPIC">
              {{
                $tf(
                  "externalIssueTargetingForm.easyType.easySubMode.epic|Eposzok alapján"
                )
              }}
            </option>
            <option value="COMPONENT_AND_FIX_VERSION">
              {{
                $tf(
                  "externalIssueTargetingForm.easyType.easySubMode.componentAndFixVersion|Komponens / Javítási verzió alapján"
                )
              }}
            </option>
          </template>
        </VuelidatedInput>
      </b-field>
    </b-field>
    <b-field
      :label="$tf('externalIssueTargetingForm.taskType|Jegy azonosító')"
      label-position="on-border"
      v-if="computedValue.mode === 'TASK'"
    >
      <vuelidated-input
        v-model="computedValue.task"
        :validation-rule="v$.modelValue.task"
      />
    </b-field>
    <b-field
      :label="$tf('externalIssueTargetingForm.queryType|Query')"
      label-position="on-border"
      v-if="computedValue.mode === 'QUERY'"
    >
      <vuelidated-input
        v-model="computedValue.query"
        :validation-rule="v$.modelValue.query"
      />
    </b-field>
    <div
      v-if="
        computedValue.mode === 'EASY' && computedValue.easySubMode === 'EPIC'
      "
    >
      <div class="is-flex has-gap-1">
        <h3 class="title">
          {{
            $tf(
              "externalIssueTargetingForm.easyType.setEpics|Vonatkozó eposzok beállítása"
            )
          }}
        </h3>
        <div>
          <b-button size="is-small" @click="startEpicSync">
            {{
              $tf(
                "externalIssueTargetingForm.easyType.syncEpics|Eposzok frissítése"
              )
            }}
          </b-button>
        </div>
      </div>
      <b-field
        :label="$tf('externalIssueTargetingForm.easyType.epics|Eposzok')"
        label-position="on-border"
      >
        <targeting-dropdown
          :items="filteredEpics"
          v-model="computedValue.epics"
          identifier-field="name"
          name-field="summary"
          has-select-all-option
          append-to-body
          scrollable
          searchable
        ></targeting-dropdown>
      </b-field>
      <b-field grouped>
        <b-checkbox v-model="computedValue.onlyProjectEpics" type="is-info"
          >{{
            $tf(
              "externalIssueTargetingForm.easyType.epics.showOnlyFromProject|Csak a projekthez tartozó eposzok megjelenítése"
            )
          }}
        </b-checkbox>
        <b-checkbox v-model="computedValue.onlyProjectIssues" type="is-info"
          >{{
            $tf(
              "externalIssueTargetingForm.easyType.epics.showOnlyIssuesFromProject|Szűrés korlátozása az adott projektre"
            )
          }}
        </b-checkbox>
      </b-field>
    </div>
    <div
      v-if="
        computedValue.mode === 'EASY' &&
        computedValue.easySubMode === 'COMPONENT_AND_FIX_VERSION'
      "
    >
      <div class="is-flex has-gap-1">
        <h3 class="title">
          {{
            $tf(
              "externalIssueTargetingForm.easyType.setComponents|Vonatkozó komponensek és javítási verziók beállítása"
            )
          }}
        </h3>
        <div>
          <b-button size="is-small" @click="startMetadataSync">
            {{
              $tf(
                "externalIssueTargetingForm.easyType.components.sync|Komponensek és javítási verziók frissítése"
              )
            }}
          </b-button>
        </div>
      </div>
      <b-field grouped expanded>
        <b-field
          :label="
            $tf('externalIssueTargetingForm.easyType.components|Komponensek')
          "
          label-position="on-border"
        >
          <targeting-dropdown
            :items="projectMetadata.components"
            v-model="computedValue.components"
            identifier-field="id"
            name-field="name"
            has-select-all-option
            append-to-body
            scrollable
            searchable
          ></targeting-dropdown>
        </b-field>
        <b-field
          :label="
            $tf(
              'externalIssueTargetingForm.easyType.fixVersions|Javítási verziók'
            )
          "
          label-position="on-border"
        >
          <targeting-dropdown
            :items="projectMetadata.fixVersions"
            v-model="computedValue.fixVersions"
            identifier-field="id"
            name-field="name"
            has-select-all-option
            append-to-body
            scrollable
            searchable
          ></targeting-dropdown>
        </b-field>
      </b-field>
    </div>
  </div>
</template>

<script>
import TargetingDropdown from "@/components/targeting/TargetingDropdown.vue";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ExternalIssueTargetingForm",
  components: {
    VuelidatedInput,
    TargetingDropdown,
  },
  setup: () => {
    const {v$, touch$} = useCustomVuelidate();
    return { v$, touch$ }
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.fetch();
    await this.fetchEpics();
    this.$emit("update:modelValue", {
      ...this.modelValue,
      type: ISSUE_TRACKING_SYSTEM[this.issueTrackingSystem].class,
      prefix: this.prefix,
    });
  },
  data() {
    return {
      isLoading: false,
      ISSUE_TRACKING_SYSTEM,
    };
  },
  validations: {
    modelValue: {
      mode: {},
      query: {
        required: requiredIf(function (modelValue) {
          return modelValue.mode === "QUERY";
        }),
      },
      task: {
        required: requiredIf(function (modelValue) {
          return modelValue.mode === "TASK";
        }),
      },
      easySubMode: {
        required: requiredIf(function (modelValue) {
          return modelValue.mode === "EASY";
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      projectMetadataGetter: "enterprise_projects/projectMetadata",
      epics: "enterprise_projects/epics",
    }),
    computedValue() {
      const self = this;
      return new Proxy(this.modelValue, {
        set(target, p, newValue, receiver) {
          self.$emit("update:modelValue", {
            ...target,
            type: ISSUE_TRACKING_SYSTEM[self.issueTrackingSystem].class,
            prefix: self.prefix,
            [p]: newValue,
          });
          return true;
        },
      });
    },
    projectMetadata() {
      return this.projectMetadataGetter &&
        this.projectMetadataGetter(this.projectIdentifier)
        ? this.projectMetadataGetter(this.projectIdentifier)
        : {};
    },
    filteredEpics() {
      if (this.modelValue.onlyProjectEpics) {
        return this.epics.filter((epic) => epic.project === this.prefix);
      }
      return this.epics;
    },
  },
  methods: {
    startLoading() {
      this.$emit("loading", true);
    },
    finishLoading() {
      this.$emit("loading", false);
    },
    async fetch() {
      this.startLoading();
      await this.$store.dispatch("enterprise_projects/fetchProjectMetadata", {
        projectIdentifier: this.projectIdentifier,
        projectType: this.projectType,
      });
      this.finishLoading();
    },
    async startMetadataSync() {
      this.startLoading();
      if (this.prefix) {
        await this.$store.dispatch("enterprise_projects/syncProjectMetadata", {
          prefix: this.prefix,
        });
      }
      await this.fetch();
      this.finishLoading();
    },
    async startEpicSync() {
      this.startLoading();
      await this.$store.dispatch("enterprise_projects/syncEpics");
      await this.fetchEpics();
      this.finishLoading();
    },
    async fetchEpics() {
      this.startLoading();
      await this.$store.dispatch("enterprise_projects/fetchEpics");
      this.finishLoading();
    },
    $invalid() {
      this.touch$();
      return this.v$.$invalid;
    },
  },
};
</script>

<style scoped lang="scss"></style>
