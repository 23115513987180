<template>
  <div class="is-flex is-flex-direction-row employee-row">
    <div class="cell is-flex-3">
      <UserInfo :user="employee" displayMode="long" tooltipPosition="bottom">
        <template #additional-information>
          <indicator-tag :indicator-id="employee?.roleId" />
        </template>
      </UserInfo>
      <p class="future-allocations">
        {{ futureAllocationCost }}
        <b-tooltip position="is-right" type="is-dark">
          <template #default>
            <p class="future-allocations-show-more-label">
              {{ $tf("resource.allocationRow.futureCost.showMore|További ›") }}
            </p>
          </template>
          <template #content>
            <div class="future-allocations-show-more-container">
              <p>
                {{
                  $tf(
                    "resource.allocationRow.futureAllocationCost|Jövőbeli allokáció: {futureAllocationCost}",
                    { futureAllocationCost }
                  )
                }}
              </p>
              <p>
                {{
                  $tf(
                    "resource.allocationRow.pastCost|Eddigi költés: {pastCost} ({pastCostPercentageVsPastAllocation})",
                    { pastCost, pastCostPercentageVsPastAllocation }
                  )
                }}
              </p>
              <p>
                {{
                  $tf(
                    "resource.allocationRow.pastAllocationCost|Eddigi allokáció: {pastAllocationCost}",
                    { pastAllocationCost }
                  )
                }}
              </p>
              <p>
                {{
                  $tf(
                    "resource.allocationRow.resourceForecastCost|Resource forecast költség: {resourceForecastCost}",
                    { resourceForecastCost }
                  )
                }}
              </p>
            </div>
          </template>
        </b-tooltip>
      </p>
    </div>
    <div
      class="cell is-flex-2 allocation-button-container"
      :class="{
        cloning:
          cloningWeek &&
          !disabled &&
          ((hoverWeek &&
            (weekData.year < hoverWeek.year ||
              (weekData.year === hoverWeek.year &&
                weekData.week <= hoverWeek.week)) &&
            (weekData.year < cloningWeek.year ||
              (weekData.year === cloningWeek.year &&
                weekData.week >= cloningWeek.week))) ||
            (weekData.year === cloningWeek.year &&
              weekData.week === cloningWeek.week)),
      }"
      v-for="(weekData, index) of weeks"
      :key="index"
      @mouseenter="hoverWeek = { year: weekData.year, week: weekData.week }"
      @mouseleave="hoverWeek = undefined"
    >
      <div class="pr-2 pl-2 pt-1 pb-1 h-100">
        <div class="is-flex is-align-items-center h-100">
          <div
            @click="clickOnButton(weekData)"
            class="allocation-button"
            :class="[
              allocationButtonClass(weekData),
              {
                disabled:
                  disabled ||
                  (cloningWeek &&
                    (weekData.year < cloningWeek.year ||
                      (weekData.year === cloningWeek.year &&
                        weekData.week < cloningWeek.week))),
              },
            ]"
          >
            <div
              class="is-flex is-align-items-center is-justify-content-center has-gap-1"
            >
              <div v-if="!!RALLOC_PRIORITY[priority(weekData)]?.icon">
                <b-icon
                  :icon="RALLOC_PRIORITY[priority(weekData)]?.icon"
                ></b-icon>
              </div>
              <div>
                <p class="has-font-weight-500 is-size-4">
                  {{ allocatedTime(weekData) }}
                </p>
              </div>
            </div>
            <div class="is-flex has-gap-2 is-align-items-center">
              <div>
                <p class="has-font-weight-500 is-size-6">
                  {{ allocatedTimeAll(weekData) }}
                </p>
              </div>
              <div><p class="is-size-6">/</p></div>
              <div>
                <p class="is-size-8">
                  {{ expectedTime(weekData) }}
                </p>
              </div>
            </div>
            <div class="allocation-button-copy" v-if="!disabled">
              <div
                @click.stop="copyAllocation(weekData)"
                class="ml-1 cursor-pointer allocation-button-copy-icon"
                v-if="!cloningWeek"
              >
                <b-icon class="icon-xl" icon="copy" />
              </div>
              <div
                class="ml-1 cursor-pointer"
                v-else-if="
                  cloningWeek.year === weekData.year &&
                  cloningWeek.week === weekData.week
                "
              >
                <b-icon icon="times-circle" />
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="mt-4 ml-1 mb-2">-->
        <!--          TODO: backend műtét szükséges, nem érkezik ugyanis ilyen adat-->
        <!--          <div class="is-flex">-->
        <!--            <div style="width: 1.35rem">-->
        <!--              <img-->
        <!--                src="/beach.svg"-->
        <!--                :alt="$tf('resource.allocationRow.imageAlt.dayOff|Szabadság')"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <p>: -</p>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AllocationBox from "@/components/ralloc/AllocationBox.vue";
import { RALLOC_PRIORITY } from "@/utils/const";
import UserInfo from "@/components/module/info/UserInfo.vue";
import {
  calendarDayify,
  deepCopy,
  moneyify,
  percentify,
  roundToTwoDecimals,
  workdayify,
} from "../../utils/util";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";

export default defineComponent({
  name: "ResourceAllocationRow",
  components: { IndicatorTag, UserInfo },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    weeks: {
      type: Array,
      required: true,
    },
    allocations: {
      type: Array,
      required: true,
    },
    projectId: {
      type: String,
      Number,
      required: true,
    },
    showAllocatedTimeInHours: {
      type: Boolean,
      required: false,
      default: false,
    },
    costSummary: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      RALLOC_PRIORITY,
      cloningWeek: null,
      modalInstance: null,
      hoverWeek: undefined,
    };
  },
  watch: {
    allocations() {
      if (!this.modalInstance) return;

      const weekData =
        this.modalInstance.modalVNode.props.props.allocationData.weekData;
      this.modalInstance.modalVNode.props.props.availabilityHtml =
        this.availability(weekData);
      this.modalInstance.modalVNode.props.props.allocationsByProject =
        this.allocationsByProject(weekData);
      this.modalInstance.$forceUpdate();
    },
  },
  computed: {
    pastAllocationCost() {
      if (!this.costSummary?.pastAllocations) return "-";
      return `${moneyify(this.costSummary?.pastAllocationCost ?? 0)} (${
        this.pastAllocationDays
      })`;
    },
    pastAllocationDays() {
      return calendarDayify((this.costSummary?.pastAllocations ?? 0) / 8);
    },
    pastCost() {
      if (!this.costSummary?.pastTime) return "-";
      return `${moneyify(this.costSummary.pastCost)} (${calendarDayify(
        this.costSummary.pastTime / (8 * 60 * 60)
      )})`;
    },
    pastCostPercentageVsPastAllocation() {
      const pastAllocationCost = this.costSummary?.pastAllocationCost ?? 0;
      const pastCost = this.costSummary?.pastCost ?? 0;
      return percentify(
        pastAllocationCost === 0
          ? pastCost === 0
            ? 0
            : 1
          : (pastCost - pastAllocationCost) / pastAllocationCost,
        true
      );
    },
    futureAllocationCost() {
      if (!this.costSummary?.futureAllocations) return "-";
      return `${moneyify(this.costSummary?.futureAllocationCost ?? 0)} (${
        this.futureAllocationDays
      })`;
    },
    futureAllocationDays() {
      return calendarDayify((this.costSummary?.futureAllocations ?? 0) / 8);
    },
    resourceForecastCost() {
      const resourceForecastCost =
        (this.costSummary?.pastCost ?? 0) +
        (this.costSummary?.futureAllocationCost ?? 0);
      const resourceForecastDays =
        (this.costSummary?.pastTime ?? 0) / (8 * 60 * 60) +
        (this.costSummary?.futureAllocations ?? 0) / 8;
      if (!resourceForecastCost) return "-";
      return `${moneyify(resourceForecastCost)} (${calendarDayify(
        resourceForecastDays
      )})`;
    },
  },
  methods: {
    moneyify,
    allocationData(weekData) {
      return this.allocations.find(
        (allocation) =>
          allocation.year === weekData.year && allocation.week === weekData.week
      );
    },
    allocatedHoursAll(weekData) {
      return this.allocationData(weekData)?.allocatedHours || 0;
    },
    allocatedTimeAll(weekData) {
      const hours = this.allocatedHoursAll(weekData);
      return this.showAllocatedTimeInHours ? hours : hours / 8;
    },
    allocatedHours(weekData) {
      const allocationData = this.allocationData(weekData);
      const association = allocationData?.associations.find(
        (asc) => asc.projectId === this.projectId
      );

      return association?.hours || 0;
    },
    allocatedTime(weekData) {
      const hours = this.allocatedHours(weekData);
      return this.showAllocatedTimeInHours ? hours : hours / 8;
    },
    expectedHours(weekData) {
      return this.allocationData(weekData)?.expectedHours || 0;
    },
    expectedTime(weekData) {
      const hours = this.expectedHours(weekData);
      return this.showAllocatedTimeInHours ? hours : hours / 8;
    },
    priority(weekData) {
      const allocationData = this.allocationData(weekData);
      const association = allocationData?.associations.find(
        (asc) => asc.projectId === this.projectId
      );

      return association?.priority || RALLOC_PRIORITY.MEDIUM.value;
    },
    availability(weekData) {
      const expectedHours = this.expectedHours(weekData);
      const allocatedHours = this.allocatedHoursAll(weekData);

      let color = "";
      if (allocatedHours === 0) {
        color = "#2F2E6B";
      } else if (allocatedHours === expectedHours) {
        color = "#299218";
      } else if (allocatedHours > expectedHours) {
        color = "#D93333";
      } else {
        color = "#E48228";
      }

      const formattedAllocated = this.showAllocatedTimeInHours
        ? `${allocatedHours} / ${expectedHours} H`
        : `${this.decimalFormat(allocatedHours / 8)} / ${this.decimalFormat(
            expectedHours / 8
          )} MWD`;

      return `<span style="color: ${color}">${formattedAllocated}</span>`;
    },
    formatAllocationTime(timeInHours) {
      return this.showAllocatedTimeInHours
        ? `${timeInHours} H`
        : `${timeInHours / 8} MWD`;
    },
    decimalFormat(num) {
      const rounded = Math.round(num * 10) / 10;
      return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(1);
    },
    allocationsByProject(weekData) {
      const allocationsByProject =
        this.allocationData(weekData)?.associations ?? [];

      return allocationsByProject.sort(
        (a, b) =>
          RALLOC_PRIORITY[b.priority].order - RALLOC_PRIORITY[a.priority].order
      );
    },
    clickOnButton(weekData) {
      if (!this.cloningWeek) {
        if (!this.disabled) this.openAllocationBox(weekData);
        return;
      }

      const { year, week } = weekData;
      const { year: cloneYear, week: cloneWeek } = this.cloningWeek;

      if (year === cloneYear && week === cloneWeek) {
        this.closeCopyMode();
      } else if (cloneYear < year || (cloneYear === year && cloneWeek < week)) {
        this.pasteAllocation(weekData);
      } else if (
        !this.disabled &&
        (cloneYear < year || (cloneYear === year && cloneWeek < week))
      ) {
        this.openAllocationBox(weekData);
      }
    },
    closeCopyMode() {
      this.cloningWeek = null;
      this.$emit("copyEnded");
    },
    openAllocationBox(weekData) {
      this.closeCopyMode();
      const expectedHours = this.expectedHours(weekData);
      const allocatedHours = this.allocatedHours(weekData);
      const allocationsByProject = this.allocationsByProject(weekData);
      const priority = this.priority(weekData);

      this.modalInstance = this.$buefy.modal.open({
        parent: this,
        component: AllocationBox,
        customClass: "allocation-box",
        events: {
          "modify-allocation": async (updatedData) => {
            const allocationRequest = {
              employeeId: this.employee.id,
              year: weekData.year,
              week: weekData.week,
              hours: updatedData.allocatedHours,
              priority: updatedData.priority,
            };

            this.$emit("modifyAllocation", allocationRequest);
          },
        },
        props: {
          allocationData: {
            weekData: weekData,
            priority: priority,
            allocatedHours: allocatedHours,
          },
          showAllocatedTimeInHours: this.showAllocatedTimeInHours,
          expectedHours: expectedHours,
          allocationsByProject: allocationsByProject,
          availabilityHtml: this.availability(weekData),
        },
      });
    },
    copyAllocation(cloningWeek) {
      this.cloningWeek = {
        year: cloningWeek.year,
        week: cloningWeek.week,
        allocatedHours: this.allocatedHours(cloningWeek),
        priority: this.priority(cloningWeek),
      };
      this.$emit("copyStarted");
    },
    async pasteAllocation(targetWeek) {
      if (!this.cloningWeek) return;
      const cloningWeek = deepCopy(this.cloningWeek);
      const targetAllocation = this.allocationData(targetWeek);
      if (targetAllocation) {
        this.$buefy.dialog.confirm({
          title: this.$tf("resource.allocationRow.modal.title|Figyelem!"),
          message: this.$tf(
            "resource.allocationRow.modal.message|A kiválasztatott időszakra már vannak kitöltött allokációk. Szeretnéd ezeket felülírni?"
          ),
          cancelText: this.$tf(
            "resource.allocationRow.modal.cancelText|Megtartás"
          ),
          confirmText: this.$tf(
            "resource.allocationRow.modal.confirmText|Felülírás"
          ),
          type: "is-info",
          onConfirm: async () => {
            await this.emitCloneAllocation(
              cloningWeek,
              targetWeek.year,
              targetWeek.week
            );
            this.closeCopyMode();
          },
        });
      } else {
        await this.emitCloneAllocation(
          cloningWeek,
          targetWeek.year,
          targetWeek.week
        );
        this.closeCopyMode();
      }
      this.closeCopyMode();
    },
    async emitCloneAllocation(cloningWeek, year, week) {
      const allocationRequest = {
        cloningWeek,
        employeeId: this.employee.id,
        year,
        week,
      };

      this.$emit("cloneAllocation", allocationRequest);
    },
    allocationButtonClass(weekData) {
      const allocatedHours = this.allocatedHoursAll(weekData);
      const expectedHours = this.expectedHours(weekData);
      const ratio = allocatedHours / expectedHours || 0;

      if (ratio < 0.2) {
        return "allocation-button-100";
      }
      if (ratio >= 0.2 && ratio < 0.4) {
        return "allocation-button-80";
      }
      if (ratio >= 0.4 && ratio < 0.6) {
        return "allocation-button-60";
      }
      if (ratio >= 0.6 && ratio < 0.8) {
        return "allocation-button-40";
      }
      if (ratio >= 0.8 && ratio < 1) {
        return "allocation-button-20";
      }
      if (ratio > 1) {
        return "allocation-button-error";
      }
      return "";
    },
    priorityCircleClass(priority) {
      if (priority === "LOW") {
        return "circle green";
      }
      if (priority === "MEDIUM") {
        return "circle green";
      }
      if (priority === "HIGH") {
        return "circle red";
      }
    },
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/scss/colors";

.employee-row {
  border-bottom: 0.5px solid $grey-lighter;
  border-top: 0.5px solid $grey-lighter;
  &:hover {
    background-color: $grey-lighter;
  }
  p.future-allocations {
    font-size: 14px;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  p.future-allocations-show-more-label {
    font-size: 12px;
    color: $custom-dark-blue;
    cursor: pointer;
    margin-bottom: 0.25rem;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }
  div.future-allocations-show-more-container {
    p {
      margin-bottom: 0.25rem;
    }
  }
}

.cell {
  border-right: 0.5px solid $grey-lighter;
  &:last-child {
    border: none;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.circle.orange {
  background-color: #edb37e;
}

.circle.red {
  background-color: #d93333;
}

.circle.green {
  background-color: #95e888;
}

.is-rounded-5 {
  border-radius: 5px;
}

.allocation-button-container.cloning .allocation-button {
  color: $always-white !important;
  background: #4a87d2 !important;
}

.allocation-button {
  border: 1px solid $grey-lighter !important;
  color: $custom-dark-blue !important;
  cursor: pointer;
  border-radius: 12px;
  text-align: center !important;
  font-size: 14px !important;
  padding: 0.25rem 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  &.disabled {
    cursor: no-drop;
    filter: brightness(35%);
  }

  &-20 {
    color: $always-black !important;
    background: #def4f4;
  }
  &-40 {
    color: $always-black !important;
    background: #bde9e9;
  }
  &-60 {
    color: $always-black !important;
    background: #9bdddd;
  }
  &-80 {
    color: $always-white !important;
    background: #7ad2d2;
  }
  &-100 {
    color: $always-white !important;
    background: #59c7c7;
  }
  &-error {
    color: $always-black !important;
    background: #e9859a;
  }

  &-copy {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);

    &-icon {
      visibility: hidden;
    }
  }

  &:hover &-copy &-copy-icon {
    visibility: visible;
  }
}
</style>
