import network from "../../../../utils/network";
import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-OverViewStore");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  overview: "/enterprise/report/overview",
  overviewWithMoney: "/enterprise/overview",
  overviewExport: "/enterprise/report/overview/export",
  overviewWithMoneyExport: "/enterprise/overview/export",
};

const initState = () => {
  return {
    overview: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchOverview: async function (
    { commit, dispatch },
    { request, showMoney } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "post",
        showMoney ? urls.overviewWithMoney : urls.overview,
        request,
        false,
        dispatch,
        "Overview betöltése..."
      );
      commit("saveOverview", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  exportOverview: async ({ dispatch }, { request, showMoney } = {}) => {
    let requestId = generateRequestId();
    const url = showMoney ? urls.overviewWithMoneyExport : urls.overviewExport;
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "POST",
        url,
        flair: "Overview exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.post(url, request);

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  },
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveOverview: (state, response) => {
    state.overview = response;
  },
};

const getters = {
  overview: (state) => {
    return state.overview;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
