<template>
  <section class="section">
    <div class="ralloc-page">
      <div class="ralloc-page-tabs-container">
        <h1 class="title ralloc-page-title">
          {{ $tf("utilizationPage.title|Erőforrás menedzsment") }}
        </h1>
        <div class="w-40">
          <b-tabs
            v-model="activeTab"
            type="is-toggle"
            class="toggle-only"
            expanded
          >
            <b-tab-item :value="TABS.PROJECTS">
              <template #header>
                <span class="tab-item">
                  {{ $tf("utilizationPage.tab.projects|Projektek") }}
                </span>
              </template>
            </b-tab-item>
            <b-tab-item :value="TABS.EMPLOYEES">
              <template #header>
                <span class="tab-item">
                  {{ $tf("utilizationPage.tab.employees|Dolgozók") }}
                </span>
              </template>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>

    <div>
      <template v-if="activeTab === TABS.PROJECTS">
        <UtilizationProjects />
      </template>
      <template v-if="activeTab === TABS.EMPLOYEES">
        <UtilizationEmployees />
      </template>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import UtilizationEmployees from "@/components/ralloc/UtilizationEmployees.vue";
import UtilizationProjects from "@/components/ralloc/UtilizationProjects.vue";
import { RALLOC_TABS } from "@/utils/const";
import tabNavigationMixin from "@/mixins/tabNavigationMixin";

export default defineComponent({
  name: "RallocMainPage",
  components: {
    UtilizationProjects,
    UtilizationEmployees,
  },
  mixins: [tabNavigationMixin],
  data() {
    return {
      TABS: RALLOC_TABS,
    };
  },
  methods: {
    getTabConfig() {
      return RALLOC_TABS;
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.tab-item {
  font-weight: 800;
}

.tabs ul {
  border-radius: 12px !important;
}

.ralloc-page {
  padding: 24px 40px 16px;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;

  &-title {
    margin-top: 1em !important;
  }

  &-tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      margin-bottom: 1rem !important;
      background: $white !important;

      li {
        background: $white !important;

        a:not(.tabs.is-toggle li.is-active a) {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>
