<script>
import _ from "lodash";

// curly brace syntax
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export default {
  props: {
    validations: {
      required: true,
      type: Object,
    },
  },
  emits: ["submit"],
  computed: {
    // this would most likely be in a language file
    TEMPLATES_MAP() {
      return {
        alpha: this.$tf("validations.alpha|Nem megfelelő formátum."),
        minLength: this.$tf(
          "validations.minLength|Minimum hossz: {{min}}."
        ),
        maxLength: this.$tf(
          "validations.maxLength|Maximum hossz: {{max}}."
        ),
        betweenHours: this.$tf(
          "validations.betweenHours|A számnak 0 és 24 közt kell lennie."
        ),
        minValue: this.$tf("validations.minValue|Legkisebb érték: {{min}}."),
        maxValue: this.$tf("validations.maxValue|Legnagyobb érték: {{max}}."),
        length: this.$tf(
          "validations.length|Nem megfelelő hossz, vagy formátum."
        ),
        required: this.$tf("validations.required|A mező kitöltése kötelező."),
        requiredIf: this.$tf(
          "validations.requiredIf|A mező kitöltése kötelező."
        ),
        email: this.$tf("validations.email|Nem megfelelő email formátum."),
        numeric: this.$tf(
          "validations.numeric|A mező csak számokat tartalmazhat."
        ),
        decimal: this.$tf(
          "validations.decimal|A mező csak számokat és tizedespontot tartalmazhat."
        ),
        leThanAmount: this.$tf(
          "validations.leThanAmount|Az érték nem lehet nagyobb mint fennmaradó összeg."
        ),
        sameAsPassword: this.$tf(
          "validations.sameAsPassword|A megadott jelszavak nem egyeznek."
        ),
        isJiraTime: this.$tf("validations.isJiraTime|Helytelen formátum."),
      };
    },

    errors() {
      if (!this.invalid) {
        return [];
      }

      return Object.keys(this.validations).reduce((errors, validator) => {
        if (!validator.startsWith("$")) {
          if (this.validations[validator].$invalid) {
            const compiled = _.template(this.TEMPLATES_MAP[validator]);
            const message =
              compiled(this.validations[validator].$params) ||
              this.validations[validator].$message;
            errors.push(message);
          }
        }

        return errors;
      }, []);
    },

    invalid() {
      return this.validations.$dirty && this.validations.$invalid;
    },
  },

  render() {
    return this.$slots.default({
      errors: this.errors,
      invalid: this.invalid,
    });
  },
};
</script>
