import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-ProjectBaseData");

const urls = {
  withType: (type) => `/enterprise/project/base-data/type/${type}`,
  withId: (id) => `/enterprise/project/base-data/${id}`,
  base: "/enterprise/project/base-data",
};

const initState = () => {
  return {
    byType: {},
    byId: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchAll: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.base,
        null,
        true,
        dispatch,
        "projectBaseData.fetchAll.flair|Projekt törzsadatok letöltése",
        null,
        force
      );
      commit("saveAll", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchByType: async function ({ commit, dispatch }, { type, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.withType(type),
        null,
        true,
        dispatch,
        "projectBaseData.fetchByType.flair|Projekt törzsadatok letöltése",
        null,
        force
      );
      commit("saveByType", { type, data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchById: async function ({ commit, dispatch }, { id, force }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.withId(id),
        null,
        true,
        dispatch,
        "projectBaseData.fetchById.flair|Projekt törzsadat letöltése",
        null,
        force
      );
      commit("saveById", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  create: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.base,
        request,
        false,
        dispatch,
        "projectBaseData.create.flair|Projekt törzsadat létrehozása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectBaseData.create.progress|Projekt törzsadat létrehozása folyamatban...",
          fail: "projectBaseData.create.fail|Projekt törzsadat létrehozása sikertelen!",
          success:
            "projectBaseData.create.success|Projekt törzsadat létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  update: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.withId(request.id),
        request,
        false,
        dispatch,
        "projectBaseData.update.flair|Projekt törzsadat mentése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectBaseData.update.progress|Projekt törzsadat mentése folyamatban...",
          fail: "projectBaseData.update.fail|Projekt törzsadat mentése sikertelen!",
          success:
            "projectBaseData.update.success|Projekt törzsadat mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.withId(id),
        null,
        false,
        dispatch,
        "projectBaseData.delete.flair|Projekt törzsadat törlése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectBaseData.delete.progress|Projekt törzsadat törlése folyamatban...",
          fail: "projectBaseData.delete.fail|Projekt törzsadat törlése sikertelen!",
          success:
            "projectBaseData.delete.success|Projekt törzsadat törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  clearCache: async function () {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveAll: (state, data) => {
    state.byType = {};
    data.forEach((it) => {
      const { type } = it;

      if (!state.byType[type]) {
        state.byType[type] = [];
      }

      state.byType[type].push(it);
    });
  },
  saveByType: (state, { type, data }) => {
    state.byType[type] = data;
  },
  saveById: (state, data) => {
    state.byId = data;
  },
};

const getters = {
  getByType: (state) => (type) => {
    return state.byType[type];
  },
  getById: (state) => {
    return state.byId;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
