<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <div>
          <h1 class="title">
            {{ $tf("association.upload.title|Összerendelések importálása") }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "association.upload.subtitle|Összerendelések ömlesztett feltöltése, importálása excelből"
              )
            }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container">
      <b-loading
        v-if="loading"
        :can-cancel="false"
        :is-full-page="false"
        v-model="loading"
      />
      <div class="overflow-x-auto" v-else>
        <template v-if="!enterpriseData || editMode">
          <div
            class="is-flex is-flex-direction-column has-gap-4 mt-5"
            style="max-width: 500px"
          >
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                v-model="enterpriseDataEdit.lowerRiskKPILimit"
                :validation-rule="v$.enterpriseDataEdit.lowerRiskKPILimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                v-model="enterpriseDataEdit.upperRiskKPILimit"
                :validation-rule="v$.enterpriseDataEdit.upperRiskKPILimit"
                label-position="on-border"
              />
            </div>

            <div>
              <vuelidated-input
                type="dropdown"
                :label="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :validation-rule="v$.enterpriseDataEdit.issueTrackingSystem"
                label-position="on-border"
              >
                <b-dropdown
                  v-model="enterpriseDataEdit.issueTrackingSystem"
                  scrollable
                  :max-height="200"
                  aria-role="list"
                  append-to-body
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <div class="is-flex-grow-1">
                        {{
                          $tf(
                            ISSUE_TRACKING_SYSTEM[
                              enterpriseDataEdit.issueTrackingSystem
                            ]?.label ?? ""
                          )
                        }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="its in Object.keys(ISSUE_TRACKING_SYSTEM)"
                    :key="ISSUE_TRACKING_SYSTEM[its].enum"
                    :value="ISSUE_TRACKING_SYSTEM[its].enum"
                    style="z-index: 999"
                  >
                    {{ $tf(ISSUE_TRACKING_SYSTEM[its].label) }}
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>
          </div>
        </template>

        <template v-if="enterpriseData && !editMode">
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData.lowerRiskKPILimit
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData.upperRiskKPILimit
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{ $tf("adminSettings.currency|Deviza") }}:
              </span>
              <span class="data-details-value">{{
                selectedCurrency.name
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueTrackingSystem|Külső taskkezelő rendszer"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                $tf(
                  ISSUE_TRACKING_SYSTEM[enterpriseData.issueTrackingSystem]
                    ?.label ?? ""
                )
              }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { decimal, maxValue, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "../../components/module/input/VuelidatedInput";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { deepCopy } from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "UploadAssociations",
  components: { VuelidatedInput },
  setup: () => useCustomVuelidate(),
  data() {
    return {
      loading: false,
      editMode: false,
      enterpriseData: null,
      enterpriseDataEdit: {
        lowerRiskKPILimit: 1.09,
        upperRiskKPILimit: 1.09,
        currency: undefined,
        issueTrackingSystem: ISSUE_TRACKING_SYSTEM.JIRA.enum,
      },
      ISSUE_TRACKING_SYSTEM,
    };
  },
  validations: {
    issueTrackingSystem: {
      required,
    },
  },
  watch: {
    async activeTab() {
      await this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      clients: "enterprise_clients/clients",
      currencies: "adminConfig/getCurrencies",
    }),
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch(force) {
      this.loading = true;
      await this.$store.dispatch("adminConfig/fetchEnterpriseData", force);
      await this.$store.dispatch("adminConfig/fetchCurrencies", force);
      this.enterpriseData =
        this.$store.getters["adminConfig/getEnterpriseData"];
      if (this.enterpriseData) {
        this.enterpriseDataEdit = cloneDeep(this.enterpriseData);
        this.enterpriseDataEdit.currency = this.enterpriseDataEdit.currency?.id;
      }
      this.loading = false;
    },
    async save() {
      let success = false;
      success = await this.saveEnterpriseData();
      if (success) {
        await this.fetch(true);
        this.editMode = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
