<template>
  <div class="risk-kpi-tag" :class="getRiskColor(value)">
    <p>
      {{ displayKpi(value) }}
    </p>
  </div>
</template>

<script>
import KpiMixin from "@/mixins/KpiMixin";

export default {
  name: "RiskKPITag",
  mixins: [KpiMixin],
  props: {
    value: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: "is-medium",
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.risk-kpi-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  width: fit-content;

  p {
    font-weight: 700;
    font-size: 1rem;
    line-height: 130%;
    display: flex;
    align-items: center;
  }
}

.is-success {
  background: $custom-success;
  color: $custom-text-success;
}
.is-warning {
  background: $custom-warning;
  color: $custom-text-warning;
}
.is-danger {
  background: $custom-danger;
  color: $custom-text-danger;
}
.is-fatal {
  background: $custom-fatal;
  color: $custom-text-fatal;
}
</style>
